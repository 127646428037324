import React, { useState, useContext, useRef } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import ImgInput from '../../UI/imgInput';
import { ToastContext } from '../../../context/ToastContext';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import { web_32_ncc_retract_pur_raw_order } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import useSystemText from '../../../helper/useSystemText';

const PurchaseMateNccRevoke = (props) => {

  const {setShowRevoke, revokeData} = props
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  
  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText305028 = useSystemText(305028)
  const sysText305029 = useSystemText(305029)

  console.log("revokeData: ", revokeData);

  const confirmHandler = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      confirmpeople: Cookies.get("cookie_uuid"),
      merchantno: revokeData.商戶號,
      ordernumber: revokeData.單號,
      amount: revokeData.金額,
      notifyurl: "",
      retpro_pur_pair_state:"0"
      
    }
           
    try {
      const { listResponse, status, statusText} = await web_32_ncc_retract_pur_raw_order(filterData);          
      console.log("listResponse: ", listResponse)          

      if (status === 200) {          
        setIsRefresh(prev => !prev)           
        showToast("success", "" + status, `成功撤销采购单: ${revokeData.單號}` )
        setShowRevoke(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }          
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] min-h-[200px] max-h-[800px] max-md:max-h-[500px] overflow-y-scroll bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText305028}</div>
            <div className='p-6 flex flex-col'>              
              <div>
                <p className='text-sm'>{sysText305029}：</p>
                <p className='text-yellow-300'>{revokeData?.單號}</p>   
              </div>                      
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowRevoke(false)}></Button>
              <Button style={{width: '120px'}} label={sysText10002} severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseMateNccRevoke;