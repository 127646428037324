import React, { useState, useContext, useRef, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { imgOnError } from '../../../helper/imgOnError';
import HelpIcon from '../../UI/helpIcon';
import ToastContext from '../../../context/ToastContext';
import Cookies from 'js-cookie';
import { web_35_ncc_pur_ordernumber_get_suborder } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const PurchaseRecordPicture = (props) => {

  const { setShowPicture, pictureData } = props
  const { showToast } = useContext(ToastContext)
  const location = useLocation()
  const [suborderPictrueData, setSuborderPictrueData] = useState([])
  const helpTextPicture = useHelpText(0)

  const sysText306041 = useSystemText(306041)

  console.log("pictureData: ", pictureData);

  useEffect(() => {          
  
      const web35_fetchData = async(row) => {  
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),     
          pur_ordernumber: pictureData.採購單編號,           
        };  
        
        try {
          const { listResponse, status, statusText } = await web_35_ncc_pur_ordernumber_get_suborder(filterData);        
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {                
            setSuborderPictrueData(listResponse.flat()); 
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        }
      }    

      web35_fetchData()
  }, [pictureData]); 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[800px] max-h-[800px] max-md:max-h-[500px] overflow-y-scroll bg-customColor-bgGray rounded-lg max-md:w-full'>
          <div className='flex flex-col'>
            <div className='flex flex-row border-b border-gray-500 text-lg p-6 flex-1'>
              <p>{sysText306041} <span className='text-yellow-500'>{pictureData.採購單編號}</span></p>
              <div className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></div>
            </div>
            <div className='p-6 flex flex-col'>
              <div className='flex'>
                {suborderPictrueData.length > 0 ? suborderPictrueData.map((row, index) => 
                  <div className='p-2' key={index}>
                    {row ?
                      <a href={`${row}`} target="_blank" rel="noreferrer">
                        <img className='max-w-[180px] max-h-[180px]' onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${row}`} />
                      </a>
                      :
                      <div className='flex justify-center items-center w-[180px] h-[180px] text-gray-500 text-sm bg-customColor-bgLightGray'>FAILED</div>
                    }
                  </div>
                ) : <p>无资料</p>}          
              
              </div>
            </div>
            <div className='border-t border-gray-500 flex justify-center items-center py-2'>
              <Button style={{ width: '120px' }} label='OK' severity='info' onClick={() => setShowPicture(false)}></Button>

            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseRecordPicture;