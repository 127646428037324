import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_09_ncc_pur_orderlist, web_35_ncc_pur_ordernumber_get_suborder} from '../../api/api';
import FilterInput from '../../components/UI/filterInput';
import { useLocation } from 'react-router-dom';
import Dialog from '../../components/dialog';
import PaginatorUI from '../../components/UI/paginatorUI'
import PurchaseRecordDetail from '../../components/purchaseManage/purchaseRecord/purchaseRecordDetail';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { formatDate } from '../../helper/formatDate';
import PurchaseRecordProcess from '../../components/purchaseManage/purchaseRecord/purchaseRecordProcess';
import PurchaseRecordPicture from '../../components/purchaseManage/purchaseRecord/purchaseRecordPicture';
import DisableSplit from '../../components/purchaseManage/disableSplit';
import PurchaseRecordCanned from '../../components/purchaseManage/purchaseRecord/purchaseRecordCanned';
import PurchaseRecordNccComplete from '../../components/purchaseManage/purchaseRecord/purchaseRecordNccComplete';
import PurchaseRecordNccRevoke from '../../components/purchaseManage/purchaseRecord/purchaseRecordNccRevoke';
import PurchaseRecordNccException from '../../components/purchaseManage/purchaseRecord/purchaseRecordNccException';
import PurchaseRecordNccProcess from '../../components/purchaseManage/purchaseRecord/purchaseRecordNccProcess';
import useJson from '../../helper/useJson';
import useSystemText from "../../helper/useSystemText";
import { MultiSelect } from 'primereact/multiselect';
import useTableSize from '../../helper/useTableSize';

const PurchaseRecordNcc = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)   
  const [purchaseRecordData , setPurchaseRecordData] = useState([])
  const [purchaseRecordSubData, setPurchaseRecordSubData] = useState([])
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const [totalCount, setTotalCount] = useState(0)  
  const [totalData, setTotalData] = useState({})
  const [processCount, setProcessCount] = useState({})
  const [filterDate, setFilterDate] = useState([])    
  const [filterNumber, setFilterNumber] = useState("")    
  const [showDetail, setShowDetail] = useState(false)  
  const [detailData, setDetailData] = useState({})
  const [showComplete, setShowComplete] = useState(false)
  const [completeData, setCompleteData] = useState({})   
  const [showException, setShowException] = useState(false)
  const [exceptionData, setExceptionData] = useState({})
  const [showRevoke, setShowRevoke] = useState(false) 
  const [revokeData, setRevokeData] = useState({})
  const [showProcess, setShowProcess] = useState(false) 
  const [processParentData, setProcessParentData] = useState({})
  const [processSubData, setProcessSubData] = useState({})
  const [showPicture, setShowPicture] = useState(false)
  const [pictureData, setPictureData] = useState({})
  const [showDisableSplit, setShowDisableSplit] = useState(false)
  const [splitData, setSplitData] = useState({})
  const [showCanned, setShowCanned] = useState(false)
  const [cannedData, setCannedData] = useState({})
  const [first, setFirst] = useState(1)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const [expandedRows, setExpandedRows] = useState(null);
  const {showToast, isRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {jsonToObject} = useJson()
  const tableScrollY = useTableSize(70, 60)

  const sysText10015 = useSystemText(10015)
  const sysText10016 = useSystemText(10016)
  const sysText10017 = useSystemText(10017)
  const sysText10018 = useSystemText(10018)
  const sysText10021 = useSystemText(10021)
  const sysText10022 = useSystemText(10022)
  const sysText10023 = useSystemText(10023)
  const sysText10025 = useSystemText(10025)
  const sysText10026 = useSystemText(10026)
  const sysText10027 = useSystemText(10027)
  const sysText10028 = useSystemText(10028)
  
  const sysText306001 = useSystemText(306001)
  const sysText306002 = useSystemText(306002)
  const sysText306003 = useSystemText(306003)
  const sysText306004 = useSystemText(306004)
  const sysText306005 = useSystemText(306005)
  const sysText306006 = useSystemText(306006)
  const sysText306007 = useSystemText(306007)
  const sysText306008 = useSystemText(306008)     
  const sysText306009 = useSystemText(306009)   
  const sysText306011 = useSystemText(306011)
  const sysText306012 = useSystemText(306012)     
  const sysText306013 = useSystemText(306013)   
  const sysText306014 = useSystemText(306014)     
  const sysText306015 = useSystemText(306015)
  const sysText306016 = useSystemText(306016)
  const sysText306020 = useSystemText(306020)
  const sysText306024 = useSystemText(306024)
  const sysText306027 = useSystemText(306027)
  const sysText306030 = useSystemText(306030)
  const sysText306038 = useSystemText(306038)
  const sysText306040 = useSystemText(306040)

  const ActiveColumn = (item) => {                
    // 母單狀態  (uuid 3&9 顯示的採購單記錄為母單)
    const parentStatusCheck = () => {   
      let parentLabel = ''
      let parentSeverity = ''
      switch (item.採購單狀態) {
        case 0:
          parentLabel = sysText10025
          parentSeverity = "secondary"
          break
        case 1:
          parentLabel = sysText10026
          parentSeverity = "info"
          break
        case 2:
          parentLabel = sysText10027
          parentSeverity = "help"
          break
        case 3:
          parentLabel = sysText10028
          parentSeverity = "success"
          break
        case 4:
          parentLabel = sysText10022
          parentSeverity = "warning"
          break
        case 5:
          parentLabel = sysText10018
          parentSeverity = "warning"
          break
        default:
          parentLabel = '';
          parentSeverity = '';
      }
      return {parentLabel, parentSeverity}             
    }      
    const {parentLabel, parentSeverity} = parentStatusCheck()
    
    //子單狀態 (uuid 1&2 顯示的採購單記錄為子單)
    const subStatusCheck = () => {   
      let subLabel = ''
      let subSeverity = ''
      switch (item.採購單狀態) {
        case 0:
          subLabel = sysText10015
          subSeverity = "secondary"
          break
        case 1:
          subLabel = sysText10016
          subSeverity = "info"
          break
        case 2:
          subLabel = sysText10017
          subSeverity = "success"
          break
        case 3:
          subLabel = sysText10022
          subSeverity = "warning"
          break
        case 4:
          subLabel = sysText10023
          subSeverity = "help"
          break
        case 5:
          subLabel = sysText10018
          subSeverity = "warning"
          break
        case 9:
          subLabel = sysText10021
          subSeverity = "danger"
          break
        default:
          subLabel = '';
          subSeverity = '';
      }
      return {subLabel, subSeverity}             
    }   

    const {subLabel, subSeverity} = subStatusCheck() 

    const showDetailHandler = () => {
      setShowDetail(true)
      setDetailData(item)
      console.log(item)     
    }

    const showCompleteHandler = () => {
      setShowComplete(true)
      setCompleteData(item)
      console.log(item)
    }

    const showExceptionHandler = () => {      
      setShowException(true)
      setExceptionData(item)
      console.log(item)     
    }  

    const revokeDisable = () => {    

      if(item.採購單狀態 == 4) {
        return true
      }

      if(item.items) {
        const list = item.items.filter(row => (row.狀態 == 1 || row.狀態 == 2 || row.狀態 == 9))
        if(list.length > 0) {
          return true
        }else{
          return false
        }
      }      
    }
    
    const showRevokeHandler = () => {
      setShowRevoke(true)
      setRevokeData(item)
      console.log(item)    
    }
    
    const showPictureHandler = () => {
      setShowPicture(true)
      setPictureData(item)
      console.log(item) 
    }

    const showDisableSplitHandler = () => {
      setSplitData(item)
      setShowDisableSplit(true)
    }

    const menuItems1_2 = [
      {
        label: sysText306016,
        command: showDetailHandler,
      },
      {
        label: sysText306020,
        command: showCompleteHandler,
        disabled: item.採購單狀態 === 0 ? false : true
      },
      {
        label: sysText306024,
        command: showExceptionHandler,
        disabled: item.採購單狀態 === 0 ? false : true
      },
    ];
    
    const menuItems3 = [
      {
        label: sysText306027,
        command: showRevokeHandler,
        disabled: revokeDisable()
        
      },
    ];
    
    // const menuItems9 = [
    //   {
    //     label: '查看图片',
    //     command: showPictureHandler,
    //   },
    // ];
    return (
      
      <div className='activeBtn flex flex-col items-center justify-center p-0 w-full'>
        {(uuidFirst === 1 || uuidFirst === 2) &&          
          <SplitButton label={subLabel} model={menuItems1_2} severity={subSeverity} raised/>
        }

        {uuidFirst === 3 &&          
          <SplitButton label={parentLabel} model={menuItems3} severity={parentSeverity} raised/>
        }

        {uuidFirst === 8 &&          
          <>
            <Button label={parentLabel} severity={parentSeverity} raised/>
            <Button label={sysText306038} severity="danger" onClick={showDisableSplitHandler} disabled={item.是否拆分 === 1} raised/>
            <Button label={sysText306040} severity='help' onClick={showPictureHandler}></Button>
          </>
        }

        {uuidFirst === 9 &&          
          <Button label={parentLabel} severity={parentSeverity} raised/>
        }
      </div>      
    )
  }

  // 手機板母單版面
  const LgParentTableBody = (item) => {
    return (
      <div className='flex flex-row justify-between w-full break-all'>
        <div className='flex flex-col w-[60%]'>
          <div>{formatDate(item.採購單時間)}</div>
          <div>{formatDate(item.完成時間)}</div>
          {(uuidFirst == 3 || uuidFirst == 8 || uuidFirst == 9) &&
             <div>{item.採購商戶號}</div>
          }
          <div>{item.採購單編號}</div>
          <div>{item.採購單金額}</div>
          {/* <div>{item.收款人姓名}</div>
          <div>{item.收款人卡號}</div> */}
          <div>{item.收款人資訊}</div>
          {(uuidFirst === 1 || uuidFirst === 2) && 
            <>
              <div>{item.付款人姓名}</div>
              <div>{item.付款人銀行}</div>
            </>
          }
        </div>
        {(uuidFirst == 3 || uuidFirst == 8 || uuidFirst == 9) &&
             <div>{item.三方套件}</div>
          }
        <div className='flex w-[40%]'>
          {ActiveColumn(item)}
        </div>
        
      </div>
      
    )
  }

  //uuid 3 9 拓展顯示子單按鈕用
  const ExpandActiveColumn = (props) => {      
    
    const {item, data} = props

    //子單狀態
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.狀態) {
        case 0:
          label = sysText10015
          severity = "secondary"
          break
        case 1:
          label = sysText10016
          severity = "info"
          break
        case 2:
          label = sysText10017
          severity = "success"
          break
        case 3:
          label = sysText10022
          severity = "warning"
          break
        case 4:
          label = sysText10023
          severity = "help"
          break
        case 5:
          label = sysText10018
          severity = "warning"
          break
        case 9:
          label = sysText10021
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }   

    const {label, severity} = statusBtnCheck()  

    const showProcessHandler = () => {
      setShowProcess(true)
      setProcessParentData(data)
      setProcessSubData(item)
      console.log(data)
      console.log(item)    
    }

    const showCompleteHandler = () => {
      setShowComplete(true)
      setCompleteData(item)
      console.log(item)
    }
    
    const showCannedHandler = () => {
      setShowCanned(true)
      setCannedData(item)
    }
    
    return (
      
      <div className='activeBtn flex flex-col justify-center items-center p-0 whitespace-nowrap'>          
        <Button label={label} severity={severity} raised />
        <Button label={uuidFirst === 3 ? sysText306030 : uuidFirst === 9 ? sysText306016 : ""} severity="warning" raised onClick={showProcessHandler} />
        {uuidFirst === 3 &&        
          <Button disabled={item.狀態 === 1 || item.狀態 === 2} label="完成支付" severity="info" raised onClick={showCompleteHandler} />
        }
        {/* <Button label="罐头讯息" severity="help" raised onClick={showCannedHandler} />         */}
      </div>
    )
  }

  const allowExpansion = (rowData) => {    
    return rowData
  };

  const onRowToggleHandler = async(data) => {

    if(data && data.length > 0) {
      //expandedRows有沒有值判斷下拉展開或關閉
      //只展開當前點擊的拓展，需為陣列
      setExpandedRows([data[data.length-1]])

      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),     
        pur_ordernumber: data[data.length-1].採購單編號,           
      };  
      
      try {
        const { listResponse, status, statusText } = await web_35_ncc_pur_ordernumber_get_suborder(filterData);        
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {                  
          setPurchaseRecordSubData(listResponse)   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    
    }else {
      setExpandedRows([])
    }
    
  }

  const tableColumns = [
    {
      header: sysText306001,
      field: "採購單時間",
      style: {minWidth: "90px"},
      body: (row) => formatDate(row.採購單時間)
    },
    {
      header: sysText306002,
      field: "完成時間",
      style: {minWidth: "90px"},
      body: (row) => formatDate(row.完成時間)
    },
    {
      header: sysText306003,
      field: "採購商戶號",
      style: {minWidth: "150px"},
      enable: [3, 8, 9]
    },
    {
      header: sysText306004,
      field: "採購單編號",
      style: {minWidth: "150px"},
    },
    {
      header: sysText306005,
      field: "採購單金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText306006,
      field: "收款人資訊",
      style: {minWidth: "120px"},
    },
    {
      header: sysText306015,
      field: "付款人資訊",
      style: {minWidth: "150px"},
      enable: [1, 2]
    },
    {
      header: sysText306007,
      field: "active",
      style: {minWidth: "150px"},
      body: ActiveColumn,
      headerClassName: "centered-header"
    },
    {
      header: sysText306008,
      field: "子單單號",
      style: {minWidth: "150px"},
      sub: true
    },
    {
      header: sysText306009,
      field: "子單完成時間",
      style: {minWidth: "90px"},
      body: (row) => formatDate(row.子單完成時間),
      sub: true
    },
    {
      header: sysText306011,
      field: "訂單金額",
      style: {minWidth: "120px"},
      sub: true
    },
    {
      header: sysText306012,
      field: "(子單)付款人資訊", //避免子母單field重複影響欄位篩選功能
      body: (row) => row.付款人資訊,
      sub: true
    },
    {
      header: sysText306013,
      field: "三方套件",
      style: {minWidth: "150px"},
      sub: true
    },
    {
      header: sysText306014 + " ", //加上空白防止重複key
      field: "狀態",
      style: {minWidth: "120px"},
      body: () => ExpandActiveColumn,
      headerClassName: "centered-header",
      sub: true
    },     

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  console.log("visibleColumns: ", visibleColumns);
  
  // U3 9子單拓展
  const RowExpansionTemplate = (data) => {

    const payerNameColumn = (item) => {
      const jsonData = jsonToObject(item.付款人資訊)
      return <p>{jsonData?.姓名}</p>
    }    

    const payerBankColumn = (item) => {
      const jsonData = jsonToObject(item.付款人資訊)
      return <p>{jsonData?.银行名称}</p>
    }     

    return (
      <>
        <div className='tableWrap px-5 bg-customColor-bgGray max-2xl:hidden'>
          <DataTable value={purchaseRecordSubData}>
            {visibleColumns.map((row, index) => 
              row.sub ? 
                row.field === "狀態" ?                            
                  <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={(item) => <ExpandActiveColumn item={item} data={data}/>} headerClassName={row.headerClassName}></Column>
                :
                  <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>
              : null
            )}  
          </DataTable>      
        </div>
        
        <table className='px-2 flex flex-col bg-customColor-bgGray min-2xl:hidden space-y-2 border border-yellow-400'>
          <tbody>
            {purchaseRecordSubData.map(item => {
              // const jsonData = jsonToObject(item.付款人資訊)
              return (
                <tr key={item.子單單號} className='flex flex-row justify-between items-center bg-customColor-bgDark min-2xl:p-4 max-2xl:p-0'>
                  <td className='flex flex-col break-all'>
                    <div>{item.子單單號}</div>
                    <div>{formatDate(item.子單完成時間)}</div>
                    <div>{item.訂單金額}</div>
                    {/* <div>{jsonData?.姓名}</div>
                    <div>{jsonData?.银行名称}</div>                   */}
                    <div>{item.付款人資訊}</div>
                    <div>{item.三方套件}</div>                     
                  </td>
                  <td>
                    {ExpandActiveColumn({item, data})} 
                  </td>
                  
                </tr>                        
              )
            })}        
          </tbody>
        </table>     
      </>     
    );
  };  

  //母單API
  useEffect(() => {      
    const web09_fetchData = async() => {  
      
      setIsLoading(true)
    
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),     
        pur_ordernumber: filterNumber,
        start_date: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
        end_date: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime(),
        pur_bankusername: "",
        pur_merchantno: "",
        page: page + 1,
        limit: rows,        
      };
      
      try {
        const { listResponse, status, statusText } = await web_09_ncc_pur_orderlist(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {        
                    
          setTotalData(listResponse.count_data?.[0])
          
          //將收款人資訊與付款人資訊json拆分
          // const newData = listResponse.data.map(row => {
          //   const payerinfo = jsonToObject(row.付款人資訊)
          //   const newPayerinfo = {"付款人姓名": payerinfo?.姓名, "付款人銀行名稱": payerinfo?.银行名称, "付款人卡号": payerinfo?.卡号}
          //   const payeeinfo = jsonToObject(row.收款人資訊)
          //   const newPayeeinfo = {"收款人姓名": payeeinfo?.姓名, "收款人銀行名稱": payeeinfo?.银行名称, "收款人卡号": payeeinfo?.卡号}
          //   return {...row, ...newPayerinfo, ...newPayeeinfo}
          // })
          // setPurchaseRecordData(newData)     
          
          setPurchaseRecordData(listResponse?.data)
          setTotalCount(listResponse.count?.[0])
          setProcessCount(listResponse.order_count?.[0])            
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }    
    web09_fetchData()     
  }, [first, rows, page, filterDate, filterNumber, isRefresh]);

  //母單設值後取子單API
  // useEffect(() => {       

  //   if(uuidFirst === 3 || uuidFirst === 9) {
  //     let matchData = []    
  //     setIsLoading(true)
  
  //     const web35_fetchData = async(row) => {  
  //       const filterData = {
  //         cookie_uuid: Cookies.get("cookie_uuid"),
  //         cookie_hash: Cookies.get("cookie_hash"),     
  //         pur_ordernumber: row.採購單編號,           
  //       };  
        
  //       try {
  //         const { listResponse, status, statusText } = await web_35_ncc_pur_ordernumber_get_suborder(filterData);        
          
  //         console.log("listResponse: ", listResponse)
  
  //         if (status === 200) {    
               
  //           matchData.push({...row, items: listResponse})          
  //         } else {
  //           showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
  //         }
  //       } catch (error) {        
  //         console.error(location.pathname, error);            
  //       }
  //     }          
    
  //     Promise.all(purchaseRecordData.map(row => web35_fetchData(row)))
  //     .then(() => {
  //       matchData.sort((a, b) => b.採購單時間.localeCompare(a.採購單時間))
  //       setPurchaseRecordMatchData(matchData)
  //       }
  //     )
  //     .catch(error => console.error("Error in Promise.all", error))
  //     .finally(() => setIsLoading(false))
  //   }else {
  //     setPurchaseRecordMatchData(purchaseRecordData)
  //   }           
  // }, [purchaseRecordData]); 

  return (
      <div className="recordWrap">
        {(uuidFirst === 3 || uuidFirst === 8) && 
        <div className='flex flex-row'>
          <div className='px-2 py-1 bg-blue-500 rounded-md mr-2 max-md:text-center'>已完成订单总数：{totalData.完成筆數 ?? 0}</div>
          <div className='px-2 py-1 bg-yellow-400 rounded-md mr-2 max-md:text-center'>已完成订单总金额 ：{totalData.完成金額加總 ?? 0}</div> 
          {(uuidFirst === 8) &&
            <>
              <div className='px-2 py-1 border mr-2 border-blue-400 rounded-md max-md:text-center'>处理中订单总数：{processCount.處理中筆數 ?? 0}</div>
              <div className='px-2 py-1 border border-yellow-400 rounded-md max-md:text-center'>处理中订单总额 ：{processCount.處理中金額 ?? 0}</div>   
            </>
          }        
        </div>
        }        
       
        <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} placeholder="请输入采购單编号">
          {(uuidFirst === 3 || uuidFirst === 9) ?
            <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:hidden custom-multiselect" display='chip'/>
          :
            <MultiSelect value={visibleColumns.map(item => ({...item, body: ""})).filter(item => !item.sub)} options={filterColumnForUuid.map(item => ({...item, body: ""})).filter(item => !item.sub)} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:hidden custom-multiselect" display='chip'/>  
          }
        </FilterInput>
        {!isLoading &&
        <div className='tableWrap'>    
          <div className='max-2xl:hidden'>  

            <DataTable datakey="採購單編號" value={purchaseRecordData} expandedRows={expandedRows} onRowToggle={(e) => onRowToggleHandler(e.data)} rowExpansionTemplate={RowExpansionTemplate} scrollable scrollHeight="550px">
              {(uuidFirst === 3 || uuidFirst === 9) && <Column expander={allowExpansion} style={{ width: '5rem' }} /> }
                {visibleColumns.map((row, index) => 
                  row.sub ?
                    null
                  :                         
                    <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>                  
                )}              
            </DataTable>
          </div>

          <div className='min-2xl:hidden'>
            <DataTable datakey="採購單編號" value={purchaseRecordData} expandedRows={expandedRows} onRowToggle={(e) => onRowToggleHandler(e.data)} rowExpansionTemplate={RowExpansionTemplate} scrollable scrollHeight={tableScrollY}>
              {(uuidFirst === 3 || uuidFirst === 9) && <Column expander={allowExpansion} style={{ width: '1rem' }} /> }
              <Column field="採購單時間" header="" body={LgParentTableBody}></Column>              
            </DataTable>
          </div>     
          
          <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
        </div>       
        }
         {/* 訂單詳細  */}
        {showDetail && <PurchaseRecordDetail setShowDetail={setShowDetail} detailData={detailData}/>}
        
        {/* 完成訂單 */}
        {showComplete && <PurchaseRecordNccComplete setShowComplete={setShowComplete} completeData={completeData} />}

        {/* 異常通知 */}
        {showException && <PurchaseRecordNccException setShowException={setShowException} exceptionData={exceptionData} />}

        {/* 整單撤回 */}
        {showRevoke && <PurchaseRecordNccRevoke setShowRevoke={setShowRevoke} revokeData={revokeData} />}

        {/* 處理訂單 */}
        {showProcess && <PurchaseRecordNccProcess setShowProcess={setShowProcess} processParentData={processParentData} processSubData={processSubData} />}
        
        {/* 查看圖片 */}
        {showPicture && <PurchaseRecordPicture setShowPicture={setShowPicture} pictureData={pictureData}/> }

        {/* 禁止拆分 */}
        {showDisableSplit && <DisableSplit setShowDisableSplit={setShowDisableSplit} splitData={splitData}/> }

        {/* 罐頭訊息 */}
        {/* {showCanned && <PurchaseRecordCanned setShowCanned={setShowCanned} cannedData={cannedData}></PurchaseRecordCanned>} */}

      </div>
  );
}

export default PurchaseRecordNcc