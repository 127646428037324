import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_09_pur_orderlist, web_35_pur_ordernumber_get_suborder } from '../../api/api';
import FilterInput from '../../components/UI/filterInput';
import { useLocation } from 'react-router-dom';
import Dialog from '../../components/dialog';
import PaginatorUI from '../../components/UI/paginatorUI'
import PurchaseRecordDetail from '../../components/purchaseManage/purchaseRecord/purchaseRecordDetail';
import PurchaseRecordComplete from '../../components/purchaseManage/purchaseRecord/purchaseRecordComplete';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import { formatDate } from '../../helper/formatDate';
import PurchaseRecordException from '../../components/purchaseManage/purchaseRecord/purchaseRecordException';
import PurchaseRecordRevoke from '../../components/purchaseManage/purchaseRecord/purchaseRecordRevoke';
import PurchaseRecordProcess from '../../components/purchaseManage/purchaseRecord/purchaseRecordProcess';
import PurchaseRecordPicture from '../../components/purchaseManage/purchaseRecord/purchaseRecordPicture';
import DisableSplit from '../../components/purchaseManage/disableSplit';
import PurchaseRecordCanned from '../../components/purchaseManage/purchaseRecord/purchaseRecordCanned';

const PurchaseRecord = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)   
  const [purchaseRecordData , setPurchaseRecordData] = useState([])
  // const [purchaseRecordMatchData, setPurchaseRecordMatchData] = useState([])
  const [purchaseRecordSubData, setPurchaseRecordSubData] = useState([])
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const [totalCount, setTotalCount] = useState(0)  
  const [totalData, setTotalData] = useState({})
  const [processCount, setProcessCount] = useState({})
  const [filterDate, setFilterDate] = useState([])    
  const [filterNumber, setFilterNumber] = useState("")    
  const [showDetail, setShowDetail] = useState(false)  
  const [detailData, setDetailData] = useState({})
  const [showComplete, setShowComplete] = useState(false)
  const [completeData, setCompleteData] = useState({})   
  const [showException, setShowException] = useState(false)
  const [exceptionData, setExceptionData] = useState({})
  const [showRevoke, setShowRevoke] = useState(false) 
  const [revokeData, setRevokeData] = useState({})
  const [showProcess, setShowProcess] = useState(false) 
  const [processParentData, setProcessParentData] = useState({})
  const [processSubData, setProcessSubData] = useState({})
  const [showPicture, setShowPicture] = useState(false)
  const [pictureData, setPictureData] = useState({})
  const [showDisableSplit, setShowDisableSplit] = useState(false)
  const [splitData, setSplitData] = useState({})
  const [showCanned, setShowCanned] = useState(false)
  const [cannedData, setCannedData] = useState({})
  const [first, setFirst] = useState(1)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const [expandedRows, setExpandedRows] = useState(null);
  const {showToast, isRefresh} = useContext(ToastContext)
  const location = useLocation()   

  const ActiveColumn = (item) => {                
    // 母單狀態  (uuid 3&9 顯示的採購單記錄為母單)
    const parentStatusCheck = () => {   
      let parentLabel = ''
      let parentSeverity = ''
      switch (item.採購單狀態) {
        case 0:
          parentLabel = "未处理"
          parentSeverity = "secondary"
          break
        case 1:
          parentLabel = "已取用"
          parentSeverity = "info"
          break
        case 2:
          parentLabel = "已派发"
          parentSeverity = "help"
          break
        case 3:
          parentLabel = "已付款"
          parentSeverity = "success"
          break
        case 4:
          parentLabel = "已驳回"
          parentSeverity = "warning"
          break
        case 5:
          parentLabel = "已逾时"
          parentSeverity = "danger"
          break
        default:
          parentLabel = '';
          parentSeverity = '';
      }
      return {parentLabel, parentSeverity}             
    }      
    const {parentLabel, parentSeverity} = parentStatusCheck()
    
    //子單狀態 (uuid 1&2 顯示的採購單記錄為子單)
    const subStatusCheck = () => {   
      let subLabel = ''
      let subSeverity = ''
      switch (item.採購單狀態) {
        case 0:
          subLabel = "未支付"
          subSeverity = "secondary"
          break
        case 1:
          subLabel = "已支付"
          subSeverity = "info"
          break
        case 2:
          subLabel = "已确认"
          subSeverity = "success"
          break
        case 3:
          subLabel = "已驳回"
          subSeverity = "warning"
          break
        case 4:
          subLabel = "已转派"
          subSeverity = "help"
          break
        case 9:
          subLabel = "异常"
          subSeverity = "danger"
          break
        default:
          subLabel = '';
          subSeverity = '';
      }
      return {subLabel, subSeverity}             
    }   

    const {subLabel, subSeverity} = subStatusCheck() 

    const showDetailHandler = () => {
      setShowDetail(true)
      setDetailData(item)
      console.log(item)     
    }

    const showCompleteHandler = () => {
      setShowComplete(true)
      setCompleteData(item)
      console.log(item)
    }

    const showExceptionHandler = () => {      
      setShowException(true)
      setExceptionData(item)
      console.log(item)     
    }  

    const revokeDisable = () => {    
      if(item.items) {
        const list = item.items.filter(row => (row.狀態 == 1 || row.狀態 == 2 || row.狀態 == 9))
        if(list.length > 0) {
          return true
        }else{
          return false
        }
      }      
    }
    
    const showRevokeHandler = () => {
      setShowRevoke(true)
      setRevokeData(item)
      console.log(item)    
    }
    
    const showPictureHandler = () => {
      setShowPicture(true)
      setPictureData(item)
      console.log(item) 
    }

    const showDisableSplitHandler = () => {
      setSplitData(item)
      setShowDisableSplit(true)
    }

    const menuItems1_2 = [
      {
        label: '订单详情',
        command: showDetailHandler,
      },
      {
        label: '完成支付',
        command: showCompleteHandler,
        disabled: item.採購單狀態 === 0 ? false : true
      },
      {
        label: '异常通知',
        command: showExceptionHandler,
        disabled: item.採購單狀態 === 0 ? false : true
      },
    ];
    
    const menuItems3 = [
      {
        label: '整单撤回',
        command: showRevokeHandler,
        disabled: revokeDisable()
        
      },
    ];
    
    // const menuItems9 = [
    //   {
    //     label: '查看图片',
    //     command: showPictureHandler,
    //   },
    // ];
    return (
      
      <div className='activeBtn flex flex-col items-center justify-center p-0 w-full'>
        {(uuidFirst === 1 || uuidFirst === 2) &&          
          <SplitButton label={subLabel} model={menuItems1_2} severity={subSeverity} raised/>
        }

        {uuidFirst === 3 &&          
          <SplitButton label={parentLabel} model={menuItems3} severity={parentSeverity} raised />
        }

        {uuidFirst === 8 &&          
          <>
            <Button label={parentLabel} severity={parentSeverity} />
            <Button label="禁止拆分" severity="danger" onClick={showDisableSplitHandler} disabled={item.是否拆分 === 1} raised/>
            <Button label='查看圖片' severity='help' onClick={showPictureHandler}></Button>
          </>
        }

        {uuidFirst === 9 &&          
          <Button label={parentLabel} severity={parentSeverity} raised/>
        }
      </div>      
    )
  }

  // 手機板母單版面
  const LgParentTableBody = (item) => {
    return (
      <div className='flex flex-row justify-between w-full'>
        <div className='flex flex-col w-[60%]'>
          <p className='break-words'>{formatDate(item.採購單時間)}</p>
          <p className='break-words'>{formatDate(item.完成時間)}</p>
          <p className='break-words'>{item.採購單編號}</p>
          <p className='break-words'>{item.採購單金額}</p>
          <p className='break-words'>{item.收款人姓名}</p>
          <p className='break-words'>{item.收款人卡號}</p>
          {(uuidFirst === 1 || uuidFirst === 2) && 
            <>
              <p className='break-words'>{item.付款人姓名}</p>
              <p className='break-words'>{item.付款人銀行}</p>
            </>
          }
        </div>
        <div className='flex w-[40%]'>
          {ActiveColumn(item)}
        </div>
        
      </div>
      
    )
  }

  //uuid 3 拓展顯示子單按鈕用
  const ExpandActiveColumn = (props) => {      
    
    const {item, data} = props

    //子單狀態
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.狀態) {
        case 0:
          label = "未支付"
          severity = "secondary"
          break
        case 1:
          label = "已支付"
          severity = "info"
          break
        case 2:
          label = "已确认"
          severity = "success"
          break
        case 3:
          label = "已驳回"
          severity = "warning"
          break
        case 4:
          label = "已转派"
          severity = "help"
          break
        case 9:
          label = "异常"
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }   

    const {label, severity} = statusBtnCheck()  

    const showProcessHandler = () => {
      setShowProcess(true)
      setProcessParentData(data)
      setProcessSubData(item)
      console.log(data)
      console.log(item)    
    }

    const showCompleteHandler = () => {
      setShowComplete(true)
      setCompleteData(item)
      console.log(item)
    }
    
    const showCannedHandler = () => {
      setShowCanned(true)
      setCannedData(item)
    }
    
    return (
      
      <div className='activeBtn flex flex-col items-center justify-around p-0'>          
        <Button label={label} severity={severity} raised />
        <Button label={uuidFirst === 3 ? "处理采购" : uuidFirst === 9 ? "订单详情" : ""} severity="warning" raised onClick={showProcessHandler} />
        {uuidFirst === 3 &&
          <Button disabled={item.狀態 === 1 || item.狀態 === 2} label="完成支付" severity="info" raised onClick={showCompleteHandler} />        
        }
        {/* <Button label="罐头讯息" severity="help" outlined onClick={showCannedHandler} />         */}
      </div>
    )
  }

  const allowExpansion = (rowData) => {    
    return rowData
  }

  const onRowToggleHandler = async(data) => {

    if(data && data.length > 0) {
      //expandedRows有沒有值判斷下拉展開或關閉
      //只展開當前點擊的拓展，需為陣列
      setExpandedRows([data[data.length-1]])

      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),     
        pur_ordernumber: data[data.length-1].採購單編號,           
      };  
      
      try {
        const { listResponse, status, statusText } = await web_35_pur_ordernumber_get_suborder(filterData);        
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {                  
          setPurchaseRecordSubData(listResponse)   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    
    }else {
      setExpandedRows([])
    }
    
  }
  
  // U3子單拓展
  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className='tableWrap px-5 bg-customColor-bgGray max-lg:hidden'>
          <DataTable value={purchaseRecordSubData}>
            <Column field="子單單號" header="子单单号"></Column>
            <Column field="子單完成時間" header="子单完成时间" body={(date) => formatDate(date.子單完成時間)}></Column>
            <Column field="訂單金額" header="订单金额"></Column>
            <Column field="付款人姓名" header="付款人姓名"></Column>
            <Column field="付款人銀行" header="付款人银行"></Column>
            <Column field="狀態" header="状态/操作" body={(item) => ExpandActiveColumn({item, data})} headerClassName='centered-header'></Column>
          </DataTable>      
        </div>
        
        <div className='px-2 flex flex-col bg-customColor-bgGray min-lg:hidden space-y-2 border border-yellow-400'>
          {purchaseRecordSubData.map(item => {
            return (
              <div key={item.子單單號} className='flex flex-row justify-between items-center bg-customColor-bgDark p-4'>
                <div className='flex flex-col'>
                  <p className='break-words'>{item.子單單號}</p>
                  <p>{formatDate(item.子單完成時間)}</p>
                  <p>{item.訂單金額}</p>
                  <p>{item.付款人姓名}</p>
                  <p>{item.付款人銀行}</p>                  
                </div>
                {ExpandActiveColumn({item, data})}    
              </div>                        
            )
          })}        
          
        </div>     
      </>     
    );
  };  

  //母單API
  useEffect(() => {      
    const web09_fetchData = async() => {  
      console.log("09");
      setIsLoading(true)
    
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),     
        pur_ordernumber: filterNumber,
        start_date: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
        end_date: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime(),
        pur_bankusername: "",
        pur_merchantno: "",
        page: page + 1,
        limit: rows,        
      };
      
      try {
        const { listResponse, status, statusText } = await web_09_pur_orderlist(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {        
                    
          setTotalData(listResponse.count_data[0])         
          setPurchaseRecordData(listResponse.data)            
          setTotalCount(listResponse.count[0])
          setProcessCount(listResponse.order_count[0])            
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }    
    web09_fetchData()     
  }, [first, rows, page, filterDate, filterNumber, isRefresh]);

  //母單設值後取子單API
  // useEffect(() => {       

  //   if(uuidFirst === 3 || uuidFirst === 9) {
  //     let matchData = []    
  //     setIsLoading(true)
  
  //     const web35_fetchData = async(row) => {  
  //       const filterData = {
  //         cookie_uuid: Cookies.get("cookie_uuid"),
  //         cookie_hash: Cookies.get("cookie_hash"),     
  //         pur_ordernumber: row.採購單編號,           
  //       };  
        
  //       try {
  //         const { listResponse, status, statusText } = await web_35_pur_ordernumber_get_suborder(filterData);        
          
  //         console.log("listResponse: ", listResponse)
  
  //         if (status === 200) {    
               
  //           matchData.push({...row, items: listResponse})          
  //         } else {
  //           showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
  //         }
  //       } catch (error) {        
  //         console.error(location.pathname, error);            
  //       }
  //     }          
    
  //     Promise.all(purchaseRecordData.map(row => web35_fetchData(row)))
  //     .then(() => {
  //       matchData.sort((a, b) => b.採購單時間.localeCompare(a.採購單時間))
  //       setPurchaseRecordMatchData(matchData)
  //       }
  //     )
  //     .catch(error => console.error("Error in Promise.all", error))
  //     .finally(() => setIsLoading(false))
  //   }else {
  //     setPurchaseRecordMatchData(purchaseRecordData)
  //   }           
  // }, [purchaseRecordData]); 

  return (
      <div className="px-4 recordWrap">
        {(uuidFirst === 3 || uuidFirst === 8) && 
        <div className='flex flex-row'>
          <div className='px-2 py-1 bg-blue-500 rounded-md mr-2 max-md:text-center'>已完成订单总数：{totalData.完成筆數 ?? 0}</div>
          <div className='px-2 py-1 bg-yellow-400 rounded-md mr-2 max-md:text-center'>已完成订单总金额 ：{totalData.完成金額加總 ?? 0}</div> 
          {(uuidFirst === 8) &&
            <>
              <div className='px-2 py-1 border mr-2 border-blue-400 rounded-md max-md:text-center'>处理中订单总数：{processCount.處理中筆數 ?? 0}</div>
              <div className='px-2 py-1 border border-yellow-400 rounded-md max-md:text-center'>处理中订单总额 ：{processCount.處理中金額 ?? 0}</div>   
            </>
          }        
        </div>
        }        
       
        <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} placeholder="请输入采购單编号"/>
        {!isLoading &&
        <div className='tableWrap'>    
          <div className='max-lg:hidden'>
            <DataTable datakey="採購單編號" value={purchaseRecordData} expandedRows={expandedRows} onRowToggle={(e) => onRowToggleHandler(e.data)} rowExpansionTemplate={rowExpansionTemplate} scrollable scrollHeight="550px">
              {(uuidFirst === 3 || uuidFirst === 9) && <Column expander={allowExpansion} style={{ width: '5rem' }} /> }
              <Column style={{minWidth: "120px"}} field="採購單時間" header="采购单时间" body={(date) => formatDate(date.採購單時間)}></Column>
              <Column style={{minWidth: "120px"}} field="完成時間" header="完成时间" body={(date) => formatDate(date.完成時間)}></Column>
              <Column style={{minWidth: "150px"}} field="採購單編號" header="采购单编号"></Column>
              <Column style={{minWidth: "120px"}} field="採購單金額" header="采购单金额"></Column>
              <Column style={{minWidth: "120px"}} field="收款人姓名" header="收款人姓名"></Column>                      
              <Column style={{minWidth: "150px"}} field="收款人卡號" header="收款人卡号"></Column>
              {(uuidFirst === 1 || uuidFirst === 2) && <Column style={{minWidth: "150px"}} field="付款人姓名" header="付款人姓名"></Column>}
              {(uuidFirst === 1 || uuidFirst === 2) && <Column style={{minWidth: "150px"}} field="付款人銀行" header="付款人銀行"></Column>}
              <Column style={{minWidth: "150px"}} field="active" header="状态/操作" body={ActiveColumn} headerClassName="centered-header"></Column>
            </DataTable>
          </div>

          <div className='min-lg:hidden'>
            <DataTable datakey="採購單編號" value={purchaseRecordData} expandedRows={expandedRows} onRowToggle={(e) => onRowToggleHandler(e.data)} rowExpansionTemplate={rowExpansionTemplate} scrollable scrollHeight="380px">
              {(uuidFirst === 3 || uuidFirst === 9) && <Column expander={allowExpansion} style={{ width: '1rem' }} /> }
              <Column field="採購單時間" header="采购单" body={LgParentTableBody}></Column>              
            </DataTable>
          </div>     
          
          <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
        </div>       
        }
         {/* 訂單詳細  */}
        {showDetail && <PurchaseRecordDetail setShowDetail={setShowDetail} detailData={detailData}/>}
        
        {/* 完成訂單 */}
        {showComplete && <PurchaseRecordComplete setShowComplete={setShowComplete} completeData={completeData} />}

        {/* 異常通知 */}
        {showException && <PurchaseRecordException setShowException={setShowException} exceptionData={exceptionData} />}

        {/* 整單撤回 */}
        {showRevoke && <PurchaseRecordRevoke setShowRevoke={setShowRevoke} revokeData={revokeData} />}

        {/* 處理訂單 */}
        {showProcess && <PurchaseRecordProcess setShowProcess={setShowProcess} processParentData={processParentData} processSubData={processSubData} />}
        
        {/* 查看圖片 */}
        {showPicture && <PurchaseRecordPicture setShowPicture={setShowPicture} pictureData={pictureData}/> }

        {/* 禁止拆分 */}
        {showDisableSplit && <DisableSplit setShowDisableSplit={setShowDisableSplit} splitData={splitData}/> }

        {/* 罐頭訊息 */}
        {/* {showCanned && <PurchaseRecordCanned setShowCanned={setShowCanned} cannedData={cannedData}></PurchaseRecordCanned>} */}

      </div>
  );
}

export default PurchaseRecord