import React, { useContext, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { web_103_new_merchant } from '../../../api/api';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const MerchantAdd = (props) => {

  const {setShowAddMerchant} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const helpText = useHelpText(11)  

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  
  const sysText601001 = useSystemText(601001)
  const sysText601006 = useSystemText(601006)
  const sysText601007 = useSystemText(601007)
  const sysText601008 = useSystemText(601008)
  const sysText601013 = useSystemText(601013)
  const sysText601017 = useSystemText(601017)
  const sysText601009 = useSystemText(601009)
  const sysText601010 = useSystemText(601010)
  const sysText601018 = useSystemText(601018)
  const sysText601019 = useSystemText(601019)

  const formik = useFormik({
    initialValues: {       
      name: "",
      login_ip_whitelist: "",
      payment_ip_whitelist: "",
      withdrawal_ip_whitelist: "",
      cashier_version: "1",      
      col_state: true,
      pur_state: true,
      tg_chatid: "",
      xrate: ""       
    },
    validate: (data) => {
      let errors = {};      

      const ipRegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(?:,(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]))*$/
      const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/
      const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/ 

      if (!data.name) {								
        errors.name = '必填';
      }else if(data.name.length > 30) {
        errors.name = '不可超過30字';
      }
      
      if (data.login_ip_whitelist && !ipRegExp.test(data.login_ip_whitelist)) {								
        errors.login_ip_whitelist = 'ex: 127.0.0.1,127.0.0.255';
      }

      if (data.payment_ip_whitelist && !ipRegExp.test(data.payment_ip_whitelist)) {								
        errors.payment_ip_whitelist = 'ex: 127.0.0.1,127.0.0.255';
      }

      if (data.withdrawal_ip_whitelist && !ipRegExp.test(data.withdrawal_ip_whitelist)) {								
        errors.withdrawal_ip_whitelist = 'ex: 127.0.0.1,127.0.0.255';
      }

      if (data.cashier_version === null) {								
        errors.cashier_version = '必填';
      }else if(!numberRegExp.test(data.cashier_version)) {
        errors.cashier_version = "必須為數字"
      }

      if (data.col_state === null) {								
        errors.col_state = '必填';
      }

      if (data.pur_state === null) {								
        errors.pur_state = '必填';
      }

      if (data.tg_chatid && data.tg_chatid.length > 50) {								
        errors.tg_chatid = '不可超過50字';
      }

      if(data.xrate && !percentRegExp.test(data.xrate)) {
        errors.xrate = "必須為 0 ~ 100.00"
      }

      return errors;
    },
    onSubmit: async(data) => {  
      
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),        
        name: data.name,       
        login_ip_whitelist: data.login_ip_whitelist.split(","),
        payment_ip_whitelist: data.payment_ip_whitelist.split(","),
        withdrawal_ip_whitelist: data.withdrawal_ip_whitelist.split(","),
        cashier_version: data.cashier_version,
        col_state: data.col_state ? "1" : "0",
        pur_state: data.pur_state ? "1" : "0",
        tg_chatid: data.tg_chatid,
        xrate: data.xrate,
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_103_new_merchant(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowAddMerchant(false)              
          showToast("success", "" + status, `成功新增商戶: ${filterData.name}` )           
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	   

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
 
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[70vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
            
            <div className='flex flex-row justify-between border-b border-gray-700 p-4 text-lg'>
              <p>{sysText601013}</p>
              <div><HelpIcon tooltipText={helpText}/></div>
            </div>

            <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[60vh] bg-customColor-bgGray overflow-y-scroll'>
              <div>
                <label htmlFor='name'>{sysText601001}</label>
                <input name="name" value={formik.values.name} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('name')}
              </div>                         

              <div>
                <label htmlFor='login_ip_whitelist'>{sysText601006}</label>
                <input min="0" name="login_ip_whitelist" value={formik.values.login_ip_whitelist} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('login_ip_whitelist')} 
              </div>

              <div>
                <label htmlFor='payment_ip_whitelist'>{sysText601007}</label>
                <input name="payment_ip_whitelist" value={formik.values.payment_ip_whitelist} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('payment_ip_whitelist')} 
              </div>

              <div>
                <label htmlFor='withdrawal_ip_whitelist'>{sysText601008}</label>
                <input min="0" name="withdrawal_ip_whitelist" value={formik.values.withdrawal_ip_whitelist} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('withdrawal_ip_whitelist')} 
              </div>

              <div>
                <label htmlFor='cashier_version'>{sysText601017}</label>
                <input name="cashier_version" value={formik.values.cashier_version} autoComplete='off' disabled={true} onChange={formik.handleChange} className='w-full opacity-50' type="text" />
                {getFormErrorMessage('cashier_version')} 
              </div>

              <div>
                <label htmlFor='tg_chatid'>{sysText601009}</label>
                <input name="tg_chatid" value={formik.values.tg_chatid} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('tg_chatid')} 
              </div>

              <div>
                <label htmlFor='xrate'>{sysText601010}</label>
                <input name="xrate" value={formik.values.xrate} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('xrate')} 
              </div>             
              
              <div className='flex items-center space-x-4'>
                <label htmlFor='col_state'>{sysText601018}</label>                 
                <InputSwitch name="col_state" checked={formik.values.col_state} onChange={formik.handleChange} className="p-inputswitch" />
              </div>  

               <div className='flex items-center space-x-4'>
                <label htmlFor='pur_state'>{sysText601019}</label>                 
                <InputSwitch name="pur_state" checked={formik.values.pur_state} onChange={formik.handleChange} className="p-inputswitch" />
              </div>                                              
            </div>

            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowAddMerchant(false)}></Button>
              <Button type="submit" style={{width: "120px"}} label={sysText10002} disabled={isLoading} severity='info' raised></Button>
            </div>
          
          </form>
          
        </div>
      </Dialog>
    </>
  );
};

export default MerchantAdd;