import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_78_update_menu } from '../../../api/api';

const MenuSettingConfirm = (props) => {
  const {setShowConfirm, confirmData} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const confirmHandler = async () => {
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      data: JSON.stringify(confirmData.data)
    };    

    const updateMenu = async () => {       
      setIsLoading(true)
      try {
        
        const { listResponse, status, statusText } = await web_78_update_menu(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {   
          setIsRefresh(prev => !prev)
          setShowConfirm(false)                  
          showToast("success", "" + status, `成功更新选单权限` )
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    updateMenu()    
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[300px] max-md:w-full bg-customColor-bgGray rounded-lg p-4'>
          <div className='flex flex-col'>
            
            <div className='p-4'>             
              <p className=''>确认是否保存设定?</p>
            </div>            
            
            <div className='border-gray-500 flex justify-end items-center space-x-2'>
              <Button style={{width: '80px'}} label='取消' severity='secondary' raised onClick={() => setShowConfirm(false)}></Button>
              <Button style={{width: '80px'}} label='确认' severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default MenuSettingConfirm;