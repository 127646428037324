import React, { createContext, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const [isRefresh, setIsRefresh] = useState(false)
  console.log("isRefresh: ", isRefresh);

  const showToast = (severity, summary, detail, life = 5000) => {
    toast.current.show({ severity, summary, detail, life});
  };

  return (
    <ToastContext.Provider value={{showToast, isRefresh, setIsRefresh}}>
      <Toast ref={toast} position="bottom-right" />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContext