import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import React from 'react';
import useCopy from '../helper/useCopy';
import useSystemText from '../helper/useSystemText';

const ClearCache = () => {
 
  const commandText = `curl --location --request POST "https://api.cloudflare.com/client/v4/zones/320764f8d01fd7ed927d8e3f21180c98/purge_cache" --header "X-Auth-Email: 7w08y57ijhc@epistarcpu.cc" --header "X-Auth-Key: 688b3207e6074b2f751ff55fddb3f73be5c30" --header "Content-Type: application/json" --data-raw "{\"purge_everything\":true}" -k`
  const {copyHandler} = useCopy()

  const sysText900000 = useSystemText(900000)
  const sysText900001 = useSystemText(900001)
  const sysText900002 = useSystemText(900002)
  const sysText900003 = useSystemText(900003)
  const sysText900004 = useSystemText(900004)
  const sysText900005 = useSystemText(900005)
  const sysText900006 = useSystemText(900006)
  const sysText900007 = useSystemText(900007)
  const sysText900008 = useSystemText(900008) 

  const clearCacheAndReload = () => {
    if (window.caches) {
      // 清除浏览器的所有缓存
      caches.keys().then(names => {
        for (let name of names)
          caches.delete(name);
      });
    }

    Object.keys(Cookies.get()).forEach((cookie) => Cookies.remove(cookie));

    // 通过设置 true 参数来绕过缓存从服务器重新加载
    window.location.reload(true);
  }

  return (
    <div className='flex flex-col p-4 '>
      <div>
        <h1 className='text-lg text-yellow-300'>{sysText900000}</h1>
      </div>

      <div className='my-4 px-6 border border-gray-700 bg-customColor-bgDark'>
        <div className='text-xl py-4 border-b border-gray-500'>
          {sysText900001}
        </div>
        <div className='my-4'>
          <pre>
            {sysText900002}
          </pre>
          
          {/* <pre className='my-4'>
            {sysText900003}        
          </pre>          */}
        </div>
      </div>

      <div className='my-4 px-6 border border-gray-700 bg-customColor-bgDark'>
        <div className='text-xl py-4 border-b border-gray-500'>
          {sysText900003}
        </div>
        <div className='my-4'>
          {/* <p>
            {sysText900005}
          </p> */}
          
          <pre className='my-4'>
            {sysText900004}         
          </pre>         
        </div>
      </div>

      <div className='my-4 px-6 border border-gray-700 bg-customColor-bgDark'>
        <div className='text-xl py-4 border-b border-gray-500'>
          {sysText900005}
        </div>
        <div className='my-4'>
          <pre>
            {sysText900006}
          </pre>
          
          <Button label={sysText900005} severity='info' onClick={clearCacheAndReload}></Button>
        </div>
      </div>

      <div className='my-4 px-6 border border-gray-700 bg-customColor-bgDark'>
        <div className='text-xl py-4 border-b border-gray-500'>
          {sysText900007}
        </div>
        <div className='my-4'>
          <pre>
            {sysText900008}
          </pre>          
                 
        </div>
      </div>
    </div>
  );
};

export default ClearCache;