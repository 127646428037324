import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { web_96_get_collect_asyncnotify } from '../../api/api';
import PaginatorUI from '../../components/UI/paginatorUI';
import Dialog from '../../components/dialog'
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';
import FilterInput from '../../components/UI/filterInput';

const PurchaseNotify = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [showAddMerchantno, setShowAddMerchantno] = useState(false)
  const [purchaseNotifyData, setPurchaseNotifyData] = useState([])
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const location = useLocation() 
  const [filterName, setFilterName] = useState("")
  const [messageData, setMessageData] = useState(null)
  const [notifyUrlData, setNotuifyData] = useState(null)  
  const tableScrollY = useTableSize(70, 70)

  const sysText10018 = useSystemText(10018)
  const sysText10021 = useSystemText(10021)
  const sysText10022 = useSystemText(10022)
  const sysText10025 = useSystemText(10025)
  const sysText10026 = useSystemText(10026)
  const sysText10027 = useSystemText(10027)
  const sysText10028 = useSystemText(10028)
  const sysText10029 = useSystemText(10029)
  const sysText10030 = useSystemText(10030)
  const sysText10031 = useSystemText(10031)
  const sysText10043 = useSystemText(10043)

  const sysText301001 = useSystemText(301001)
  const sysText301002 = useSystemText(301002)
  const sysText301003 = useSystemText(301003)
  const sysText301004 = useSystemText(301004)
  const sysText301005 = useSystemText(301005)
  const sysText301006 = useSystemText(301006)
  const sysText301007 = useSystemText(301007)
  const sysText301008 = useSystemText(301008)     
  const sysText301009 = useSystemText(301009)   
  const sysText301010 = useSystemText(301010)     
  const sysText301011 = useSystemText(301011)  
  const sysText301012 = useSystemText(301012)  
  

  const NotifyUrlColumn = (rowData) => {
    return rowData?.通知回調地址 ? 
    <div className="flex">
      <Button label={sysText10043} severity='info' text raised onClick={(e) => {setNotuifyData(rowData?.通知回調地址)}}></Button>
    </div>
    : ""
  }
  const NotifyUrlDialog = () => {
    if (notifyUrlData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setNotuifyData(null)}
        header={sysText301006}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText10043}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            {notifyUrlData}
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setNotuifyData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const MessageColumn = (rowData) => {
    return rowData.響應訊息 ? 
    <div className="flex">
      <Button label='查看讯息' severity='info' text raised onClick={(e) => {setMessageData(rowData)}}></Button>
    </div>
    : ""
  }
  const MessageDialog = () => {
    if (messageData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setMessageData(null)}
        header={sysText301009}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText301009}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            {messageData.響應訊息}
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setMessageData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const OrderStatusColumn = (item) => {

    const statusCheck = () => {   
      let label = ''     
      switch (item.原訂單狀態) {
        case 0:
          label = sysText10025        
          break
        case 1:
          label = sysText10026          
          break
        case 2:
          label = sysText10027          
          break
        case 3:
          label = sysText10028          
          break
        case 4:
          label = sysText10022          
          break
        case 5:
          label = sysText10018          
          break
        default:
          label = '';          
        }      

      return label          
    }      

    const label = statusCheck()

    return <p>{label}</p>
  }

  const ActiveColumn = (item) => {

    const statusCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.訂單狀態) {
        case 0:
          label = sysText10029
          severity = "secondary"
          break
        case 1:
          label = sysText10030
          severity = "info"
          break
        case 8:
          label = sysText10031
          severity = "help"
          break  
        case 9:
          label = sysText10021
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }   

    const {label, severity} = statusCheck() 

    return (
      <div className='flex flex-col items-center'>
        <Button label={label} severity={severity} />       
      </div>    
    )
  } 

  const tableColumns = [
    {
      header: sysText301001,
      field: "商戶號",
      style: {minWidth: "80px"},
    },
    {
      header: sysText301002,
      field: "時間紀錄",
      style: {minWidth: "120px"},
    },
    {
      header: sysText301003,
      field: "訂單號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText301004,
      field: "訂單金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText301005,
      field: "原訂單狀態",
      style: {minWidth: "120px"},
      body: OrderStatusColumn
    },
    {
      header: sysText301006,
      field: "通知回調地址",
      style: {minWidth: "100px"},
      body: NotifyUrlColumn
    },
    {
      header: sysText301007,
      field: "上次通知時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText301008,
      field: "上次通知IP",
      style: {minWidth: "150px"},
    },
    {
      header: sysText301009,
      field: "響應訊息",
      style: {minWidth: "100px"},
      body: MessageColumn
    },
    {
      header: sysText301010,
      field: "通知次數",
      style: {minWidth: "80px"},
    },
    {
      header: sysText301011,
      field: "訂單狀態",
      style: {minWidth: "120px"},
      headerClassName: "centered-header",
      body: ActiveColumn
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      page: page + 1,
      limit: rows,
      table_type: "2",      
      select: filterName
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_96_get_collect_asyncnotify(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          setPurchaseNotifyData(listResponse.data)
          setTotalCount(listResponse.筆數)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [isRefresh, page, first, rows, filterName]);  

  return (  
     
    <div className='flex flex-col'>     
      <FilterInput showCalendarInput={false} setFilterNumber={setFilterName} placeholder={sysText301012}>
        <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
      </FilterInput>
       
      <div className="px-2 tableWrap">               
        <DataTable value={purchaseNotifyData} scrollable scrollHeight={tableScrollY}>
          {visibleColumns.map((row,index) =>             
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
          )}              
        </DataTable>          
          
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>      
      </div> 
      {/* 通知回調地址 */}
      {notifyUrlData && <NotifyUrlDialog/>}
      {/* 響應訊息 */}
      {messageData && <MessageDialog/>}
    </div>
   
  );
}

export default PurchaseNotify;