import React, {useState} from 'react';

const LoadingContext = React.createContext()

export const LoadingProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const value = {
    isLoading,
    setIsLoading
  };

  return <LoadingContext.Provider value={value}>{props.children}</LoadingContext.Provider>;
};

export default LoadingContext