import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';

const HelpIcon = (props) => {
  const {tooltipText} = props
  const [tooltipPosition, setTooltipPosition] = useState("left")

  const handleMouseEnter = (e) => {
    const element = e.currentTarget;
    const rect = element.getBoundingClientRect();
    const spaceOnRight = window.innerWidth - rect.right;
    // 假设如果右边空间少于100px，则将 Tooltip 显示在左边
    if (spaceOnRight < 100) {
        setTooltipPosition('top');
    } else {
        setTooltipPosition('left');
    }
};

  return (
    <>      
      <div className='relative w-4 h-4 z-1'>
        <Tooltip target=".custom-target-icon"/>
        <i className="custom-target-icon pi pi-info rotate-6" data-pr-tooltip={tooltipText ? tooltipText : "无资料"} data-pr-position={tooltipPosition} onMouseEnter={handleMouseEnter} style={{ padding: '2px', fontSize: '1rem', color: 'white', border: "white 2px solid", borderRadius: "10rem", backgroundColor: "#3B82F6"}}></i>
      </div>
    </>
   
  );
};

export default HelpIcon;