import React, { useContext, useState } from 'react';
import { web_88_pur_order_allotment_or_grab } from '../../../api/api';
import ToastContext from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const PurchaseMateTransform = (props) => {

  const {grabData, setShowGrab} = props
  const {isRefresh, setIsRefresh, showToast} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [verificationCode, setVerificationCode] = useState("")

  const confirmHandler = async() => {
    setIsLoading(true)
    const filterData = {      
      cookie_uuid: Cookies.get('cookie_uuid'),
      cookie_hash: Cookies.get('cookie_hash'),    
      id: grabData.id,
      grab: "1",
      verification_code: verificationCode        
    };
           
    try {
      const { listResponse, status, statusText} = await web_88_pur_order_allotment_or_grab(filterData);         

      if (status === 200) {  
                           
        showToast("success", `成功轉換 單號 ${grabData.單號}` )          
        setIsRefresh(prev => !prev)
        setShowGrab(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
      setShowGrab(false)
    }
  }      
  
  return (
    <Dialog>
      <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>    
        <div className='border-b border-gray-700 p-4 text-lg'>抢单确认</div>      
        <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
          <p>订单编号: {grabData.單號}</p>
          <p>订单时间: {grabData.時間}</p>  
          <p>订单金额: {grabData.金額}</p>  
          <p>商户编号: {grabData.商戶號}</p>

          <div className='space-x-2 py-2'>
            <label>驗證碼</label>
            <input className='px-2 w-24' maxLength="8" onChange={(e) => setVerificationCode(e.target.value)}></input>
          </div>                         
        </div>
        <div className='flex justify-around items-center border-t border-gray-700 py-2'>
          <Button style={{ width: "120px" }} label='取消' severity='secondary' raised onClick={() => setShowGrab(false)}></Button>
          <Button style={{ width: "120px" }} label='确定' severity='info' raised onClick={confirmHandler}></Button>
        </div>
      </div>
    </Dialog>
  ); 
};

export default PurchaseMateTransform;