import React, { useState, useEffect, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import ImgInput from '../../UI/imgInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { uploadfile, web_17_add_staffcardnumber, web_50_get_bank_name } from '../../../api/api';
import { useFormik } from 'formik';
import AddBankName from '../bankKeyword/addBankName';
import { Dropdown } from 'primereact/dropdown';

const AddCard = (props) => {

  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {setShowAddCard} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [bankOption, setBankOption] = useState([])
  const [fileImg1, setFileImg1] = useState()
  const [showAddBankName, setShowAddBankName] = useState(false)
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
        selectBankName: '',
        bankCity: '',
        bankProvince: '',
        bankBranch: '',        
        cardnumber: '',
        dailyCash: 0,
        dailyCount: 0
    },
    validate: (data) => {
        let errors = {};
        const numberRegExp = /^[0-9]*$/   

        if(!data.selectBankName) {								
          errors.selectBankName = '必填';
        }

        if(!data.bankProvince) {								
          errors.bankProvince = '必填';
        }
       
        if(!data.bankCity) {								
          errors.bankCity = '必填';
        }

        if(!data.bankBranch) {								
          errors.bankBranch = '必填';
        }        
       
        if(!data.cardnumber) {								
          errors.cardnumber = '必填';
        }else if(!numberRegExp.test(data.cardnumber)) {
          errors.cardnumber = '请输入数字';
        }

        if(!fileImg1) {
          errors.fileImg1 = "请先上传图片"
        }

        return errors;
    },
    onSubmit: async(data) => {    
      const uploadHandler = async(fileImg) => {
        setIsLoading(true)
        const filterData = {
          fileImg: fileImg          
        }              
           
        try {
          const { listResponse, status, statusText } = await uploadfile(filterData);        
          console.log(filterData);
          console.log("listResponse: ", listResponse)
    
          if (status === 200) {
            // 將API返回的URL傳回給父組件
            return listResponse.url                      
                      
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
            showToast("error", "上傳圖片失敗")
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
          // setFile(null);
          // setPreview(null);
          // event.target.value = null;
        }
      }
      //等待圖片上傳 並回傳URL
      const img_url = await uploadHandler(fileImg1)      
      
      const addCard = async () => {   
        setIsLoading(true)
        const filterData = {     
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),         
          staffcardnumber: data.cardnumber,
          staffname: Cookies.get("username"),
          bankname: data.selectBankName.銀行名稱,
          bankprovince: data.bankProvince,
          bankcity: data.bankCity,
          bankbranch: data.bankBranch,
          paymentamountdaylimit: data.dailyCash,
          paymentcountdaylimit: data.dailyCount,
          pur_payment_limit: 0,
          img_url: img_url
        };    
        
        try {
          
          const { listResponse, status, statusText } = await web_17_add_staffcardnumber(filterData);       
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {                     
            setIsRefresh(prev => !prev)
            setShowAddCard(false)              
            showToast("success", "" + status, `成功新增` ) 
            formik.resetForm();           
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      addCard()     

      
    }
    
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  useEffect(() => {    

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    }
    
    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_50_get_bank_name(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {          
          setBankOption(listResponse)            
                   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [isRefresh]);

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[600px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-lg'>
          <form className='flex flex-col' onSubmit={formik.handleSubmit} onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-700 p-4 text-lg'>新增卡片</div>
                    
            <div className='space-y-2 text-sm my-2 px-8 py-4 h-[60vh] overflow-y-scroll'>
              <label htmlFor='selectBankName'>银行名称</label>
              <div className='flex flex-row items-center space-x-4'>                  
                <Dropdown filter name="selectBankName" className='custom-dropdown' value={formik.values.selectBankName} onChange={formik.handleChange} options={bankOption} filter optionLabel="銀行名稱" placeholder="请选择银行"/>
                {uuidFirst === 9 && 
                  <Button label='新增銀行' severity='info' onClick={(e) => {e.preventDefault(); setShowAddBankName(true)}}></Button>
                }
              </div> 

              <div>
                <label htmlFor='bankCity'>开户城市</label>
                <input name="bankCity" value={formik.values.bankCity} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('bankCity')}
              </div>

              <div>
                <label htmlFor='bankProvince'>开户省份</label>
                <input name="bankProvince" value={formik.values.bankProvince} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('bankProvince')}
              </div>

              <div>
                <label htmlFor='bankBranch'>开户支行</label>
                <input name="bankBranch" value={formik.values.bankBranch} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('bankBranch')}
              </div>           

              <div>
                <label htmlFor='cardnumber'>卡号</label>
                <input name="cardnumber" value={formik.values.cardnumber} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" maxLength="30"/>
                {getFormErrorMessage('cardnumber')}
              </div>

              <div>
                <label htmlFor='dailyCash'>日收款额上限</label>
                <input name="dailyCash" value={formik.values.dailyCash} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCash')}
              </div>

              <div>
                <label htmlFor='dailyCount'>日收款次数上限</label>
                <input name="dailyCount" value={formik.values.dailyCount} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCount')}
              </div>

              <div>
                <label htmlFor='fileImg'>图片预览</label>
                <ImgInput fileImg1={fileImg1} setFileImg1={setFileImg1}/>
                {!fileImg1 ? <small className="p-error">请先上传图片</small> : <small className="p-error">&nbsp;</small>}
              </div>             

            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label='取消新增' severity='secondary' raised onClick={() => setShowAddCard(false)}></Button>
              <Button style={{width: "120px"}} label='确认新增' severity='info' type="submit" raised></Button>
            </div>
          </form>
         
        </div>

        {/* 新增銀行 */}
        {showAddBankName && <AddBankName setShowAddBankName={setShowAddBankName}/>}
      </Dialog>
    </>
  );
};

export default AddCard;