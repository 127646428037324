import React, { useState, useContext, useRef } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import { web_68_logout_all } from '../../../api/api';
import { useLocation } from 'react-router-dom';


const LogoutAllConfirm = (props) => {

  const {setShowLogoutAll} = props
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()    

  const logoutAllHandler = async() => {
    setIsLoading(true)
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")        
    }  

    try {
      
      const { listResponse, status, statusText } = await web_68_logout_all(filterData);       
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {                     
        showToast("success", "" + status, `成功登出所有使用者` )
        setIsRefresh(prev => !prev)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-h-[800px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col p-4'>            
            <div className='p-4 flex flex-col'>              
              <div>
                <p className=''>确定要登出所有使用者吗</p>
                <p className='text-sm text-red-600'>*自身帐号也将一并登出</p>               
              </div>                      
            </div>
            <div className='border-gray-500 flex justify-end items-center space-x-4'>
              <Button style={{width: '80px'}} label='取消' severity='secondary' raised onClick={() => setShowLogoutAll(false)}></Button>
              <Button style={{width: '80px'}} label='确认' severity='info' raised onClick={logoutAllHandler}></Button>              
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LogoutAllConfirm;