import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { web_96_get_collect_asyncnotify } from '../../api/api';
import PaginatorUI from '../../components/UI/paginatorUI';
import Dialog from '../../components/dialog'
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';
import FilterInput from '../../components/UI/filterInput';

const OrderNotify = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [orderNotifyData, setOrderNotifyData] = useState([])
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(10)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const location = useLocation()
  const [messageData, setMessageData] = useState(null)
  const [notifyUrlData, setNotuifyData] = useState(null)
  const tableScrollY = useTableSize(70, 70)
  const [filterName, setFilterName] = useState("")
    
  const sysText10015 = useSystemText(10015)
  const sysText10016 = useSystemText(10016)
  const sysText10017 = useSystemText(10017)
  const sysText10018 = useSystemText(10018)
  const sysText10019 = useSystemText(10019)
  const sysText10020 = useSystemText(10020)
  const sysText10021 = useSystemText(10021)
  const sysText10029 = useSystemText(10029)
  const sysText10030 = useSystemText(10030)
  const sysText10031 = useSystemText(10031)
  const sysText10040 = useSystemText(10040)
  const sysText10043 = useSystemText(10043)

  const sysText206001 = useSystemText(206001)
  const sysText206002 = useSystemText(206002)
  const sysText206003 = useSystemText(206003)
  const sysText206004 = useSystemText(206004)
  const sysText206005 = useSystemText(206005)
  const sysText206006 = useSystemText(206006)
  const sysText206007 = useSystemText(206007)
  const sysText206008 = useSystemText(206008)     
  const sysText206009 = useSystemText(206009)   
  const sysText206010 = useSystemText(206010)     
  const sysText206011 = useSystemText(206011)
  const sysText206013 = useSystemText(206013)

  const NotifyUrlColumn = (rowData) => {
    return rowData?.通知回調地址 ? 
    <div className="flex">
      <Button label={sysText10043} severity='info' text raised onClick={(e) => {setNotuifyData(rowData?.通知回調地址)}}></Button>
    </div>
    : ""
  }
  const NotifyUrlDialog = () => {
    if (notifyUrlData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setNotuifyData(null)}
        header={sysText206006}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText10043}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            {notifyUrlData}
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setNotuifyData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const MessageColumn = (rowData) => {
    return rowData.響應訊息 ? 
    <div className="flex">
      <Button label={sysText10043} severity='info' text raised onClick={(e) => {setMessageData(rowData?.響應訊息)}}></Button>
    </div>
    : ""
  }
  const MessageDialog = () => {
    if (messageData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setMessageData(null)}
        header={sysText206009}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText206009}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            {messageData}
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setMessageData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const OrderStatusColumn = (item) => {

    const statusCheck = () => {   
      let label = ''     
      switch (item.原訂單狀態) {
        case 0:
          label = sysText10015
          break
        case 1:
          label = sysText10016
          break
        case 2:
          label = sysText10017
          break
        case 3:
          label = sysText10018
          break
        case 4:
          label = sysText10019
          break
        case 5:
          label = sysText10020
          break        
        case 9:
          label = sysText10021
          break
        default:
          label = '';
      }

      return label          
    }      

    const label = statusCheck()

    return <p>{label}</p>
  }

  const ActiveColumn = (item) => {

    const statusCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.訂單狀態) {
        case 0:
          label = sysText10029
          severity = "secondary"
          break
        case 1:
          label = sysText10030
          severity = "info"
          break        
        case 7:
          label = sysText10040
          severity = "warning"
          break
        case 8:
          label = sysText10031
          severity = "help"
          break
        case 9:
          label = sysText10021
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }   

    const {label, severity} = statusCheck() 

    return (
      <div className='flex flex-col items-center'>
        <Button label={label} severity={severity} />       
      </div>    
    )
  }   

  const tableColumns = [
    {
      header: sysText206001,
      field: "商戶號",
      style: {minWidth: "80px"},
    },
    {
      header: sysText206002,
      field: "時間紀錄",
      style: {minWidth: "120px"},
    },
    {
      header: sysText206003,
      field: "訂單號",
      style: {minWidth: "120px"},
    },
    {
      header: sysText206004,
      field: "訂單金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText206005,
      field: "原訂單狀態",
      style: {minWidth: "120px"},
      body: OrderStatusColumn
    },
    {
      header: sysText206006,
      field: "通知回調地址",
      style: {minWidth: "100px"},
      body: NotifyUrlColumn
    },
    {
      header: sysText206007,
      field: "上次通知時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText206008,
      field: "上次通知IP",
      style: {minWidth: "150px"},
    },
    {
      header: sysText206009,
      field: "響應訊息",
      style: {minWidth: "100px"},
      body: MessageColumn
    },
    {
      header: sysText206010,
      field: "通知次數",
      style: {minWidth: "80px"},
    },
    {
      header: sysText206011,
      field: "訂單狀態",
      style: {minWidth: "120px"},
      headerClassName: "centered-header",
      body: ActiveColumn
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      page: page + 1,
      limit: rows,
      table_type: "1",
      select: filterName      
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_96_get_collect_asyncnotify(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          setOrderNotifyData(listResponse.data)
          setTotalCount(listResponse.筆數)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [isRefresh, page, first, rows, filterName]);  

  return (  
     
    <div className='flex flex-col'>      
      <FilterInput showCalendarInput={false} setFilterNumber={setFilterName} placeholder={sysText206013}>
        <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
      </FilterInput>         
      <div className="px-2 tableWrap">               

        <DataTable value={orderNotifyData} scrollable scrollHeight={tableScrollY}>
          {visibleColumns.map((row,index) =>             
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
          )}              
        </DataTable>

        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
      
      </div>      
      {/* 通知回調地址 */}
      {notifyUrlData && <NotifyUrlDialog/>}
      {/* 響應訊息 */}
      {messageData && <MessageDialog/>}    
    </div>
   
  );
}

export default OrderNotify;