import { useContext } from "react";
import ToastContext from "../context/ToastContext";

function useSystemText(id) {

  const {showToast} = useContext(ToastContext)

  let data = sessionStorage.getItem("systemText")
  try {
    data = JSON.parse(data)
    if(data && Array.isArray(data)) {
      const getDataForID = data.find(item => item.id === id)
      
      if(getDataForID) {
        return getDataForID?.翻譯過後語言;
      }
       
      return "无资料" 
    }
    return "无资料"
    
  } catch(e) {
    showToast("warning", "取得系统文字失败")
  } 
}

export default useSystemText