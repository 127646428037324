import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { web_40_merchantno_money, web_41_am_record } from '../../../api/api';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import ToastContext from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import useSystemText from '../../../helper/useSystemText';

const ApplyWithdraw = (props) => {

  const {setShowApplyWithdraw} = props
  const [canWithdrawAmount, setCanWithdrawAmount] = useState(0)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText203028 = useSystemText(203028)
  const sysText203029 = useSystemText(203029)
  const sysText203032 = useSystemText(203032)
  const sysText203016 = useSystemText(203016)

  const formik = useFormik({
    initialValues: {
      withdrawAmount: 0.00,
      remark: '',
    },
    validate: (data) => {
        let errors = {};      
        const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/ 

        if (!data.withdrawAmount) {								
          errors.withdrawAmount = '必填';
        }else if(!numberRegExp.test(data.withdrawAmount)) {
          errors.withdrawAmount = '必须是数字'
        }
        
        if (data.remark && data.remark.length > 50) {								
          errors.remark = '不可超過50字';
        }

        return errors;
    },
    onSubmit: (data) => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        ori_amount: canWithdrawAmount,
        change_amount: data.withdrawAmount,
        remark: data.remark              
      };    
  
      const confirmWithdraw = async () => {       
        try {
          
          const { listResponse, status, statusText } = await web_41_am_record(filterData);       
          
          console.log("listResponse: ", listResponse)
  
          if (status === 200) {     
            setIsRefresh(prev => !prev)                
            showToast("success", `成功申请提领 金额: ${filterData.change_amount}` )
            setShowApplyWithdraw(false)
            // formik.resetForm();
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      confirmWithdraw()     
      
    }
  }); 
  
  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  useEffect(() => {         

    const fetchData = async() => {
      
      setIsLoading(true)       

      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash")      
      };

      try {
        const { listResponse, status, statusText } = await web_40_merchantno_money(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                
          setCanWithdrawAmount(listResponse)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, []);   

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText203028}</div>
            <div className='p-6 space-y-2 flex-1'>
              <p>{sysText203029}： {canWithdrawAmount}</p>              
              <div>
                <label htmlFor="withdrawAmount">{sysText203032}</label>
                <input name="withdrawAmount" type="text" value={formik.values.withdrawAmount} autoComplete='off' onChange={formik.handleChange} className='w-full' placeholder='提领金额' />
                {getFormErrorMessage('withdrawAmount')}
              </div> 
                          
              <div>
                <label htmlFor="remark">{sysText203016}</label>
                <input name="remark" type="text" value={formik.values.remark} autoComplete='off' onChange={formik.handleChange} className='w-full' placeholder='请输入备注(可留空)' />
                {getFormErrorMessage('remark')}
              </div>   
                         
            </div>    

            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label={sysText10003} severity='secondary' onClick={() => setShowApplyWithdraw(false)}></Button>
              <Button type='submit' style={{width: "120px"}} label={sysText10002} severity='info'></Button>
            </div>       
          </form>        
        </div>
        
        {/* </div> */}
      </Dialog>
    </>
  );
};

export default ApplyWithdraw;