import React, {useState} from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';

const ShareRecordCard = (props) => {

  const {item, displayDate} = props
  const [showDetail, setShowDetail] = useState(false)

  return (
    <div className='flex flex-col w-[50%] max-2xl:w-[100%] p-4'>
      <div className='bg-customColor-bgLightGray px-4 rounded-lg'>
        <div className='flex flex-row justify-between items-center h-14 border-b border-gray-600'>
          <p className='hover:text-blue-500 duration-100 cursor-pointer underline text-yellow-300' onClick={() => {setShowDetail(true)}}>{item.用戶名稱}</p>
          <p>{displayDate}</p>
        </div>          
        <div className='flex my-4 h-14'>            
          <div className='flex flex-col items-center justify-center w-[50%] border-r'>
            <p>用户营业额</p>
            <p>{item.用戶營業額}</p>
          </div>
          <div className='flex flex-col items-center justify-center w-[50%]'>
            <p>营业额提成</p>
            <p>{item.營業額提成}</p>
          </div>
        </div>
      </div>    

      {showDetail &&  
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>記錄列表</div>
            <div className='p-4'>
              <p>無資料</p>
            </div>            
            
            <div className='flex justify-around items-center border-t border-gray-700 py-4'>
              <Button style={{width: "120px"}} label='OK' severity='info' outlined onClick={() => setShowDetail(false)}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog> 
      }
       
    </div>

    
  );
};

export default ShareRecordCard;