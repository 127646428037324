import React, { useState } from 'react';
import { API_URL } from '../api/api';

function Test() {
  const [message, setMessage] = useState('');
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleTest01ButtonClick = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000);
    const apiUrl = `${API_URL}/test_01`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      const data = await response.json();
      setMessage(`测试信息01: ${JSON.stringify(data)}, API URL: ${apiUrl}`);
    } catch (error) {
      if (error.name === 'AbortError') {
        setMessage(`测试信息01: 超时120s, API URL: ${apiUrl}`);
      } else {
        setMessage(`测试信息01: Error: ${error}, API URL: ${apiUrl}`);
      }
    }
  };

  const handleTest02ButtonClick = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000);
    const apiUrl = `${API_URL}/test_02`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        signal: controller.signal,
      });
      clearTimeout(timeoutId);
      const data = await response.json();
      setMessage(`测试信息02: ${JSON.stringify(data)}, API URL: ${apiUrl}`);
    } catch (error) {
      if (error.name === 'AbortError') {
        setMessage(`测试信息02: 超时120s, API URL: ${apiUrl}`);
      } else {
        setMessage(`测试信息02: Error: ${error}, API URL: ${apiUrl}`);
      }
    }
  };
  const handleLoginTestButtonClick = () => {
    setShowLogin(true);
  };

  const handleLoginSubmit = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000); //設置超時時間為120s
    const apiUrl = `${API_URL}/web_01_login`; //將API URL修改為你的登入API URL
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "password": "iQ3JSMvwvfgEUf92YWAACUMtkxyeJXfGmvqJtSGW",
          "user": username,
          "user_password": password,
          "cookie_hash": "",
          "cookie_uuid": "",
          "verification_code": ""
        })
      });
      clearTimeout(timeoutId); //清除設置的超時時間
      const data = await response.json();
      setMessage(`登入測試: ${JSON.stringify(data)}, API URL: ${apiUrl}`);
    } catch (error) {
      if (error.name === 'AbortError') {
        setMessage(`登入測試: 超時120s, API URL: ${apiUrl}`);
      } else {
        setMessage(`登入測試: 錯誤: ${error}, API URL: ${apiUrl}`);
      }
    }
    setShowLogin(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message);
  };

  return (
    <div style={{ margin: '20px' }}>
      <button style={{ margin: '20px', marginBottom: '10px', backgroundColor: 'lightblue', color: 'black' }} onClick={handleTest01ButtonClick}>测试按钮01</button>
      <button style={{ margin: '20px', marginBottom: '10px', backgroundColor: 'lightblue', color: 'black' }} onClick={handleTest02ButtonClick}>测试按钮02</button>
      <button style={{ margin: '20px', marginBottom: '10px', backgroundColor: 'lightblue', color: 'black' }} onClick={handleLoginTestButtonClick}>测试登入按钮</button>
      {showLogin && (
        <div>
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" />
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
          <button style={{ margin: '20px', marginBottom: '10px', backgroundColor: 'lightblue', color: 'black' }} onClick={handleLoginSubmit}>提交</button>
        </div>
      )}
      <textarea style={{ display: 'block', color: 'black', backgroundColor: 'white', marginBottom: '10px', width: '100%' }} rows={10} readOnly value={message} />
      <button style={{ display: 'block', backgroundColor: 'lightblue', color: 'black' }} onClick={copyToClipboard}>复制信息</button>
      <p>请点击测试按钮后，再点击复制信息，信息就会复制到剪贴簿中，然后将信息发给客服。</p>
    </div>
  );
}

export default Test;
