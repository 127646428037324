import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { web_32_retract_pur_raw_order } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import useSystemText from '../../../helper/useSystemText';

const PurchaseRecordRevoke = (props) => {
  const {setShowRevoke, revokeData} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  
  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText306004 = useSystemText(306004)
  const sysText306028 = useSystemText(306028)
  const sysText306029 = useSystemText(306029)

  const revokeHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      confirmpeople: Cookies.get("cookie_uuid"),
      merchantno: revokeData?.採購商戶號,
      ordernumber: revokeData?.採購單編號,
      amount: revokeData?.採購單金額,
      notifyurl: "",
      retpro_pur_pair_state: "1"
    }
   
    try {
      const {listResponse, status, statusText} = await web_32_retract_pur_raw_order(filterData)

      if (status === 200) {         
        showToast("success", "" + status, `成功撤回 订单编号:${revokeData.採購單編號}` )
        setIsRefresh(prev => !prev)          
        setShowRevoke(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>{sysText306028}</div>
            <div className='p-4 text-sm space-y-4'>
              <p>{sysText306004}:</p>
              <p className='text-yellow-400'>{revokeData.採購單編號}</p>
              <p className='text-gray-500'>{sysText306029}？</p>
            </div>            
            
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowRevoke(false)}></Button>
              <Button style={{width: '120px'}} label={sysText10002} severity='info' raised onClick={revokeHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseRecordRevoke;