import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { web_23_ncc_get_gateway, web_57_ncc_set_card, web_57_set_card } from '../../../api/api';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import useSystemText from '../../../helper/useSystemText';

const EditCardNcc = (props) => {

  const {editCardData, setShowEditCard} = props
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [selectState, setSelectState] = useState(String(editCardData.狀態))
  const [gatewayList, setGatewayList] = useState([])
  // const [info, setInfo] = useState({})

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  
  const sysText701003 = useSystemText(701003)
  const sysText701004 = useSystemText(701004)
  const sysText701005 = useSystemText(701005)
  const sysText701006 = useSystemText(701006)
  const sysText701009 = useSystemText(701009)
  const sysText701022 = useSystemText(701022)
  const sysText701018 = useSystemText(701018)
  const sysText701011 = useSystemText(701011)
  const sysText701020 = useSystemText(701020)
  const sysText701021 = useSystemText(701021)
  const sysText701023 = useSystemText(701023)
  const sysText701024 = useSystemText(701024)
  const sysText701025 = useSystemText(701025)
  const sysText701026 = useSystemText(701026)
  const sysText701027 = useSystemText(701027)
  const sysText701028 = useSystemText(701028)
  const sysText701029 = useSystemText(701029)

  const stateOption = [
    {
      name: sysText701025,
      value: "0"
    },
    {
      name: sysText701026,
      value: "1"
    },
    {
      name: sysText701027,
      value: "2"
    },
    {
      name: sysText701028,
      value: "3"
    },
  ]
  
  const formik = useFormik({
    initialValues: {
      info: "",
      // payment: editCardData.收款人姓名,
      balance: editCardData.餘額,
      paymentAmountDayLimit: editCardData.日收款額上限,              
      singlePaymentUpperLimit: editCardData.單筆上限,
      singlePaymentLowerLimit: editCardData.單筆下限 

    },
    validate: (data) => {
      let errors = {};  
      const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/      

      data.info && Object.values(data.info).map((item) => {
        if (!item) {
          errors.info = '必填';
        }
      })
      
      if (data.balance === null) {								
        errors.balance = '必填';
      } if(!numberRegExp.test(data.balance)) {
        errors.balance = "必須為數字"
      }   
      
      // if (data.currentCount === null) {								
      //   errors.currentCount = '必填';
      // } if(!numberRegExp.test(data.currentCount)) {
      //   errors.currentCount = "必須為數字"
      // }  

      if(data.paymentAmountDayLimit === null) {								
        errors.paymentAmountDayLimit = '必填';
      }else if(!numberRegExp.test(data.paymentAmountDayLimit)) {
        errors.paymentAmountDayLimit = "必須為數字"
      }  

      if(data.singlePaymentUpperLimit === null) {								
        errors.singlePaymentUpperLimit = '必填';
      }else if(!numberRegExp.test(data.singlePaymentUpperLimit)) {
        errors.singlePaymentUpperLimit = "必須為數字"
      }         
     
      if(data.singlePaymentLowerLimit === null) {								
        errors.singlePaymentLowerLimit = '必填';
      }else if(!numberRegExp.test(data.singlePaymentLowerLimit)) {
        errors.singlePaymentLowerLimit = "必須為數字"
      }  

      if (data.state === "") {								
        errors.state = '必填';
      }

      return errors;
    },
    onSubmit: async(data) => {  

      try {

        let infoJson = JSON.stringify(data.info)

        setIsLoading(true)
        let filterData = {     
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          id: editCardData.id,        
          paymentamountdaylimit: data.paymentAmountDayLimit,
          single_payment_upper_limit: data.singlePaymentUpperLimit,
          single_payment_lower_limit: data.singlePaymentLowerLimit,
          balance: data.balance,
          info: infoJson,        
          old_paymentamountdaylimit: editCardData.日收款額上限,
          old_single_payment_upper_limit: editCardData.單筆上限,
          old_single_payment_lower_limit: editCardData.單筆下限,
          old_balance: editCardData.餘額,
          old_info: editCardData.資訊,
          state: selectState      
        };      
        
        const { listResponse, status, statusText } = await web_57_ncc_set_card(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEditCard(false)              
          showToast("success", "" + status, `成功编辑卡片` )           
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };
  
  const jsonToObj = () => {
    try {
      const data = JSON.parse(editCardData?.資訊)
      formik.setFieldValue("info", data)
    } catch (e){
      formik.setFieldValue("info", "")
      showToast("error", "该笔资料登录帐号 | 钱包格式有误")
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const infoChangeHandler = (e, key) => {
    console.log(e);
    let newData = {...formik.values.info}
    newData[key] = e.target.value
    formik.setFieldValue("info", newData)
  }
  
  useEffect(() => {
    jsonToObj()
  },[editCardData])

  useEffect(() => {    
        
    const fetchGateWayData = async() => {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id_list: editCardData.通道  
      }
      
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_23_ncc_get_gateway(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {          
          setGatewayList(listResponse)            
                   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchGateWayData()

    
  }, [isRefresh, formik.values.selectGatewayType]);

  console.log("formik.values.info: ", formik.values.info);
      
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[35vw] min-w-[350px] max-h-[800px] max-md:w-full bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText701022}</div>          
          
          {/* {(uuidFirst === 1 || uuidFirst === 2) &&
             <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>         
              
              <div>
                <label htmlFor='paymentAmountDayLimit'>日收款额上限</label>
                <input name="paymentAmountDayLimit" value={formik.values.paymentAmountDayLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('paymentAmountDayLimit')}
              </div>  
  
              <div>
                <label htmlFor='singlePaymentUpperLimit'>单笔上限</label>
                <input name="singlePaymentUpperLimit" value={formik.values.singlePaymentUpperLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('singlePaymentUpperLimit')} 
              </div>

               <div>
                <label htmlFor='singlePaymentLowerLimit'>单笔下限</label>
                <input name="singlePaymentLowerLimit" value={formik.values.singlePaymentLowerLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('singlePaymentLowerLimit')} 
              </div>         
                                      
            </div>
          }         */}
         
         
            <div className='space-y-4 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
              <div className='space-x-4 space-y-4'>
                
                {/* 根據選擇的通道類型 取出需要的欄位建立input */}
                {formik.values.info && Object.keys(formik.values.info).length > 0 &&           
                <>    
                  <label htmlFor='info'>{sysText701005}</label>
                  {Object.entries(formik.values.info).map(([key, value]) => (                  
                      <div key={key}>
                        <label htmlFor={key}>{key}</label>
                        <input name={key} value={value} autoComplete='off' onChange={(e) => {infoChangeHandler(e, key)}} className='w-full' type="text" />                        
                        {getFormErrorMessage('info')}
                      </div>                 
                  ))}
                </>        
                }      
                
              </div>

              <div className='flex flex-col space-x-4'>
                <label htmlFor='gateway'>{sysText701004}</label>
                <div className='flex flex-row flex-wrap'>               
                  {gatewayList.length > 0 ? gatewayList.map((row => 
                    <Button type='text' severity='info' className='border rounded-md p-2 m-1' disabled={true} onClick={(e) => {e.preventDefault()}}>
                      {row.顯示名稱}
                    </Button>                
                  )) : <p>{sysText701023}</p>}  
                </div>                                
                                
              </div>

              {/* <div>
                <label htmlFor='payment'>收款人</label>
                <input name="payment" value={formik.values.payment} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('payment')}
              </div> */}
            {(uuidFirst === 3 || uuidFirst === 9) && 
            <>            
              <div>
                <label htmlFor='balance'>{sysText701009}</label>
                <input name="balance" value={formik.values.balance} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('balance')}
              </div>  
  
              <div>
                <label htmlFor='currentCash'>{sysText701024}</label>
                <input name="currentCash" value={editCardData.當前收款額} disabled={true} autoComplete='off' onChange={formik.handleChange} className='w-full opacity-30 cursor-not-allowed' type="text" />
                {getFormErrorMessage('currentCash')} 
              </div> 
            </>               
            }
              <div>
                <label htmlFor='paymentAmountDayLimit'>{sysText701018}</label>
                <input name="paymentAmountDayLimit" value={formik.values.paymentAmountDayLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('paymentAmountDayLimit')}
              </div>
  
              <div>
                <label htmlFor='singlePaymentUpperLimit'>{sysText701020}</label>
                <input name="singlePaymentUpperLimit" value={formik.values.singlePaymentUpperLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('singlePaymentUpperLimit')} 
              </div>

               <div>
                <label htmlFor='singlePaymentLowerLimit'>{sysText701021}</label>
                <input name="singlePaymentLowerLimit" value={formik.values.singlePaymentLowerLimit} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('singlePaymentLowerLimit')} 
              </div>          
            {(uuidFirst === 3 || uuidFirst === 9) &&
              <div className='flex flex-row space-x-4'>
                <div className='flex items-center space-x-4'>
                  <label htmlFor='state'>{sysText701029}</label>                 
                  <Dropdown className='custom-dropdown' value={selectState} onChange={(e) => setSelectState(e.value)} options={stateOption} optionLabel="name"/>
                </div>                
              </div>
            }                                       
            </div>
                

          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{width: "120px"}} label={sysText10003} severity='secondary' raised onClick={() => setShowEditCard(false)}></Button>
            <Button type="submit" style={{width: "120px"}} label={sysText10002} disabled={isLoading} severity='info' raised></Button>
          </div>
        
        </form>
          
        </div>
      </Dialog>
    </>
  );
};

export default EditCardNcc;