import Copy from 'clipboard-copy';
import { useContext } from 'react';
import ToastContext from '../context/ToastContext';

function useCopy() {
  const { showToast } = useContext(ToastContext);

  const copyHandler = (text) => {
    Copy(text).then(() => {
      showToast("success", "已复制內容");
    });
  };

  return { copyHandler };
}

export default useCopy