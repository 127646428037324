import React from 'react';
import useSystemText from '../helper/useSystemText';

const Home = () => {  

  const sysText100001 = useSystemText(100001) 
 
  return (
    <div className='flex justify-center items-center text-center w-full h-full'>
      <div className='w-full'>
        <pre className='whitespace-pre-line'>
          {sysText100001}
        </pre>
        {/* <p>亲爱的使用者，</p>
        <p>在此对您表达由衷的感谢。您的使用和信任是我们努力的动力，也是系统进步的原动力。</p>
        <p>系统的每一项功能、每一个更新都是为了更好地满足您的需求。每一次使用，都是一次对系统的信任和期望。</p>
        <p>我们深知，这也是对我们工作的最大肯定。</p>
        <p>当您遇到困难或疑惑时，请记得我们团队随时为您提供协助，就像您的身边有一个值得信赖的伙伴。</p>
        <p>每一次的反馈和建议，我们都会认真听取并不断改进，让系统更加完善。</p>
        <p>最后，谢谢您一直以来的支持与陪伴。愿您的每一天都充满阳光，充满正能量。</p>
        <p>期待与您共同见证系统的进步与成长！</p>
        <p>祝您有愉快的一天。</p>         */}
      </div>      
    </div>
  );
};

export default Home;