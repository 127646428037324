import React, { useContext, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_15_ncc_add_order, web_18_get_merchant, web_22_ncc_get_info_to_add_ncc_order, web_23_ncc_get_gateway} from "../../api/api";
import { Dropdown } from "primereact/dropdown";
import useSystemText from "../../helper/useSystemText";

const RecordNccAdd = () =>  {    

    const {showToast} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()		
    const [cardData, setCardData] = useState([])
    // const [merchantData, setMerchantData] = useState([])
    
    const [uuidOption, setUuidOption] = useState([])
    // const [payerInfoOption, setPayerInfoOption] = useState([])
    const [staffInfoOption, setstaffInfoOption] = useState([])
    //選員工資訊後取得id_list，傳入web23用
    const [gatewayList, setGatewayList] = useState("")
    const [gatewayOption, setGatewayOption] = useState([]) 

    const sysText205001 = useSystemText(205001)
    const sysText205002 = useSystemText(205002)
    const sysText205003 = useSystemText(205003)
    const sysText205004 = useSystemText(205004)
    const sysText205005 = useSystemText(205005)
    const sysText205006 = useSystemText(205006)
    const sysText205007 = useSystemText(205007)
    const sysText205008 = useSystemText(205008)     
    const sysText205009 = useSystemText(205009)     
    const sysText205010 = useSystemText(205010)     
    const sysText205011 = useSystemText(205011)     

    console.log("gatewayOption: ", gatewayOption);

    //mallname前3位+手派訂單縮寫前3位+時間（年月日時分秒毫秒）+亂數英文或數字3位
    function generateCode() {     
      let mallname = ""
      if(Cookies.get("mallname")) {
        mallname = Cookies.get("mallname").slice(0, 3)
      }
  
      // 獲取當前時間 YYYYMMDDHHmmssms
      let date = new Date();

      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      let milliseconds = ("00" + date.getMilliseconds()).slice(-3);
  
      const timeStr = year + month + day + hours + minutes + seconds + milliseconds;

      //手派訂單縮寫
      const mco = "mco"

      // 產生0-999999的隨機數
      const randomNum = Math.floor(Math.random() * 1000);

      // 將隨機數轉為字串，並在不足6位的情況下在前面補0
      const randomNumStr = String(randomNum).padStart(3, '0');

      // 將隨機數和時間戳組合成最終的代碼
      const code = mallname + mco + timeStr + randomNumStr
  
      return code;
    }

    //primeReact Dropdown套件能夠正常換行 whitespace-normal
    const staffInfoItemTemplate = (option) => {
      return (                
        <p className="whitespace-normal">{option}</p>       
      );
    };
    
    const formik = useFormik({
      initialValues: {
          ordernumber: '',          
          productprice: '',
          payerInfo: '',
          merchantno: '',
          uuid: '',
          staffInfo: '',              
          selectGateway: '',
          verificationCode: ''
      },
      validate: (data) => {
          let errors = {};     
          const ordernumberRegExp = /^[A-Za-z0-9]{1,30}$/
          const numberRegExp = /^\d*(\.\d+)?$/    
          const verificationCodeRegExp = /^\d{1,10}$/ 
          const amountRegExp = /^([1-9]\d*)(\.\d{1,2})?$/

          if (!data.ordernumber) {								
            errors.ordernumber = '必填';
          }else if(!ordernumberRegExp.test(data.ordernumber)) {
            errors.ordernumber = 'a-Z 或 0-9'
          }         

          if (!amountRegExp.test(data.productprice)) {
            errors.productprice = '必须为数字，且不能为0'
          }

          if (!data.payerInfo) {								
            errors.payerInfo = '必填';
          }

          if (!data.merchantno) {								
            errors.merchantno = '必填';
          }
          
          if (!data.uuid) {								
            errors.uuid = '必填';
          }

          if (!data.staffInfo) {								
            errors.staffInfo = '必填';
          }          

          if (!data.selectGateway) {								
            errors.selectGateway = '必填';
          }

          if (data.verificationCode && !verificationCodeRegExp.test(data.verificationCode)) {
            errors.verificationCode = '必须为数字'
          }

          return errors;
      },     
      
      onSubmit: (data) => {
        
          const filterData = {  
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"),   
          	uuid: data.uuid,
          	ordernumber: data.ordernumber,
          	productprice: data.productprice,
            payer_info: data.payerInfo,
          	staff_info: data.staffInfo,           
            merchantno: data.merchantno,
            confirmpeople: Cookies.get("cookie_uuid"),
            source: "root",
            gateway_id: data.selectGateway.id,
            verification_code: data.verificationCode
          };    
      
          const addOrder = async () => {     
            setIsLoading(true)  
          	try {
          		
          		const { listResponse, status, statusText } = await web_15_ncc_add_order(filterData);       
              
          		console.log("listResponse: ", listResponse)
      
          		if (status === 200) {                     
          			showToast("success", "" + status, `成功新增一笔订单 编号为:${data.ordernumber}` )
                formik.resetForm();
          		} else {
          			showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          		}
          	} catch (error) {        
          		console.error(location.pathname, error);            
          	} finally {
          		setIsLoading(false)
          	}
          }
          addOrder()  

          
      }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const handleChange = (e, dataIndex) => {     
      // 自定义的 handleChange 函数
      
      const { name, value } = e.target;        
                
      let filterList = []
      let staffInfoList = []
      let payerInfoList = []
      //判斷哪一個select被選擇，從cardData獲取資料，更改其他欄位option跟值
      switch(name) {
        case "uuid":               
          filterList = cardData.filter(row => row.indexOf(value) >= 0)                  
          console.log("filterList: ", filterList);
          filterList.map((row)=>{
            staffInfoList=([...staffInfoList, row[1]])
            payerInfoList=([...payerInfoList, row[2]])            
          })         
          setstaffInfoOption(staffInfoList)                 
          formik.setFieldValue(name, value)  
          formik.setFieldValue("staffInfo", filterList[0][1])          
          setGatewayList(filterList[0][3])                    
          break;        

        case "staffInfo":               
          filterList = cardData.filter(row => row.indexOf(value) >= 0)   
          
          formik.setFieldValue(name, value)   
          formik.setFieldValue("uuid", filterList[0][0])
          setGatewayList(filterList[0][3])
          break;

        case "selectGateway":          
          filterList = gatewayOption.filter(row => row === value)
          formik.setFieldValue(name, value)
          formik.setFieldValue("merchantno", filterList[0].商戶代號 ?? "")
          console.log("formik.values.selectGateway.商戶代號: ", formik.values.selectGateway.商戶代號);        
      }
      console.log("filterList: ", filterList)
      
    };

    useEffect(()=>{
      const web22_fetchData = async () => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash")
        }
             
        try {
          const { listResponse, status, statusText} = await web_22_ncc_get_info_to_add_ncc_order(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                         
            setCardData(listResponse.data)                                     
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      
      web22_fetchData()               

      formik.setFieldValue("ordernumber", generateCode())      
    },[location])

    // 設定選單列表 f3拿到數據並filter去除重複值
    useEffect(()=>{
      let uuidList = []      
      let staffInfoList = []            

      cardData.map((row) => {        
        uuidList = [...uuidList, row[0]]        
        staffInfoList = [...staffInfoList, row[1]]         
      })      
      
      setUuidOption(uuidList.filter((item, index) => uuidList.indexOf(item) === index))      
      setstaffInfoOption(staffInfoList.filter((item, index) => staffInfoList.indexOf(item) === index))            
    },[cardData])
    
    useEffect(()=>{
      const web23_ncc_fetchData = async () => {
        setIsLoading(true)
        const filterData = {
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"),
          id_list: gatewayList
        }
             
        try {
          const { listResponse, status, statusText} = await web_23_ncc_get_gateway(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                         
            setGatewayOption(listResponse)                                     
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }    
      if(formik.values.staffInfo !== "") {
        web23_ncc_fetchData()
      }else {
        setGatewayOption([])
      }       
              
    },[formik.values.staffInfo])

    console.log("formik.values.selectGateway: ", formik.values.selectGateway);

    return (
        <div className="systeminformationWrap card flex justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">{sysText205001}</div>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row flex-wrap justify-between">
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="ordernumber" className="text-blue-300">{sysText205002}</label>
                  </div>
                               
                  <input
                      id="ordernumber"
                      name="ordernumber"                  
                      className="p-2 w-64 opacity-70 cursor-not-allowed"
                      value={formik.values.ordernumber}
                      onChange={formik.handleChange}
                      placeholder={sysText205002}
                      autoComplete="off"
                      disabled={true}                      
                  />
                  {getFormErrorMessage('ordernumber')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="ordernumber" className="text-blue-300">{sysText205010}</label>
                  </div>
                               
                  <input
                      id="ordernumber"
                      name="ordernumber"                  
                      className="p-2 w-64 opacity-70 cursor-not-allowed"
                      value={formik.values.ordernumber}
                      onChange={formik.handleChange}
                      placeholder={sysText205010}
                      autoComplete="off"
                      disabled={true}                      
                  />
                  {getFormErrorMessage('ordernumber')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="productprice" className="text-blue-300">{sysText205003}</label>
                  </div>             
                  <input
                      id="productprice"
                      name="productprice"                  
                      className="p-2 w-64"
                      value={formik.values.productprice}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder={sysText205003}
                  />
                  {getFormErrorMessage('productprice')}
                </div>                

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="merchantno" className="text-blue-300">{sysText205004}</label>
                  </div>             
                  <input
                      id="merchantno"
                      name="merchantno"                  
                      className="p-2 w-64 cursor-not-allowed"
                      value={formik.values.merchantno}
                      // onChange={formik.handleChange}
                      placeholder={sysText205004}
                      // options={merchantData}
                      autoComplete="off"                      
                      disabled={true}
                  />
                  {getFormErrorMessage('merchantno')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="uuid" className="text-blue-300">{sysText205005}</label>
                  </div>             
                  <Dropdown
                      id="uuid"
                      name="uuid"                  
                      className="custom-dropdown w-64"
                      value={formik.values.uuid}
                      onChange={handleChange}
                      options={uuidOption}
                      filter
                      placeholder={sysText205005}
                  />
                  {getFormErrorMessage('uuid')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="staffInfo" className="text-blue-300">{sysText205006}</label>
                  </div>             
                  <Dropdown
                      id="staffInfo"
                      name="staffInfo"                  
                      className="custom-dropdown w-64"
                      value={formik.values.staffInfo}
                      onChange={handleChange}
                      options={staffInfoOption}
                      filter
                      itemTemplate={staffInfoItemTemplate}
                      placeholder={sysText205006}
                  />
                  {getFormErrorMessage('staffInfo')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="payerInfo" className="text-blue-300">{sysText205007}</label>
                  </div>             
                  <input
                      id="payerInfo"
                      name="payerInfo"                  
                      className="w-64 p-2"
                      value={formik.values.payerInfo}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder={sysText205007}
                  />
                  {getFormErrorMessage('payerInfo')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="selectGateway" className="text-blue-300">{sysText205008}</label>
                  </div>             
                  <Dropdown
                      id="selectGateway"
                      name="selectGateway"                  
                      className="custom-dropdown w-64"
                      value={formik.values.selectGateway}
                      onChange={handleChange}
                      options={gatewayOption}
                      // optionValue="id"
                      optionLabel="顯示名稱"
                      filter
                      placeholder={sysText205008}                     
                  />
                  {getFormErrorMessage('selectGateway')}
                </div> 

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="verificationCode" className="text-blue-300">{sysText205011}</label>
                  </div>             
                  <input
                      id="verificationCode"
                      name="verificationCode"                  
                      className="w-64 p-2"
                      value={formik.values.verificationCode}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder={sysText205011}
                  />                 
                  {getFormErrorMessage('verificationCode')}
                </div>               
              
              </div>
              
              <Button label={sysText205009} type="submit" disabled={isLoading} severity="info" raised className="w-36" />
              
            </form>
          </div>
          
        </div>
    )
}

export default RecordNccAdd;