import React, { useContext, useEffect, useState } from "react";
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_70_ncc_purchase_order_reversal, web_70_purchase_order_reversal } from "../../api/api";
import useSystemText from "../../helper/useSystemText";

const PurchaseReversal = () =>  {    

    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()
    const [ordernumberInput, setOrdernumberInput] = useState("")
    const [ordernumberNccInput, setOrdernumberNccInput] = useState("")

    const sysText304001 = useSystemText(304001)
    const sysText304002 = useSystemText(304002)
    const sysText304003 = useSystemText(304003)   
    
    const confirmHandler = async() => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        pur_ordernumber: ordernumberInput        
      }  

      try {
        
        const { listResponse, status, statusText } = await web_70_purchase_order_reversal(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `采购单冲正 子单号${filterData.pur_ordernumber}` )
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const confirmHandlerNcc = async() => {
      setIsLoading(true)
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        pur_ordernumber: ordernumberNccInput        
      }  

      try {
        
        const { listResponse, status, statusText } = await web_70_ncc_purchase_order_reversal(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          showToast("success", "" + status, `采购单冲正 子单号${filterData.pur_ordernumber}` )
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
   
    return (
        <div className="systeminformationWrap card flex flex-col justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">采购单沖正</div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col">
                <label>{sysText304002}</label>
                <input type="text" className="w-96" placeholder={sysText304002} name="ordernumber" value={ordernumberInput} onChange={(e) => setOrdernumberInput(e.target.value)} autoComplete="off"></input>     
              </div>                        
              <div>
                <Button label={sysText304003} onClick={confirmHandler} disabled={ordernumberInput === ""} severity="info" raised className="w-48"/>
              </div>
            </div>            
          </div>

          <div className="my-12 mx-10 p-8 border border-gray-700 overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">{sysText304001}</div>
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col">
                <label>{sysText304002}</label>
                <input type="text" className="w-96" placeholder={sysText304002} name="ordernumber" value={ordernumberNccInput} onChange={(e) => setOrdernumberNccInput(e.target.value)} autoComplete="off"></input>     
              </div>                        
              <div>
                <Button label={sysText304003} onClick={confirmHandlerNcc} disabled={ordernumberNccInput === ""} severity="info" raised className="w-48"/>
              </div>
            </div>            
          </div>          
        </div>
    )
}

export default PurchaseReversal;