import React, { useContext, useEffect, useState } from 'react';
import FilterInput from '../../components/UI/filterInput';
import { Button } from 'primereact/button';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../context/ToastContext';
import PurchaseMateRevoke from '../../components/purchaseManage/purchaseMate/purchaseMateRevoke';
import { web_14_find_pur_order, web_21_find_pur_order_card, web_88_pur_order_allotment_or_grab } from '../../api/api';
import Cookies from 'js-cookie';
import PurchaseMateSplit from '../../components/purchaseManage/purchaseMate/purchaseMateSplit';
import PurchaseMateConfirm from '../../components/purchaseManage/purchaseMate/purchaseMateConfirm';
import PurchaseMateForce from '../../components/purchaseManage/purchaseMate/purchaseMateForce';
import Dialog from '../../components/dialog';

const PurchaseMate = () => {
  const [filterNumber, setFilterNumber] = useState("")
  const [purchaseMateData, setPurchaseMateData] = useState([])
  const [orderCardData, setOrderCardData] = useState([])
  const [selectSplitData, setSelectSplitData] = useState({})
  const [splitDataList, setSplitDataList] = useState([])
  const [showSplit, setShowSplit] = useState(false)
  const [currentSplitId, setCurrentSplitId] = useState("")
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const [showRevoke, setShowRevoke] = useState(false)
  const [selectOrder, setSelectOrder] = useState({})
  const [selectCard, setSelectCard] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmData, setConfrimData] = useState({})
  const [showForce, setShowForce] = useState(false)
  const [cardSort, setCardSort] = useState("")
  const [showGrab, setShowGrab] = useState(false)
  const [grabData, setGrabData] = useState(null)

  console.log("selectCard: ", selectCard);
  console.log("splitDataList: ", splitDataList);
  console.log("selectSplitData: ", selectSplitData);
  console.log("selectOrder: ", selectOrder);
  console.log("cardSort: ", cardSort);

  const showRevokeHandler = () => {
    setShowRevoke(true)
  }

  const showSpiltHandler = (e, item) => {
    e.target.checked && setShowSplit(true)
    setCurrentSplitId(item.單號)        
  }

  const showConfirmHandler = () => {
    const index = splitDataList.findIndex(row => row.id === selectOrder.單號)    
    setSelectSplitData(splitDataList[index])
    setConfrimData(selectOrder)
    setShowConfirm(true)
  }

  const showForceHandler = () => {
    setShowForce(true)
  }

  const selectOrderHandler = (e, item) => {
    setSelectOrder(item)    
  }

  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectCard(orderCardData);
    } else {
      setSelectCard([]);
    }
  };

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectCard((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectCard((prevSelected) =>
        prevSelected.filter((row) => row.卡號 !== item.卡號)
      );
    }
  };
  
  //卡片餘額排序
  const balanceSortHandler = () => {
    if(cardSort === "desc") {
      setCardSort("asc")
    }else {
      setCardSort("desc")
    }
  }

  const showGrabHandler = (item) => {
    setGrabData(item)
    setShowGrab(true)
  }  

  useEffect(() => {      
    
    const web14_fetchData = async() => {
      setIsLoading(true)
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),    
        select: "",
        grab: "0"        
      };
             
      try {
        const { listResponse, status, statusText} = await web_14_find_pur_order(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                     
          setPurchaseMateData(listResponse.data)         
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }    
    web14_fetchData()   
    setSelectOrder({})
  }, [isRefresh]);


  useEffect(() => {         
    
    const web21_fetchData = async() => {
      setIsLoading(true)
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),
        select: filterNumber || ""                   
      };
         
      try {
        const { listResponse, status, statusText} = await web_21_find_pur_order_card(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {                     
          setOrderCardData(listResponse.data)  
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    } 
    // 選訂單才show卡
    if(Object.keys(selectOrder).length > 0) {
      web21_fetchData()  
    }else {
      setOrderCardData([])
      setSelectCard([])
    }
    setCardSort("")     
     
  }, [selectOrder, filterNumber]);


  //判斷卡片餘額排序按鈕
  useEffect(() => {
    if(selectOrder) {
      const newSelectCard = [...orderCardData]
      if(cardSort === "desc") {
        newSelectCard.sort((a, b) => a.當前餘額 - b.當前餘額);
      }else if(cardSort === "asc"){
        newSelectCard.sort((a, b) => b.當前餘額 - a.當前餘額);
      }
      setOrderCardData(newSelectCard)      
    }
    
  }, [cardSort])
  
  return (
    <div className='purchaseMateWrap p-4'>
      <div className='flex flex-col'>
        <FilterInput showCalendarInput={false} setFilterNumber={setFilterNumber} placeholder="请输入卡号"/>
        <div className='flex flex-row min-md:ml-auto max-md:justify-center space-x-4'>
          <Button disabled={selectCard.length === 0 || JSON.stringify(selectOrder) === '{}'} label="强制送出" severity='warning' raised onClick={showForceHandler}></Button>
          <Button disabled={selectCard.length === 0 || JSON.stringify(selectOrder) === '{}'} label="确认送出" severity='success' raised onClick={showConfirmHandler}></Button>
          <Button disabled={Object.keys(selectOrder).length === 0} label="撤销请求" severity='danger' raised onClick={showRevokeHandler}></Button>
        </div>
        
        <div className="tableWrap h-[35vh] overflow-scroll">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">               
            <tbody className='space-y-4'>
              {purchaseMateData.map((item, index) => 
                <tr key={index} className="bg-customColor-bgDark text-white flex flex-row">
                  <td className="w-4 p-4 flex items-center justify-center basis-1/12">
                    <div className="flex items-center">
                      <input name="radio" type="radio" checked={selectOrder.單號 === item.單號} onChange={(e) => selectOrderHandler(e, item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500"/>                     
                    </div>
                  </td>                    
                  <td className="px-6 py-4 flex flex-row flex-wrap items-center basis-8/12">
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                        订单编号
                      </div>
                      <p>{item.單號}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                        订单时间
                      </div>
                      <p>{item.時間}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                        订单金额
                      </div>
                      <p>{item.金額}</p>                            
                    </div>
                    <div className='flex flex-row items-center min-w-[40%]'>
                      <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                        商户编号
                      </div>
                      <p>{item.商戶號}</p>                            
                    </div>
                  </td>
                  <td className="flex items-center space-x-3 basis-2/12 whitespace-nowrap">
                    <input type="checkbox" onChange={(e) => showSpiltHandler(e, item)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full focus:ring-blue-500"/>
                    <label htmlFor="checkbox" className="text-white">可拆分</label>                    
                  </td>                 
                </tr>              
              )} 
            </tbody>
          </table>
        </div>
        
        <div className="tableWrap overflow-scroll h-[30vh]">          
          <table className="w-full text-sm text-left">
            <thead className="text-xs bg-customColor-bgLightGray text-gray-500 top-0 sticky">
              <tr>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={handleAllCheckboxChange} checked={orderCardData.length > 0 && selectCard.length === orderCardData.length}
                    />
                  </div>
                </th>
                <th className='min-w-[120px] px-[0.5rem] py-1'>
                  姓名
                </th>
                <th className='min-w-[120px] px-[0.5rem] py-1'>
                  银行名称
                </th>
                <th className='min-w-[120px] px-[0.5rem] py-1'>
                  卡号
                </th>
                <th className='min-w-[100px] px-[0.5rem] py-1' onClick={balanceSortHandler}>
                  当前余额
                  {cardSort === "desc" ? 
                    <i className="pi pi-sort-amount-down-alt text-blue-700"></i>
                  : cardSort === "asc" ? 
                    <i className="pi pi-sort-amount-up-alt text-blue-700"></i>
                  : 
                    <i className="pi pi-sort-alt"></i>
                  } 
                </th>
                <th className='min-w-[80px] px-[0.5rem] py-1'>
                  {"今日收款額／上限"}
                </th>
                <th className='min-w-[80px] px-[0.5rem] py-1'>
                  {"今日收款次數／上限"}
                </th>                
                <th className='min-w-[100px] px-[0.5rem] py-1'>
                  信用额度
                </th>
                <th className='min-w-[80px] px-[0.5rem] py-1'>
                  狀態
                </th>                
              </tr>
            </thead>
              <tbody className='bg-customColor-bgDark'>
                {orderCardData.map((item) => 
                  <tr key={item.卡號} className="">
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input name="cardCheckbox" type="checkbox" value={selectCard} checked={selectCard.find((row) => row.卡號 === item.卡號)} onChange={(e) => handleCheckboxChange(e, item)} 
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                  </td>  
                  <td>
                    {item.姓名}
                  </td>               
                  <td>
                    {item.銀行名}
                  </td>
                  <td>
                    {item.卡號}
                  </td>
                  <td>
                    {item.當前餘額}
                  </td>
                  <td>
                    {item['今日收款額/上限(%)']}
                  </td>
                  <td>
                    {item['今日收款次數/上限(%)']}
                  </td>
                  <td>
                    {item.信用額度}
                  </td>
                  <td>
                    {item.狀態 === 0 ? "停用" : item.狀態 === 1 ? "启用" : null}
                  </td>
                </tr>          
                )}
                      
              </tbody>
          </table>
        </div>
      </div>
      {/* 撤銷請求 */}
      {showRevoke && <PurchaseMateRevoke revokeData={selectOrder} setShowRevoke={setShowRevoke}/>}
      
      {/* 拆分設定 */}
      {showSplit && <PurchaseMateSplit splitDataList={splitDataList} setSplitDataList={setSplitDataList} setShowSplit={setShowSplit} currentSplitId={currentSplitId}/>}

      {/* 確認送出 */}
      {showConfirm && <PurchaseMateConfirm selectCard={selectCard} selectSplitData={selectSplitData} confirmData={confirmData} setShowConfirm={setShowConfirm} />}
      
      {/* 強制送出 */}
      {showForce && <PurchaseMateForce setShowForce={setShowForce} selectOrder={selectOrder} selectCard={selectCard}/> }

    </div>
  );
};

export default PurchaseMate;