import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { useFormik } from 'formik';
import { web_100_frozen_amount } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';

const RecordFreezeNcc = (props) => {

  const {setShowFreeze, rowData, freezeOrUnfreeze} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003) 

  const sysText201006 = useSystemText(201006)
  const sysText201009 = useSystemText(201009)
  const sysText201029 = useSystemText(201029)
  const sysText201030 = useSystemText(201030)
  const sysText201032 = useSystemText(201032)
  const sysText201033 = useSystemText(201033) 
  const sysText201034 = useSystemText(201034) 

  const formik = useFormik({
    initialValues: {
      freezeAmount: rowData.訂單金額,
      remark: "",            
        
    },

    validate: (data) => {
        let errors = {};     
        const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/     

        if (!data.freezeAmount) {								
          errors.freezeAmount = '必填';
        }else if(!numberRegExp.test(data.freezeAmount)) {
          errors.freezeAmount = '必须是数字'
        }       

        if (data.remark && data.remark.length > 50) {								
          errors.remark = '不可超過50字';
        }
      
        return errors;
    },     
    
    onSubmit: async(data) => {

      setIsLoading(true)
      let filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),   
        ordernumber: rowData.訂單編號,        
        orderprice: data.freezeAmount,
        remark: data.remark ?? "",
        ncc_or_cc: "1"
      }

      if(freezeOrUnfreeze === "冻结") {
        filterData.operatetype = "5"
      }else if(freezeOrUnfreeze === "解冻") {
        filterData.operatetype = "6"
      }
            
      try {
        const { listResponse, status, statusText} = await web_100_frozen_amount(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) {          
          setIsRefresh(prev => !prev)           
          showToast("success", "" + status, `成功${freezeOrUnfreeze}訂單: ${rowData.訂單編號}` )
          setShowFreeze(false)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }          
    }
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
      return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] bg-customColor-bgGray rounded-lg'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
            <div className='border-b border-gray-500 text-lg p-6'>{freezeOrUnfreeze === "冻结" ? sysText201029 : freezeOrUnfreeze === "解冻" ? sysText201032 : null}</div>
            <div className='p-6 space-y-2 overflow-y-scroll'>
              <p>{sysText201006}</p>
              <p className='text-yellow-500'>{rowData.訂單編號}</p>
              <p>{sysText201009}</p>
              <p className='text-yellow-500'>{rowData.訂單金額}</p>             
              
              <div>
                <label htmlFor="freezeAmount">{freezeOrUnfreeze === "冻结" ? sysText201030 : freezeOrUnfreeze === "解冻" ? sysText201034 : null}</label>                       
                <input
                    id="freezeAmount"
                    name="freezeAmount"                                       
                    className="p-2 w-full"
                    value={formik.values.freezeAmount}
                    onChange={formik.handleChange}
                    placeholder="请输入付款人姓名"
                    autoComplete="off" 
                />
                {getFormErrorMessage('freezeAmount')}  
              </div>
                       
              <div>
                <label>{sysText201033}</label>
                <textarea 
                  id="remark"
                  name="remark"                                       
                  className="p-2 w-full bg-transparent"
                  value={formik.values.remark}
                  onChange={formik.handleChange}                      
                  autoComplete="off" 
                />
              </div>             
              
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label={sysText10003} severity='secondary' raised onClick={() => setShowFreeze(false)}></Button>
              <Button type="submit" style={{width: '120px'}} label={sysText10002} severity='info' raised></Button>             
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default RecordFreezeNcc;