import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import FilterInput from '../../components/UI/filterInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { web_102_get_merchant_list, web_104_update_merchant } from '../../api/api';
import MerchantAdd from '../../components/merchant/merchantList/merchantAdd';
import MerchantEdit from '../../components/merchant/merchantList/merchantEdit';
import useSystemText from '../../helper/useSystemText';
import PaginatorUI from '../../components/UI/paginatorUI';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const MerchantnoList = () => { 
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {setIsLoading} = useContext(LoadingContext)
  const [showAddMerchant, setShowAddMerchant] = useState(false)
  const [showEditMerchant, setShowEditMerchant] = useState(false)  
  const [merchantData, setMerchantData] = useState([]) 
  const [rowData, setRowData] = useState([]) 
  const [filterName, setFilterName] = useState("")  
  const [selectStatus, setSelectStatus] = useState("")
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const [gatewayId, setGatewayId] = useState(null)
  const [ipInfo, setIpInfo] = useState(null)
  const [ipLabel, setIpLabel] = useState("")
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10);
  const [totalCount, setTotalCount] = useState(0)
  const tableScrollY = useTableSize(60, 60)

  const sysText10034 = useSystemText(10034)
  const sysText10035 = useSystemText(10035)
  const sysText10038 = useSystemText(10038)
  const sysText10041 = useSystemText(10041)
  const sysText10042 = useSystemText(10042)

  const sysText601001 = useSystemText(601001)
  const sysText601002 = useSystemText(601002)
  const sysText601003 = useSystemText(601003)
  const sysText601004 = useSystemText(601004)
  const sysText601005 = useSystemText(601005)  
  const sysText601006 = useSystemText(601006)
  const sysText601007 = useSystemText(601007)
  const sysText601008 = useSystemText(601008)
  const sysText601009 = useSystemText(601009)
  const sysText601010 = useSystemText(601010)  
  const sysText601011 = useSystemText(601011)
  const sysText601012 = useSystemText(601012)
  const sysText601013 = useSystemText(601013)
  const sysText601014 = useSystemText(601014)
  const sysText601015 = useSystemText(601015)
  const sysText601016 = useSystemText(601016)
  const sysText601021 = useSystemText(601021)
  const sysText601022 = useSystemText(601022)

  const statusOption = [
    {
      name: sysText10038,
      value: "-1"
    },
    {
      name: sysText10034,
      value: "0"
    },
    {
      name: sysText10035,
      value: "1"
    }
  ]

  const GatewayIdColumn = (rowData) => {
    return <Button label={sysText10042} severity='help' text raised onClick={() => {setGatewayId(rowData.通道id)}}></Button>
  }
  const GatewayIdDialog = ({ gatewayId, setGatewayId }) => {
    if (gatewayId === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setGatewayId(null)}
        header={sysText10042}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText10042}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>            
            <div>
              {gatewayId.join("、")}     
            </div>         
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setGatewayId(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const IpColumn = (data, label) => {
    return <Button label={sysText10041} severity='info' text raised onClick={(e) => { 
      setIpInfo(data)
      setIpLabel(label)
    }}></Button>
  }
  const IpDialog = ({ ipInfo, setIpInfo }) => {
    if (ipInfo === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setIpInfo(null)}
        header={ipLabel}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{ipLabel}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {ipInfo.map((item) => 
              <p>{item}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setIpInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };


  const ActiveColumn = (item) => {      
    
    const statusHandler = async(buttonName) => {
     
      let filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        merchant_id: item.id,        
        name: item.商戶,       
        login_ip_whitelist: item.登入白名單,
        payment_ip_whitelist: item.代付白名單,
        withdrawal_ip_whitelist: item.提款白名單,
        cashier_version: item.收銀台版本,
        col_state: item.代收,
        pur_state: item.代付
      };    

      if(buttonName === "專收") {
        filterData.col_state === "1" ? filterData.col_state = "0" : filterData.col_state = "1"
      }else if(buttonName === "專付") {
        filterData.pur_state === "1" ? filterData.pur_state = "0" : filterData.pur_state = "1"
      }

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_104_update_merchant(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                        
          showToast("success", "" + status, `成功编辑商戶: ${filterData.name}` )           
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }       

    const editHandler = () => {
      setShowEditMerchant(true)
      setRowData(item)
    }  

    return (
      <div className='flex flex-row items-center space-x-2'>
        <Button label={sysText601014} severity={item.代收 === 1 ? "success" : "danger"} onClick={() => statusHandler("專收")} raised/>
        <Button label={sysText601015} severity={item.代付 === 1 ? "success" : "danger"} onClick={() => statusHandler("專付")} raised/>   
        <Button label={sysText601016} severity="info" onClick={editHandler} raised/>       
      </div>    
    )
  }

  const tableColumns = [
    {
      header: sysText601001,
      field: "商戶",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601002,
      field: "通道id",
      style: {minWidth: "120px"},
      body: GatewayIdColumn
    },
    {
      header: sysText601003,
      field: "餘額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601004,
      field: "結算中金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601005,
      field: "凍結金額",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601006,
      field: "登入白名單",
      style: {minWidth: "120px"},
      body: (row) => IpColumn(row.登入白名單, sysText601006)
    },
    {
      header: sysText601007,
      field: "代付白名單",
      style: {minWidth: "120px"},
      body: (row) => IpColumn(row.代付白名單, sysText601007)
    },
    {
      header: sysText601008,
      field: "提款白名單",
      style: {minWidth: "120px"},
      body: (row) => IpColumn(row.提款白名單, sysText601008)
    },
    {
      header: sysText601009,
      field: "TG群ID",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601010,
      field: "TG匯率",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601011,
      field: "創建時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText601012,
      field: "",
      style: {minWidth: "200px"},
      body: ActiveColumn,
      headerClassName: 'centered-header',
      alignFrozen: "right",
      // frozen: true
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterName,
      page: page + 1,
      limit: rows      
    };

    const fetchData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_102_get_merchant_list(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {
          let newData = Object.values(listResponse.data)
          if(selectStatus == "1") {
            newData = newData.filter((row) => (row.代收 == selectStatus || row.代付 == selectStatus))
          }else if(selectStatus == "0") {
            newData = newData.filter((row) => (row.代收 == selectStatus && row.代付 == selectStatus ))
          }
          setMerchantData(newData)   
          
          setTotalCount(listResponse.count[0])
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [isRefresh, filterName, selectStatus, first, rows, page]);   

  return (  
     
    <div className='flex flex-col'>       
      
      <div className='flex flex-row flex-wrap min-md:justify-end max-md:justify-center mb-1 items-center mx-4 space-x-2'>           
        
        <div className='flex flex-col'>
          
          <FilterInput showCalendarInput={false} setFilterNumber={setFilterName} placeholder={sysText601021}>
            <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
          </FilterInput>
          <div className='flex flex-row justify-end max-md:justify-center space-x-4'>
            <Dropdown className='custom-dropdown flex items-center' value={selectStatus} onChange={(e) => setSelectStatus(e.value)} options={statusOption} optionLabel='name' optionValue='value' placeholder={sysText601022}></Dropdown>
            <Button label={sysText601013} severity='info' className='' onClick={() => {setShowAddMerchant(true)}}></Button>
          </div>
         
        </div>
         
      </div>             

      <div className="px-2 tableWrap"> 
        
        <DataTable value={merchantData} scrollable scrollHeight={tableScrollY}>
          {visibleColumns.map((row,index) =>             
            <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
          )}              
        </DataTable>

        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> 
      </div>

      {/* 通道詳情 */}
      <GatewayIdDialog gatewayId={gatewayId} setGatewayId={setGatewayId}/>
      {/* 資訊詳情 */}
      <IpDialog ipInfo={ipInfo} setIpInfo={setIpInfo}/>
      {/* 新增商戶 */}
      {showAddMerchant && <MerchantAdd setShowAddMerchant={setShowAddMerchant}/> }
      {/* 編輯商戶 */}
      {showEditMerchant && <MerchantEdit setShowEditMerchant={setShowEditMerchant} rowData={rowData}/> }
    </div>
   
  );
}

export default MerchantnoList;