import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { web_13_matched_pur_sub_order, web_27_ncc_create_pur_sub_order } from '../../../api/api';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import useSystemText from '../../../helper/useSystemText';

const PurchaseMateNccConfirm = (props) => {

  const {selectCard, selectSplitData, setShowConfirm, confirmData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()  

  const [showMate, setShowMate] = useState(false)
  const [mateCardData, setMateCardData] = useState({})
  const [mateResult, setMateResult] = useState([])
  const [orderBalance, setOrderBalance] = useState(0)
  const [payAmountInput, setPayAmountInput] = useState()
  const [balance, setBalance] = useState()
  const [verificationCode, setVerificationCode] = useState("")

  const sysText305001 = useSystemText(305001)
  const sysText305003 = useSystemText(305003)
  const sysText305010 = useSystemText(305010)
  const sysText305012 = useSystemText(305012)
  const sysText305013 = useSystemText(305013)
  const sysText305018 = useSystemText(305018)
  const sysText305021 = useSystemText(305021)
  const sysText305022 = useSystemText(305022)
  const sysText305023 = useSystemText(305023)
  const sysText305019 = useSystemText(305019)
  const sysText305020 = useSystemText(305020)
  const sysText305024 = useSystemText(305024)
  const sysText305025 = useSystemText(305025)
  const sysText305026 = useSystemText(305026)
  const sysText305027 = useSystemText(305027)
  const sysText305030 = useSystemText(305030)

  console.log("confirmData: ", confirmData);
  console.log("selectSplitData: ", selectSplitData)
  console.log("mateResult: ", mateResult);
  console.log("mateCardData: ", mateCardData);
  console.log("payAmountInput: ", payAmountInput);
  console.log("balance: ", balance);
  
  const confirmHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      confirmpeople: Cookies.get("cookie_uuid") || "",
      goal: confirmData.金額,
      max_split_num: selectSplitData?.maxSplitNum ?? 1,
      min_split_amount: selectSplitData?.minSplitAmount ?? confirmData.金額,
      card: {}
    }

    selectCard.map((item,index) => {
      filterData.card[`${item.id?.toString()}`] = parseInt(item.當前餘額)
    })    
    
    try {
      const {listResponse, status, statusText} = await web_13_matched_pur_sub_order(filterData)
      console.log("listResponse: ", listResponse);

      if (listResponse.status === 204) {
        showToast("success", "" + status, `无配对方案` )      
      } else if (status === 200) {          
        //建立子單用卡片資料 cardData
        setMateCardData(listResponse.data)
        // 過濾並更新 selectCard 沒有配對到的資料
        let filterSelectCard = selectCard.filter(card => listResponse.data.hasOwnProperty(card.id));
        //將當前餘額欄位儲存到state值
        let balanceList = {}
        // 將配對到的卡的當前餘額減去分配到的金額
        const updateSelectCard = filterSelectCard.map((card) => {   
          balanceList = {...balanceList, [card.id]: card.當前餘額 - listResponse.data[card.id]}   
          return {...card, 當前餘額:card.當前餘額, 應付金額: listResponse.data[card.id]}
        })
        setBalance(balanceList)
        setMateResult(updateSelectCard)
        setShowMate(true)

        //尚未分配餘額        
        let totalMateAmount = 0
        Object.values(listResponse.data).map(amount => totalMateAmount += amount)
        setOrderBalance(confirmData.金額 - totalMateAmount)
        console.log("orderBalance: ", orderBalance);
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }       
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  } 

  //當手動更改應付金額時 判斷計算
  const payAmountChangeHandler = (e, item) => {   
    let newPayAmountData = {...payAmountInput}
    newPayAmountData[item.id] = e.target.value
    console.log("newPayAmountData: ", newPayAmountData);

    let newBalanceData = {...balance}
    if(e.target.value) {
      newBalanceData[item.id] = item.當前餘額 - parseInt(e.target.value)
    }else {
      newBalanceData[item.id] = "无法计算"
    }   
    console.log("newBalanceData: ", newPayAmountData);

    setPayAmountInput(newPayAmountData)
    setBalance(newBalanceData)
  }

  const creatSubOrderDisable = () => {
    const balanceLowZeroList = Object.values(balance).filter(value => value < 0)
    console.log("balanceLowZeroList: ", balanceLowZeroList);
    if (balanceLowZeroList.length > 0 || orderBalance !== 0) {
      return true
    }else {
      return false
    }
  }

  const creatSubOrderHandler = async() => {    
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      crepso_pur_confirmpeople: Cookies.get("cookie_uuid") || "",
      crepso_pur_merchantno: confirmData.商戶號,
      crepso_pur_rawordernumber: confirmData.單號,
      crepso_pur_productamount: confirmData.金額,      
      crepso_pur_payee_info: confirmData.收款人姓名,
      crepso_carddata: payAmountInput,
      verification_code: verificationCode
    }   

    try {
      const {listResponse, status, statusText} = await web_27_ncc_create_pur_sub_order(filterData)
      console.log("listResponse: ", listResponse);

      if (status === 200) {          
        setIsRefresh(prev => !prev)
        showToast("success", "" + status, `成功建立子单` )
        setShowConfirm(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }       
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }    
  }

  useEffect(() => {
    setPayAmountInput(mateCardData)
  }, [mateCardData])

  useEffect(() => {
    let totalAmount = confirmData.金額
    if(payAmountInput) Object.values(payAmountInput)?.map(value => {totalAmount = totalAmount - value})
    setOrderBalance(totalAmount)
  }, [payAmountInput, balance])

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText305021}</div>
            <div className='p-6 space-y-4 flex-1'>
              <p>{sysText305001}: {confirmData?.單號}</p>              
              <p>{sysText305003}: {confirmData?.金額}</p>             
              <p>{sysText305022}: {selectSplitData?.minSplitAmount ? selectSplitData.minSplitAmount : <span className='text-yellow-500'>未設定</span>}</p>
              <p>{sysText305023}: {selectSplitData?.maxSplitNum ? selectSplitData?.maxSplitNum : <span className='text-yellow-500'>未設定</span>}</p>
              
            </div>
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button label={sysText305020} severity='secondary' raised onClick={() => setShowConfirm(false)}></Button>
              <Button label={sysText305019} severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
        </div>
      </Dialog>

      {showMate && 
        <Dialog>
          <div className='mx-auto w-[800px] max-md:w-full bg-customColor-bgGray rounded-lg'>
            <div className='flex flex-col'>
              <div className='border-b border-gray-500 text-lg p-6 flex-1'>{sysText305024} <span className='text-yellow-500'>{confirmData?.單號}</span></div>
              {orderBalance >= 0 ?
                <div className='m-4 p-2 flex justify-center bg-customColor-bgLightGray border'>{sysText305025}：{orderBalance}</div>
              : 
                <div className='m-4 p-2 flex justify-center bg-[#330000] border border-red-800'>{sysText305025}：{orderBalance}</div>
              }
              <div className='max-h-[50vh] overflow-y-scroll'>
                <table className="w-full text-sm text-gray-500 dark:text-gray-400 ">               
                  <tbody className=''>
                    {mateResult.map((item, index) => 
                      <tr key={index} className="bg-customColor-bgDark text-white flex flex-row m-4 hover:bg-customColor-bgLightGray">                                     
                        <td className="px-6 py-4 flex flex-row flex-wrap items-center w-full">
                          <div className='flex flex-row items-center w-[50%] max-md:w-[100%]'>
                            <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                              {sysText305010}
                            </div>
                            <p>{item.姓名}</p>                            
                          </div>
                          <div className='flex flex-row items-center w-[50%] max-md:w-[100%]'>
                            <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                              {sysText305018}
                            </div>
                            <input className='p-1' name="payAmount" value={payAmountInput[item.id]} onChange={(e) => payAmountChangeHandler(e, item)} type='number'/>                            
                          </div>
                          <div className='flex flex-row items-center w-[50%] max-md:w-[100%]'>
                            <div className='bg-blue-900 border border-blue-800 px-2 py-1 m-1 rounded-lg'>
                              {sysText305012}
                            </div>
                            <p>{item.卡号}</p>                            
                          </div>
                          <div className='flex flex-row items-center w-[50%] max-md:w-[100%]'>
                            <div className='bg-indigo-900 border border-indigo-800 px-2 py-1 m-1 rounded-lg'>
                              {sysText305013}
                            </div>
                            <p>{balance[item.id]}</p>                            
                          </div>
                        </td>                     
                      </tr>   
                    )} 
                  </tbody>
                </table>

                <div className='space-x-2 p-4'>
                  <label>{sysText305030}</label>
                  <input className='px-2 w-24' maxLength="8" onChange={(e) => setVerificationCode(e.target.value)}></input>
                </div>
              </div>
             
              <div className='border-t border-gray-500 flex justify-around items-center py-2'>
                <Button style={{width: '120px'}} label={sysText305027} severity='secondary' raised onClick={() => setShowConfirm(false)}></Button>
                <Button style={{width: '120px'}} label={sysText305026} severity='info' disabled={creatSubOrderDisable()} raised onClick={creatSubOrderHandler}></Button>                
              </div>
            </div>
          </div>
        </Dialog>
      }
      
    </>
  );
};

export default PurchaseMateNccConfirm;