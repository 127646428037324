import React from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';

const WithdrawRecordRemark = (props) => {

  const {setShowRemark, remarkData} = props

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>备注</div>
            <div className='p-4 text-sm'>
              <p>{remarkData.備註 ? remarkData.備註 : "无备注"} </p>
            </div>            
            
            <div className='flex justify-center items-center border-t border-gray-700 py-2 px-4'>
              <Button style={{width: "80px"}} label='OK' severity='info' raised onClick={() => setShowRemark(false)}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default WithdrawRecordRemark;