import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_16_information_board } from '../../api/api';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';


const InformationBorad = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [informationBoradData , setInformationBoradData] = useState([]) 
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterDate, setFilterDate] = useState([])

  //預設時間為6個月前
  const sixMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 6))

  const defaultDate = [
    new Date(sixMonthAgo.setHours(0, 0, 0, 0)), // Today's start date (00:00:00)
    new Date(new Date().setHours(23, 59, 59, 999)) // Today's end date (23:59:59)
  ]

  console.log("defaultDate: ", defaultDate);

  useEffect(() => {         

    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      start_date: filterDate[0] ? filterDate[0] : sixMonthAgo.getTime(),
      end_date: filterDate[1] ? filterDate[1] : new Date().getTime(),
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_16_information_board(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setInformationBoradData(listResponse)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterDate, isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showSearchInput={false} setFilterDate={setFilterDate} defaultDate={defaultDate} placeholder="请选择日期"/>
        <div className='max-lg:hidden'>
          <DataTable value={informationBoradData} scrollable scrollHeight='550px'>
            <Column style={{ minWidth: "120px" }} field="" header="商户代码"></Column>
            <Column style={{ minWidth: "120px" }} field="商戶號" header="商户名"></Column>
            <Column style={{ minWidth: "120px" }} field="餘額" header="余额"></Column>
            <Column style={{ minWidth: "120px" }} field="起始金額" header="起始金额"></Column>
            <Column style={{ minWidth: "120px" }} field="訂單成功單數" header="订单成功单数"></Column>  
            <Column style={{ minWidth: "120px" }} field="訂單成功手續費" header="订单手续费"></Column>
            <Column style={{ minWidth: "120px" }} field="採購成功單數" header="采购成功单数"></Column>
            <Column style={{ minWidth: "120px" }} field="採購駁回金額" header="采购驳回金额"></Column>
            <Column style={{ minWidth: "120px" }} field="採購成功金額" header="采购成功金额"></Column>
            <Column style={{ minWidth: "120px" }} field="採購手續費" header="采购手续费"></Column>  
            <Column style={{ minWidth: "120px" }} field="提款成功單數" header="提款成功单数"></Column>
            <Column style={{ minWidth: "120px" }} field="提款申請金額" header="提款申请金额"></Column>
            <Column style={{ minWidth: "120px" }} field="提款駁回金額" header="提款驳回金额"></Column>
            <Column style={{ minWidth: "120px" }} field="提款成功金額" header="提款成功金额"></Column>
            <Column style={{ minWidth: "120px" }} field="增項資金" header="增项资金"></Column>  
            <Column style={{ minWidth: "120px" }} field="減項資金" header="减项资金"></Column>
            <Column style={{ minWidth: "120px" }} field="總手續費" header="总手续费"></Column>
            <Column style={{ minWidth: "120px" }} field="解凍金額" header="解冻金额"></Column>
            <Column style={{ minWidth: "120px" }} field="凍結金額" header="冻结金额"></Column>
            <Column style={{ minWidth: "120px" }} field="代收系統費用" header="代收系统费用"></Column>
            <Column style={{ minWidth: "120px" }} field="代付系統費用" header="代付系统费用"></Column>
            <Column style={{ minWidth: "120px" }} field="利潤" header="利润"></Column>               
          </DataTable>          
        </div>
        
      </div>
  );
}

export default InformationBorad