import React, { useContext, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_17_ncc_gateway_list, web_17_ncc_gateway_type, web_33_ncc_add_pur_order, web_84_add_flowrecord_get_merchantno } from "../../api/api";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import HelpIcon from "../../components/UI/helpIcon";
import useHelpText from "../../helper/useHelpText";
import useSystemText from "../../helper/useSystemText";

const PurchaseAddNcc = () =>  {    

    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()
    const [merchantno, setMerchantno] = useState("")
    const [gatewayTypeOption, setGatewayTypeOption] = useState([])
    const [gatewayOption, setGatewayOption] = useState([])
    const helpText7 = useHelpText(7)

    const sysText303001 = useSystemText(303001)
    const sysText303002 = useSystemText(303002)
    const sysText303003 = useSystemText(303003)
    const sysText303004 = useSystemText(303004)
    const sysText303005 = useSystemText(303005)
    const sysText303006 = useSystemText(303006)
    const sysText303007 = useSystemText(303007)
    const sysText303008 = useSystemText(303008)     
    const sysText303009 = useSystemText(303009)   
    const sysText303010 = useSystemText(303010)     
    const sysText303011 = useSystemText(303011)     
   
    function generateCode() {     
      let mallname = ""
      if(Cookies.get("mallname")) {
        mallname = Cookies.get("mallname").slice(0, 3)
      }
  
      // 獲取當前時間 YYYYMMDDHHmmssms
      let date = new Date();

      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      let milliseconds = ("00" + date.getMilliseconds()).slice(-3);
  
      const timeStr = year + month + day + hours + minutes + seconds + milliseconds;

      //手派採購單縮寫
      const mcp = "mcp"

      // 產生0-999999的隨機數
      const randomNum = Math.floor(Math.random() * 1000);

      // 將隨機數轉為字串，並在不足6位的情況下在前面補0
      const randomNumStr = String(randomNum).padStart(3, '0');

      // 將隨機數和時間戳組合成最終的代碼
      const code = mallname + mcp + timeStr + randomNumStr
  
      return code;
    }
    
    const formik = useFormik({
      initialValues: {
        customerno: '',
        amount: "",
        merchantno: '',
        payername: "",
        bankname: "",
        cardNumber: "",
        gatewayType: "",
        gatewayId: [],
        grab: "0",
        verificationCode: ""        
      },
      validate: (data) => {
          let errors = {};
          const numberRegExp = /^\d{1,20}(\.\d{1,2})?$/  
          const amountRegExp = /^([1-9]\d*)(\.\d{1,2})?$/


          if (!data.customerno) {								
            errors.customerno = '必填';
          }          

          if(!amountRegExp.test(data.amount)) {
            errors.amount = '必须为数字，且不能为0';
          }

          if (!data.merchantno) {								
            errors.merchantno = '必填';
          }      
          
          if (!data.payername) {								
            errors.payername = '必填';
          }
          
          if (!data.bankname) {								
            errors.bankname = '必填';
          }     

          if (!data.cardNumber) {								
            errors.cardNumber = '必填';
          }else if(!numberRegExp.test(data.cardNumber)) {		
            errors.cardNumber = '必須是0~9';
          }         
          
          if (!data.gatewayType) {								
            errors.gatewayType = '必填';
          }   

          if (data.gatewayId.length <= 0) {								
            errors.gatewayId = '必填';
          }   
         
          return errors;
      },     
      
      onSubmit: (data) => {

        let info = {"姓名": data.payername, "银行名称": data.bankname, "卡号": data.cardNumber}
        info = JSON.stringify(info)
        
        const filterData = {    
          cookie_uuid: Cookies.get("cookie_uuid"),
          cookie_hash: Cookies.get("cookie_hash"), 
          customerno: data.customerno,
          amount: data.amount,
          merchantno: data.merchantno,
          payer_info: info,
          gateway_id: data.gatewayId,
          grab: data.grab,           
          confirmpeople: Cookies.get("cookie_uuid"),
          source: Cookies.get("cookie_uuid"),
          verification_code: data.verificationCode
        };    
    
        const addPurOrder = async () => { 
          setIsLoading(true)       
          try {
          
            const { listResponse, status, statusText } = await web_33_ncc_add_pur_order(filterData);       
            
            console.log("listResponse: ", listResponse)
    
            if (status === 200) {                  
              setIsRefresh(prev => !prev)
              showToast("success", "" + status, `成功新增一笔采购单 编号为:${data.customerno}` )
              formik.resetForm();
            } else {
              showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
            }
          } catch (error) {        
            console.error(location.pathname, error);            
          } finally {
            setIsLoading(false)
          }
        }
        addPurOrder()            
      }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };  
    
    useEffect(() => {
      const web84_fetchData = async() => {
        setIsLoading(true)
        const filterData = {      
          cookie_uuid: Cookies.get('cookie_uuid'),
          cookie_hash: Cookies.get('cookie_hash')                       
        };
           
        try {
          const { listResponse, status, statusText} = await web_84_add_flowrecord_get_merchantno(filterData);          
          console.log("listResponse: ", listResponse)          
  
          if (status === 200) {                     
            formik.setFieldValue("merchantno", listResponse?.merchantno)
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      } 

      const gatewayTypefetchData = async() => {
        setIsLoading(true)
        const filterData = {      
          cookie_uuid: Cookies.get('cookie_uuid'),
          cookie_hash: Cookies.get('cookie_hash'),
          cardorncc: "1"                       
        };
           
        try {
          const { listResponse, status, statusText} = await web_17_ncc_gateway_type(filterData);          
          console.log("listResponse: ", listResponse)          
  
          if (status === 200) {            
            setGatewayTypeOption(listResponse)
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }       

      web84_fetchData()
      gatewayTypefetchData()

      formik.setFieldValue("customerno", generateCode())
    }, [location, isRefresh])

    useEffect(() => {
      const gatewayfetchData = async() => {
        setIsLoading(true)
        const filterData = {      
          cookie_uuid: Cookies.get('cookie_uuid'),
          cookie_hash: Cookies.get('cookie_hash'),
          gateway_type: formik.values.gatewayType                       
        };
           
        try {
          const { listResponse, status, statusText} = await web_17_ncc_gateway_list(filterData);          
          console.log("listResponse: ", listResponse)          
  
          if (status === 200) {            
            setGatewayOption(listResponse)
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      if(formik.values.gatewayType) {
        gatewayfetchData()
      }

      formik.setFieldValue("gatewayId", [])

    },[formik.values.gatewayType])

    console.log("gatewayType: ", formik.values.gatewayType);    
    console.log("gatewayId: ", formik.values.gatewayId);

    return (
        <div className="systeminformationWrap card flex justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="flex flex-row absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">{sysText303001} <span className="mx-2"><HelpIcon tooltipText={helpText7}/></span> </div>
            
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row flex-wrap justify-between">                
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="customerno" className="text-blue-300">{sysText303002}</label>
                  </div>
                               
                  <input
                      id="customerno"
                      name="customerno"                  
                      className="p-2 w-64 cursor-not-allowed opacity-50"
                      value={formik.values.customerno}                      
                      placeholder={sysText303002}
                      autoComplete="off"   
                      disabled={true}                   
                  />
                  {getFormErrorMessage('customerno')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="amount" className="text-blue-300">{sysText303003}</label>
                  </div>             
                  <input
                      id="amount"
                      name="amount"                  
                      className="p-2 w-64"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder={sysText303003} 
                  />
                  {getFormErrorMessage('amount')}
                </div>
                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="merchantno" className="text-blue-300">{sysText303004}</label>
                  </div>             
                  <input
                      id="merchantno"
                      name="merchantno"                  
                      className="p-2 w-64 opacity-50 cursor-not-allowed"
                      value={formik.values.merchantno}
                      onChange={formik.handleChange}
                      placeholder={sysText303004}
                      autoComplete="off"
                      disabled={true}
                  />
                  {getFormErrorMessage('merchantno')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="payername" className="text-blue-300">{sysText303005}</label>
                  </div>             
                  <input
                      id="payername"
                      name="payername"                  
                      className="p-2 w-64"
                      value={formik.values.payername}
                      onChange={formik.handleChange}
                      placeholder={sysText303005}
                      autoComplete="off"                      
                  />
                  {getFormErrorMessage('payername')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="bankname" className="text-blue-300">{sysText303006}</label>
                  </div>             
                  <input
                      id="bankname"
                      name="bankname"                  
                      className="p-2 w-64"
                      value={formik.values.bankname}
                      onChange={formik.handleChange}
                      placeholder={sysText303006}
                      autoComplete="off"                      
                  />
                  {getFormErrorMessage('bankname')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="cardNumber" className="text-blue-300">{sysText303007}</label>
                  </div>             
                  <input
                      id="cardNumber"
                      name="cardNumber"                  
                      className="p-2 w-64"
                      value={formik.values.cardNumber}
                      onChange={formik.handleChange}
                      placeholder={sysText303007}
                      autoComplete="off"                      
                  />
                  {getFormErrorMessage('cardNumber')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="gatewayType" className="text-blue-300">{sysText303008}</label>
                  </div>             
                  <Dropdown name="gatewayType" className="custom-dropdown w-64" value={formik.values.gatewayType} onChange={formik.handleChange} options={gatewayTypeOption} optionLabel="名稱" optionValue="id"/>
                  {getFormErrorMessage('gatewayType')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="gatewayId" className="text-blue-300">{sysText303009}</label>
                  </div>             
                  <Dropdown name="gatewayId" className="custom-dropdown w-64" filter value={formik.values.gatewayId} onChange={formik.handleChange} options={gatewayOption} optionLabel="名稱" optionValue="id"/>
                  {getFormErrorMessage('gatewayId')}
                </div>

                <div className="flex flex-col w-[33%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="verificationCode" className="text-blue-300">{sysText303011}</label>
                  </div>             
                  <input
                      id="verificationCode"
                      name="verificationCode"                  
                      className="p-2 w-64"
                      value={formik.values.verificationCode}
                      onChange={formik.handleChange}                      
                      autoComplete="off"
                      placeholder={sysText303011}              
                  />
                  {getFormErrorMessage('verificationCode')}
                </div>
               
              
              </div>
              
              <Button label={sysText303010} type="submit" disabled={isLoading} severity="info" raised className="w-36" />
              
            </form>
          </div>
          
        </div>
    )
}

export default PurchaseAddNcc;