import React, { useState, useEffect, useContext } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import ShareRecordCard from '../../components/share/shareRecord/shareRecordCard';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Dialog from '../../components/dialog';
import ShareRecordWithdraw from '../../components/share/shareRecord/shareRecordWithdraw';
import { c11_order_history, web_42_order_history } from '../../api/api';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../context/ToastContext';
import Cookies from 'js-cookie';
import LoadingContext from '../../context/loadingContext';
import FilterInput from '../../components/UI/filterInput';
import WithdrawRecord from './withdrawRecord';
import PaginatorUI from '../../components/UI/paginatorUI';
import ShareRecordList from '../../components/share/shareRecord/shareRecordList';
import useSystemText from '../../helper/useSystemText';

const ShareRecord = () => {

  const sysText501001 = useSystemText(501001)
  const sysText501002 = useSystemText(501002)
  const sysText501003 = useSystemText(501003)
  const sysText501004 = useSystemText(501004)
  const sysText501005 = useSystemText(501005)
  const sysText501006 = useSystemText(501006)
  const sysText501007 = useSystemText(501007)
  const sysText501008 = useSystemText(501008)  
  const sysText501009 = useSystemText(501009)
  const sysText501010 = useSystemText(501010)
  const sysText501011 = useSystemText(501011)

  const dateToString = (start_date, end_date) => {      
     
    const startDateString = new Date(start_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const endDateString = new Date(end_date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, "-");

    const dateString = `${startDateString} ~ ${endDateString}`

    return dateString
  } 

  const defaultDate = [
    new Date(new Date().setHours(0, 0, 0, 0)), // Today's start date (00:00:00)
    new Date(new Date().setHours(23, 59, 59, 999)) // Today's end date (23:59:59)
  ]

  const [idFirst] = useState(parseInt(Cookies.get("cookie_uuid")[0]))
  
  const [shareRecordData, setShareRecordData] = useState([])
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const [filterDate, setFilterDate] = useState([])
  // const [displayDate, setDisplayDate] = useState(dateToString(-2209017600000, new Date().getTime()))
  const [showWithdraw, setShowWithdraw] = useState(false)
  const [withdrawData, setWithdrawData] = useState(0)
  const [tabMenu, setTabMenu] = useState('') 
  const [itemList, setItemList] = useState([])
  const [uuid] = useState(Cookies.get('cookie_uuid'));
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const [totalCount, setTotalCount] = useState(0) 
  const [showRecordList, setShowRecordList] = useState(false)
  const [recordListData, setRecordListData] = useState({})   

  const [allIncome, setAllIncome] = useState(0)
  const [allSalesshare, setAllSalesshare] = useState(0)

  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()  

  // const items1 = [
  //   {label: '订单流水记录', icon: '', command: () => {setTabMenu('order')}},
  //   {label: '提领记录', icon: '',  command: () => {setTabMenu('withdraw')}}    
  // ];

  // const items38 = [
  //   {label: '提领记录', icon: '',  command: () => {setTabMenu('withdraw')}}
  // ]

  const ActiveColumn = (item) => {     
    const showRecordListHandler = () => {
      setRecordListData(item)
      setShowRecordList(true)
    }          

    return (      
      <div className='activeBtn flex flex-col items-center justify-around'>          
        <Button label={sysText501010} severity='info' raised onClick={showRecordListHandler}/>      
      </div>
    )
  }    
  
  const showWithdrawHandler = () => {    
    setShowWithdraw(true)
  }

  useEffect(() => {      
    if(idFirst === 1 || idFirst === 2) {
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        start_date: filterDate[0] ? filterDate[0] : new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
        end_date: filterDate[1] ? filterDate[1] : new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
        page: page + 1,
        limit: rows
      };    

      const fetchData = async () => {    
        setIsLoading(true)   
        try {
          
          const { listResponse, status, statusText } = await web_42_order_history(filterData);       
          
          console.log("listResponse: ", listResponse)

          if (status === 200) {                     
            setShareRecordData(listResponse.data)
            setTotalCount(listResponse.totalPage)
            setAllIncome(listResponse.ALL_income) 
            setWithdrawData(listResponse.totalwithDrawal)             
            setAllSalesshare(Math.round(listResponse.ALL_salesshare * 100) / 100)
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {        
          console.error(location.pathname, error);            
        } finally {
          setIsLoading(false)
        }
      }
      fetchData()  
      // if(filterDate[0] && filterDate[1]) {
      //   setDisplayDate(dateToString(filterDate[0], filterDate[1]))
      // } else {
      //   setDisplayDate(dateToString(-2209017600000, new Date().getTime()))
      // }
    }
  }, [filterDate, idFirst, first, rows, page]);

  useEffect(() => {    
    if(idFirst === 1 || idFirst === 2) {
      setItemList([
        {label: sysText501001, value: 'order', icon: '', command: () => {setTabMenu('order')}},
        {label: sysText501002, value: 'withdraw', icon: '',  command: () => {setTabMenu('withdraw')}}    
      ])
    }else if((idFirst === 3 || idFirst === 8 || idFirst === 9)) {
      setItemList([{label: sysText501002, value: 'withdraw', icon: '',  command: () => {setTabMenu('withdraw')}}])     
    }
  }, [location, idFirst, shareRecordData, isRefresh]);

  useEffect(() => {    
    setTabMenu(itemList[0]?.value)
  }, [itemList]);

  return (
    <div className="shareRecord px-4">
      <TabMenu model={itemList}/>      
      
      {(tabMenu === 'order') &&
        <div className=''>
          <div className='flex w-full'>
            <div className='flex flex-col w-full px-4 max-md:items-center'>              
              <div className='flex flex-col min-md:ml-auto'>
                <Button className='w-24' label={sysText501011} severity="info" size="small" raised onClick={showWithdrawHandler}/>               
              </div>
              <div className='flex flex-row items-center max-2xl:my-2'>
                
                  <div className='px-1 py-1 bg-blue-500 rounded-md mr-2 max-md:text-center'>{sysText501003}：{allIncome}</div>
                  <div className='px-1 py-1 bg-yellow-400 rounded-md mr-2 max-md:text-center'>{sysText501004}：{allSalesshare}</div>
                           
              </div>
              <div className='flex min-2xl:ml-auto max-2xl:w-full'>
                <FilterInput setFilterDate={setFilterDate} setFirst={setFirst} setPage={setPage} showSearchInput={false} defaultDate={defaultDate}/>
              </div>                           
            </div>       
          </div>

          <div className="px-2 recordWrap tableWrap"> 
            <div className='max-lg:hidden'>
              <DataTable value={shareRecordData} scrollable scrollHeight='500px'>
                <Column style={{minWidth: "120px"}} field="uuid" header={sysText501005} ></Column>
                <Column style={{minWidth: "120px"}} field="用戶名稱" header={sysText501006}></Column>
                <Column style={{minWidth: "120px"}} field="用戶營業額" header={sysText501007}></Column>
                <Column style={{minWidth: "120px"}} field="營業額提成" header={sysText501008}></Column>        
                <Column style={{minWidth: "120px"}} field="active" header={sysText501009} body={ActiveColumn} headerClassName="centered-header"></Column>
              </DataTable>
            </div>
            <div className='min-lg:hidden'>
              <DataTable value={shareRecordData} scrollable scrollHeight='300px'>
                <Column style={{minWidth: "120px"}} field="uuid" header={sysText501005} ></Column>
                <Column style={{minWidth: "120px"}} field="用戶名稱" header={sysText501006}></Column>
                <Column style={{minWidth: "120px"}} field="用戶營業額" header={sysText501007}></Column>
                <Column style={{minWidth: "120px"}} field="營業額提成" header={sysText501008}></Column>        
                <Column style={{minWidth: "120px"}} field="active" header={sysText501009} body={ActiveColumn} headerClassName="centered-header"></Column>
              </DataTable>
            </div>        
           
            <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>            
          </div>        
        </div>
      }

      {(tabMenu === 'withdraw') &&
       <WithdrawRecord></WithdrawRecord>
      }

      {/* 申請提領 */}
      {showWithdraw && <ShareRecordWithdraw setShowWithdraw={setShowWithdraw} withdrawData={withdrawData}/>}
      {/* 查看記錄列表 */}
      {showRecordList && <ShareRecordList setShowRecordList={setShowRecordList} recordListData={recordListData} filterDate={filterDate}/>}
      
    </div>
  );
};

export default ShareRecord;