import React, { useContext, useEffect, useState } from 'react';
import { web_64_share_list } from '../../../api/api';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatDate } from '../../../helper/formatDate';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import Dialog from '../../dialog';
import useSystemText from '../../../helper/useSystemText';

const ShareRecordList = (props) => {
  const {setShowRecordList, recordListData, filterDate} = props
  const [recordData, setRecordData] = useState([])
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation() 

  const sysText501012 = useSystemText(501012)
  const sysText501023 = useSystemText(501023)
  const sysText501024 = useSystemText(501024)
  const sysText501025 = useSystemText(501025)
  const sysText501026 = useSystemText(501026)

  useEffect(() => {      
    
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      user_uuid: recordListData.uuid,
      start_time: filterDate?.[0] !== undefined ? filterDate?.[0] : new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      end_time: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date(new Date().setHours(23, 59, 59, 999)).getTime()
    };    

    const fetchData = async () => {       
      setIsLoading(true)
      try {       
        const { listResponse, status, statusText } = await web_64_share_list(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setRecordData(listResponse)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()  
       
  }, [filterDate]);

  return (
    <Dialog>
        <div className='mx-auto w-[1000px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>{sysText501023}</div>
            <div className="p-4 recordWrap tableWrap">          
              
              <DataTable value={recordData} scrollable scrollHeight='400px'>
                <Column style={{minWidth: "120px"}} field="時間" header={sysText501012}></Column>
                <Column style={{minWidth: "120px"}} field="單號" header={sysText501024}></Column>
                <Column style={{minWidth: "80px"}} field="類型" header={sysText501025}></Column>
                <Column style={{minWidth: "120px"}} field="金額" header={sysText501026}></Column>                               
              </DataTable>    
                          
            </div>              
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label='OK' severity='info' raised onClick={() => setShowRecordList(false)}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog> 
  );
};

export default ShareRecordList;