import React, { useEffect, useContext, useState } from 'react';
import Dialog from '../../dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import Cookies from 'js-cookie';
import { get_income_data } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';

const RecordWechatU3 = (props) => {

  const {setShowWechat, wechatData} = props
  const location = useLocation()
  const {showToast} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [otherDetailData, setOtherDetailData] = useState([]) 

  const StatusColumn = (item) => {

    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''
      switch (item.data_clear_state) {
        case 0:
          label = "未清"
          severity = "info"
          break
        case 1:
          label = "已清"
          severity = "success"
          break
        case 2:
          label = "非收"
          severity = "warning"
          break
        case 9:
          label = "異常"
          severity = "danger"
          break       
        default:
          label = '';
          severity = '';
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()

    return (
      <div>
        <Button label={label} severity={severity} text raised></Button>
      </div>
    )
  }

  useEffect(() => {          

    const fetchData = async() => {
      setIsLoading(true) 
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        qr_uuid: wechatData.uuid.toString()     
      };

            
      try {
        const { listResponse, status, statusText } = await get_income_data(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) { 
          setOtherDetailData(listResponse)
        } else {
          // showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData()      
  }, [location, wechatData]);

  return (
    <>      
      <Dialog>
        <div className='mx-auto w-[90%] min-h-[250px] bg-customColor-bgGray rounded-lg'>
            <div className='flex flex-col'>
              <div className='border-b border-gray-500 text-lg p-6 flex-1'>查看微信</div>     

              <div className='w-full tableWrap mt-2 p-4'>
                <DataTable value={otherDetailData} scrollable scrollHeight="500px">
                  <Column style={{minWidth: "120px"}} field="datatime" header="时间"></Column>
                  <Column style={{minWidth: "120px"}} field="data_clear_state" body={StatusColumn} header="状态"></Column>
                  <Column style={{minWidth: "300px"}} field="data" header="讯息内容"></Column>
                  <Column style={{minWidth: "120px"}} field="data_cleared_staffbank" header="员工银行"></Column>     
                  <Column style={{minWidth: "120px"}} field="data_cleared_staffcardno" header="员工卡号"></Column>
                  <Column style={{minWidth: "120px"}} field="data_cleared_balance" header="余额"></Column>
                  <Column style={{minWidth: "120px"}} field="data_cleared_amount" header="交易金额"></Column> 
                  <Column style={{minWidth: "120px"}} field="data_cleared_customer_cardno" header="客户卡号"></Column>
                  <Column style={{minWidth: "120px"}} field="data_cleared_customer_name" header="客户姓名"></Column>
                </DataTable>      
              </div>

              <div className='border-t border-gray-500 flex justify-around items-center py-2'>
                <Button style={{minWidth: '200px'}} label='关闭' severity='secondary' raised onClick={() => setShowWechat(false)}></Button>                
              </div>
            </div>
          </div>
           
      </Dialog>       
      
    </>
    
  );
};

export default RecordWechatU3;