import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { g2_exception_pur_sub_order, web_24_exception_pur_sub_order } from '../../../api/api';
import { useLocation } from 'react-router-dom';

const PurchaseRecordException = (props) => {
  const {setShowException, exceptionData} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const exceptionHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: exceptionData?.採購單編號
    }
   
    try {
      const {listResponse, status, statusText} = await web_24_exception_pur_sub_order(filterData)

      if (status === 200) {         
        setIsRefresh(prev => !prev)
        showToast("success", "" + status, `成功更新异常 訂單編號:${exceptionData.採購單編號}` )          
        setShowException(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>异常通知</div>
            <div className='p-4 text-sm space-y-4'>
              <p>采购单编号:</p>
              <p className='text-yellow-400'>{exceptionData.採購單編號}</p>
              <p className='text-gray-500'>确认是否通知异常?</p>
            </div>            
            
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label='取消' severity='secondary' raised onClick={() => setShowException(false)}></Button>
              <Button style={{width: '120px'}} label='确认' severity='info' raised onClick={exceptionHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseRecordException;