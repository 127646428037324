import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { web_60_ncc_review_ncc, web_60_review_staffcardnumber } from '../../../api/api';
import { imgOnError } from '../../../helper/imgOnError';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const UpdateStateNcc = (props) => {

  const {setShowUpdateState, updateStateData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [infoToObject, setInfoToObject] = useState({}) 
  const [isImgLoading, setIsImgLoading] = useState(true)
  const [showLog, setShowLog] = useState(false)
  const location = useLocation() 
  const helpTextPicture = useHelpText(0)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)
  const sysText10008 = useSystemText(10008)
  
  const sysText707002 = useSystemText(707002)
  const sysText707003 = useSystemText(707003)
  const sysText707004 = useSystemText(707004)
  const sysText707007 = useSystemText(707007)
  const sysText707008 = useSystemText(707008)
  const sysText707009 = useSystemText(707009)

  const LogDialog = () => {
    if (showLog === false) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setShowLog(false)}
        header={sysText707009}
      >
        <div className='mx-auto w-[800px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText707009}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
           <pre>
              {updateStateData.log}
           </pre>            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setShowLog(false)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };
  
  const confirmHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      id: updateStateData.id,
      review: "1"
    }
    
    try {
      
      const {listResponse, status, statusText} = await web_60_ncc_review_ncc(filterData)

      if (status === 200) { 
        setIsRefresh(prev => !prev)        
        showToast("success", "" + status, `成功审核 ID:${filterData.id}` )          
        setShowUpdateState(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }

  const exceptionHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      id: updateStateData.id,
      review: "2"
    }
    
    try {
      
      const {listResponse, status, statusText} = await web_60_ncc_review_ncc(filterData)

      if (status === 200) { 
        setIsRefresh(prev => !prev)        
        showToast("success", "" + status, `成功駁回 ID:${filterData.id}` )          
        setShowUpdateState(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    try {
      setInfoToObject(JSON.parse(updateStateData?.資訊))
    } catch(e) {
      showToast("error", "此笔\"资讯\"栏位有误")
    }
  }, [updateStateData])
 
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[600px] max-md:w-[100%] max-h-[80%] overflow-y-scroll bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='flex flex-row border-b border-gray-500 text-lg p-6 flex-1'>
              <p>{sysText707007}</p>
              <span className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></span>
            </div>
            <div className='p-4'>
              <p>{sysText707002}: <span className='text-sm'>{updateStateData.姓名}</span></p>
              <p>{sysText707003}: <span className='text-sm'>{updateStateData.收款人姓名}</span></p>
              <p>{sysText707004}:</p>
              <div className='flex flex-col px-4 border border-yellow-400'>
                {infoToObject && Object.keys(infoToObject).length > 0 &&
                  Object.entries(infoToObject).map(([key, value]) => 
                  <p key={key}>{key}: <span>{value}</span></p>
                  )
                }
              </div>

              <Button label={sysText707008} severity='info' onClick={() => {setShowLog(true)}}></Button>              
              
              {updateStateData.圖片網址 ?
                <div className='flex justify-center items-center p-4'>
                  {isImgLoading && <div className="loading-indicator absolute">Loading...</div>}
                  {/* <a className='flex justify-center items-center' href={`${updateStateData.圖片網址}`} target='_blank' rel='noopener noreferrer'> */}
                    <img className='max-w-[400px] max-h-[400px] max-md:max-w-[80%] border border-red-800 cursor-pointer' onLoad={() => setIsImgLoading(false)} onError={({currentTarget}) => imgOnError(currentTarget)} src={`${updateStateData.圖片網址}`} onClick={(currentTarget) => {window.open(currentTarget.src)}}></img>
                  {/* </a> */}
                </div>  
                : <div className='flex justify-center items-center min-w-[400px] min-h-[400px] text-gray-500'>
                    <p className=''>无资料</p>
                  </div>
              }
            </div>
            
            <div className='border-t border-gray-500 flex justify-around items-center h-20'>
              <Button style={{width: '7rem'}} label={sysText10003} severity='secondary' raised onClick={() => setShowUpdateState(false)}></Button>
              <Button style={{width: '7rem'}} label={sysText10002} severity='info' onClick={confirmHandler} raised></Button>
              <Button style={{width: '7rem'}} label={sysText10008} severity='danger' raised onClick={exceptionHandler}></Button>
            </div>
          </div>
        </div>
      </Dialog>

      <LogDialog/>
    </>
  );
};

export default UpdateStateNcc;