import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { web_17_ncc_gateway_type, web_83_edit_gateway } from '../../../api/api';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

const GatewaySettingEdit = (props) => {

  const {rowData, setShowEdit} = props
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [gatewayTypeList, setGatewayTypeList] = useState([]) 

  const stateOption = [
    {
      name: "停用",
      value: "0"
    },
    {
      name: "启用",
      value: "1"
    },
    {
      name: "上锁",
      value: "2"
    },
    {
      name: "限制",
      value: "3"
    },
  ]  

  const formik = useFormik({
    initialValues: {
        gatewayType: rowData.gateway_type_id,
        gatewayName: rowData.display_name,
        collectRate: rowData.col_rate_percent,
        collectFixedFee: rowData.col_rate_fixe,
        payRate: rowData.pur_rate_percent,
        payFixedFee: rowData.pur_rate_fixe,        
        minFee: rowData.min_fee,
        maxFee: rowData.max_fee,
        simpleMinlimitFee: rowData.min_limit_per_transaction,
        simpleMaxlimitFee: rowData.max_limit_per_transaction,
        state: rowData.state === 0 ? false : true
    },
    validate: (data) => {
      let errors = {};      

      const numberRegExp = /^\d{1,10}(\.\d{1,2})?$/ 
      const percentRegExp = /^(100(\.0{1,2})?|0(\.\d{1,2})?|[1-9]\d?(\.\d{1,2})?)$/    
      
      if (!data.gatewayType) {								
        errors.gatewayType = '必填';
      }

      if (!data.gatewayName) {								
        errors.gatewayName = '必填';
      }else if(data.gatewayName.length > 30) {
        errors.gatewayName = '不可超过30个字';
      }


      if (data.collectRate === null) {								
        errors.collectRate = '必填';
      }else if (!percentRegExp.test(data.collectRate)) {
        errors.collectRate = '0 ~ 100.00'
      }

      if (data.collectFixedFee === null) {								
        errors.collectFixedFee = '必填';
      }else if (!numberRegExp.test(data.collectFixedFee)) {
        errors.collectFixedFee = '必须是数字'
      }

      if (data.payRate === null) {								
        errors.payRate = '必填';
      }else if (!percentRegExp.test(data.payRate)) {
        errors.payRate = '0 ~ 100.00'
      }
      
      if (data.minFee === null) {								
        errors.minFee = '必填';
      }else if (!numberRegExp.test(data.minFee)) {
        errors.minFee = '必须是数字'
      }
      
      if (data.maxFee === null) {								
        errors.maxFee = '必填';
      }else if (!numberRegExp.test(data.maxFee)) {
        errors.maxFee = '必须是数字'
      }

      if (data.simpleMinlimitFee === null) {								
        errors.simpleMinlimitFee = '必填';
      }else if (!numberRegExp.test(data.simpleMinlimitFee)) {
        errors.simpleMinlimitFee = '必须是数字'
      }

      if (data.simpleMaxlimitFee === null) {								
        errors.simpleMaxlimitFee = '必填';
      }else if (!numberRegExp.test(data.simpleMaxlimitFee)) {
        errors.simpleMaxlimitFee = '必须是数字'
      }

      return errors;
    },
    onSubmit: async(data) => {  
      
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id: rowData.id,
        display_name: data.gatewayName,
        gateway_type_id: data.gatewayType,
        merchantno: rowData.merchantno,
        col_rate_percent: data.collectRate,
        col_rate_fixe: data.collectFixedFee,
        pur_rate_percent: data.payRate,
        pur_rate_fixe: data.payFixedFee,
        min_fee: data.minFee,
        max_fee: data.maxFee,
        min_limit_per_transaction: data.simpleMinlimitFee,
        max_limit_per_transaction: data.simpleMaxlimitFee,
        state: data.state ? "1" : "0"          
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await web_83_edit_gateway(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEdit(false)              
          showToast("success", "" + status, `成功编辑通道 商戶: ${filterData.merchantno}` )           
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	   

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  useEffect(() => {       
    const filterData = {     
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash")      
    };   

    const fetchGatewayTypeData = async() => {
      setIsLoading(true)       
      try {
        const { listResponse, status, statusText } = await web_17_ncc_gateway_type(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {           
          setGatewayTypeList(listResponse)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchGatewayTypeData(filterData)

  }, [isRefresh]);
      
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[35vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
            
            <div className='border-b border-gray-700 p-4 text-lg'>通道费率设置</div>

            <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[60vh] bg-customColor-bgGray overflow-y-scroll'>
              <div>
                <label htmlFor='gatewayType'>通道类型</label>
                <Dropdown name="gatewayType" value={formik.values.gatewayType} options={gatewayTypeList} optionLabel='名稱' optionValue='id' onChange={formik.handleChange} className='w-full custom-dropdown'/>
                {getFormErrorMessage('gatewayType')}
              </div>

              <div>
                <label htmlFor='gatewayName'>通道名稱</label>
                <input name="gatewayName" value={formik.values.gatewayName} autoComplete='off' onChange={formik.handleChange} className='w-full' type="text" />
                {getFormErrorMessage('gatewayName')} 
              </div>   

              <div>
                <label htmlFor='collectRate'>代收费率(%)</label>
                <input name="collectRate" value={formik.values.collectRate} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('collectRate')} 
              </div>                   
              
              <div>
                <label htmlFor='collectFixedFee'>代收固定费用</label>
                <input name="collectFixedFee" value={formik.values.collectFixedFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('collectFixedFee')}
              </div>

              <div>
                <label htmlFor='payRate'>代付费率(%)</label>
                <input name="payRate" value={formik.values.payRate} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('payRate')} 
              </div>                   
              
              <div>
                <label htmlFor='payFixedFee'>代付固定费用</label>
                <input name="payFixedFee" value={formik.values.payFixedFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('payFixedFee')}
              </div>

              {/* <div>
                <label htmlFor='minFee'>最低费用</label>
                <input name="minFee" value={formik.values.minFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('minFee')} 
              </div>

              <div>
                <label htmlFor='maxFee'>最高费用</label>
                <input name="maxFee" value={formik.values.maxFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('maxFee')}
              </div> */}

              <div>
                <label htmlFor='simpleMinlimitFee'>单笔最小限额</label>
                <input name="simpleMinlimitFee" value={formik.values.simpleMinlimitFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('simpleMinlimitFee')} 
              </div>

              <div>
                <label htmlFor='simpleMaxlimitFee'>单笔最高限额</label>
                <input name="simpleMaxlimitFee" value={formik.values.simpleMaxlimitFee} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('simpleMaxlimitFee')} 
              </div> 
              
              <div className='flex items-center space-x-4'>
                <label htmlFor='state'>关闭/启用</label>                 
                <InputSwitch name="state" checked={formik.values.state} onChange={formik.handleChange} className="p-inputswitch" />
              </div>                                                
            </div>

            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label='取消编辑' severity='secondary' raised onClick={() => setShowEdit(false)}></Button>
              <Button type="submit" style={{width: "120px"}} label='确认编辑' disabled={isLoading} severity='info' raised></Button>
            </div>
          
          </form>
          
        </div>
      </Dialog>
    </>
  );
};

export default GatewaySettingEdit;