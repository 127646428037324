import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_109_agency_transaction } from '../../api/api';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';

const AgencyTransaction = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [agencyTransactionData , setAgencyTransactionData] = useState([]) 
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterDate, setFilterDate] = useState([])

  //預設時間為6個月前
  const today = new Date()

  const defaultDate = [
    new Date(today.setHours(0, 0, 0, 0)), // Today's start date (00:00:00)
    new Date(today.setHours(23, 59, 59, 999)) // Today's end date (23:59:59)
  ]  

  useEffect(() => {         

    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      s_time: filterDate[0] ? filterDate[0] : defaultDate[0].getTime(),
      e_time: filterDate[1] ? filterDate[1] : today.getTime(),
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_109_agency_transaction(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setAgencyTransactionData(Object.values(listResponse))
        }else if (status === 429) {          
          showToast("warn", "刷新太快，請重新整理" )
        } else {          
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (
         error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterDate, isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showSearchInput={false} setFilterDate={setFilterDate} defaultDate={defaultDate} placeholder="请选择日期"/>
        <div className=''>
          <DataTable value={agencyTransactionData} scrollable scrollHeight='550px'>
            <Column style={{ minWidth: "120px" }} field="用戶名" header="用户名"></Column>
            <Column style={{ minWidth: "120px" }} field="訂單成功數" header="总订单成功单数 "></Column>
            <Column style={{ minWidth: "120px" }} field="營業額" header="总营业额"></Column>
            <Column style={{ minWidth: "120px" }} field="採購成功數" header="总採购成功单数"></Column>
            <Column style={{ minWidth: "120px" }} field="採購成功額" header="总採购成功金额"></Column>  
            <Column style={{ minWidth: "120px" }} field="營業額提成" header="总营业额提成"></Column>
            <Column style={{ minWidth: "120px" }} field="可提領金額" header="当前可提领金额"></Column>
            <Column style={{ minWidth: "120px" }} field="結算中" header="结算中资金"></Column>
          </DataTable>      
        </div>
        
      </div>
  );
}

export default AgencyTransaction