import ToastContext from "../context/ToastContext";
import { useContext } from "react";

function useJson() {
  const {showToast} = useContext(ToastContext)

  const jsonToObject = (data) => {
    let newData = {} 
    try {          
      newData = JSON.parse(data)
    } catch (e) {
      showToast("warn", "卡片资讯有误")
      return {}
    }
    
    return newData
  }
 
  return {jsonToObject};
}

export default useJson