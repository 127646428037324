import React, { useState, useEffect, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
// import FilterInput from '../../UI/filterInput';
import LoadingContext from '../../../context/loadingContext';
import { web_55_insert_erp_water168_shop_bank } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';

const AddBankName = (props) => {

  const {setShowAddBankName} = props 
  const [bankNameInput, setBankName] = useState("")  
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation() 

  const confirmHandler = async() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),      
      bank_name: bankNameInput
    }
      
    try {
      const { listResponse, status, statusText } = await web_55_insert_erp_water168_shop_bank(filterData);        
      
      console.log("listResponse: ", listResponse)

      if (status === 200) {
        setIsRefresh(prev => !prev)
        showToast("success", "" + status, `成功新增銀行名稱: ${filterData.bank_name}` )            
        setShowAddBankName(false)      
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  } 

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>新增银行</div>
                    
            <div className='space-y-2 text-sm my-2 px-8 py-4 overflow-y-scroll'>
              <p>银行名称</p>
              <input className='w-80' type="text" value={bankNameInput} onChange={(e) => setBankName(e.target.value)} placeholder='请输入银行名称'/>             
            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{width: "120px"}} label='取消新增' severity='secondary' onClick={() => setShowAddBankName(false)}></Button>
              <Button style={{width: "120px"}} label='确认新增' severity='info' onClick={confirmHandler}></Button>
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default AddBankName;