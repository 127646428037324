import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_19_ncc_review_info } from '../../api/api';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import FilterInput from '../../components/UI/filterInput';
import Cookies from 'js-cookie';
import UpdateState from '../../components/other/cardCheck/updateState';
import UpdateStateNcc from '../../components/other/cardCheckNcc/updateStateNcc';
import useSystemText from '../../helper/useSystemText';

const CardCheckNcc = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [cardCheckNccData , setCardCheckNccData] = useState([]);
  const [updateStateData, setUpdateStateData] = useState({})  
  const [showUpdateState, setShowUpdateState] = useState(false)
  const [filterUUID, setFilterUUID] = useState("")
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()   

  const sysText707001 = useSystemText(707001)
  const sysText707002 = useSystemText(707002)
  const sysText707003 = useSystemText(707003)
  const sysText707004 = useSystemText(707004)
  const sysText707005 = useSystemText(707005)
  const sysText707006 = useSystemText(707006)
  const sysText707010 = useSystemText(707010)

  const ActiveColumn = (item) => {   
    const updateStateHandler = () => {
      setUpdateStateData(item)
      setShowUpdateState(true)
    }            
       
    return (      
      <div className='activeBtn flex items-center justify-center'>          
        <Button label={sysText707006} severity="warning" raised onClick={updateStateHandler}/>        
      </div>
    )
  }      

  useEffect(() => {    

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterUUID,
      card: "0"
    }
    
    const fetchData = async() => {    
      setIsLoading(true)
      try {
          
        const { listResponse, status, statusText } = await web_19_ncc_review_info(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setCardCheckNccData(listResponse.data)            
                   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [isRefresh, filterUUID]);      

  return (
      <div className="p-4 recordWrap tableWrap"> 
        <FilterInput setFilterNumber={setFilterUUID} showCalendarInput={false} placeholder={sysText707010}/>       
        <div className=''>
          <DataTable value={cardCheckNccData}  scrollable scrollHeight="530px">
            <Column style={{ minWidth: '150px' }} field="用戶編號" sortable header={sysText707001}></Column>
            <Column style={{ minWidth: '100px' }} field="姓名" header={sysText707002}></Column>
            <Column style={{ minWidth: '150px' }} field="收款人姓名" header={sysText707003}></Column>
            <Column style={{ minWidth: '150px' }} field="資訊" sortable header={sysText707004}></Column>                          
            <Column style={{ minWidth: "100px" }} field="active" header={sysText707005} body={ActiveColumn} headerClassName='cented-header'></Column>
          </DataTable>         
        </div>             
        
        {showUpdateState && <UpdateStateNcc updateStateData={updateStateData} setShowUpdateState={setShowUpdateState}/>}

      </div>
  );
}

export default CardCheckNcc