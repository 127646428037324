import { useState, useEffect } from 'react';

const useTableSize = (largeHeight = 60, smallHeight = 50) => {
  const thresholdWidth = 1280
  const [tableScrollY, setTableScrollY] = useState(window.innerWidth >= thresholdWidth ? window.innerHeight * (largeHeight / 100) : window.innerHeight * (smallHeight / 100));
  
  useEffect(() => {

    console.log("window.innerHeight: ", window.innerHeight);
    const handleResize = () => {
      window.innerWidth >= thresholdWidth ? setTableScrollY(window.innerHeight * (largeHeight / 100)) : setTableScrollY(window.innerHeight * (smallHeight / 100));    
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [thresholdWidth, largeHeight, smallHeight]);

  return tableScrollY;
};

export default useTableSize;