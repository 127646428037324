import React, {useContext, useState} from 'react';
import  Dialog from '../../dialog'
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_62_updata_credit } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';

const EditCredits = (props) => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {editCreditData, setShowEditCredit} = props
  const [creditsInput, setCreditsInput] = useState(editCreditData?.信用額度)
  const [amountInput, setAmountInput] = useState(editCreditData?.當前額度)

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText703001 = useSystemText(703001)
  const sysText703002 = useSystemText(703002)
  const sysText703003 = useSystemText(703003)
  const sysText703004 = useSystemText(703004)
  const sysText703008 = useSystemText(703008)

  const confirmHandler = async() => {
    setIsLoading(true) 
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      update_uuid: editCreditData.uuid,
      old_credittotal: editCreditData?.信用額度,
      old_creditbalance: editCreditData?.當前額度,
      credittotal: creditsInput,
      creditbalance: amountInput
    }

          
    try {
      const { listResponse, status, statusText} = await web_62_updata_credit(filterData);          
      console.log("listResponse: ", listResponse)          

      if (status === 200) {                     
        setIsRefresh(prev => !prev)
        setShowEditCredit(false)          
        showToast("success", "" + status, `成功更新信用分数: ${editCreditData.uuid}` )
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }
  

  return (
    <Dialog>
      <div className='mx-auto w-[500px] max-md:w-full min-h-[400px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <div className='flex flex-col'>
          <div className='flex items-center p-6 border-b border-gray-700'>
            <h2 className='text-lg'>{sysText703008}</h2>
          </div>           
          <div className='leading-9 p-7 text-sm space-y-2'>
            <p>{sysText703001}: {editCreditData?.uuid}</p>
            <p>{sysText703002}: {editCreditData?.用戶姓名}</p>          
            <p>{sysText703003}</p>
            <input type="number" value={creditsInput} onChange={(e) => setCreditsInput(e.target.value)} className='w-full'></input>
            <p>{sysText703004}</p>
            <input type="number" value={amountInput} onChange={(e) => setAmountInput(e.target.value)} className='w-full'></input>
          </div>  
          
          <div className='flex flex-auto w-full justify-around items-center border-t border-gray-700 p-2'>
            <Button className='flex justify-center w-24' label={sysText10003} onClick={() => {setShowEditCredit(false)}} size="small" severity="secondary" raised></Button>
            <Button className='flex justify-center w-24' label={sysText10002} onClick={confirmHandler} size="small" severity="info" raised></Button>
          </div> 
        </div>
        
      </div>
    </Dialog>
  );
};

export default EditCredits;