
const riskControl_text = `先设定"风控封禁-时长"这表示用户触发以下条件后将被锁多少(秒)
再设定触发条件，这些表示用户在多少(秒)超过一定的金额或次数将被封锁
`

const userManageEditUser_text = `层级: 0~99 层级越大的用户将会优先派单
批量修改: 当前修改用户的所有下限用户一并修改层级`

export default {riskControl_text, userManageEditUser_text}

