import React, { useContext, useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import Cookies from "js-cookie";
import { ToastContext } from "../../context/ToastContext";
import LoadingContext from "../../context/loadingContext";
import { useLocation } from "react-router-dom";
import { web_33_add_pur_order, web_84_add_flowrecord_get_merchantno } from "../../api/api";

const PurchaseAdd = () =>  {    

    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
		const {isLoading, setIsLoading} = useContext(LoadingContext)
		const location = useLocation()
    const [merchantno, setMerchantno] = useState("")

    function generateCode() {
      // 產生0-999999的隨機數
      const randomNum = Math.floor(Math.random() * 1000000);
  
      // 將隨機數轉為字串，並在不足6位的情況下在前面補0
      const randomNumStr = String(randomNum).padStart(6, '0');
  
      // 獲取當前時間戳
      const timestamp = Date.now();
  
      // 將隨機數和時間戳組合成最終的代碼
      const code = randomNumStr + timestamp;
  
      return code;
    }
    
    const formik = useFormik({
      initialValues: {
        customerno: '',
        amount: 0,
        merchantno: '',
        bankname: '',
        accountname: '',
        bankbranch: '',
        bankcity: '',
        bankcardno: '',        
      },
      validate: (data) => {
          let errors = {};
          const numberRegExp = /^[0-9]*$/        

          if (!data.customerno) {								
            errors.customerno = '必填';
          }          

          if (!data.merchantno) {								
            errors.merchantno = '必填';
          }
          
          if (!data.bankname) {								
            errors.bankname = '必填';
          }

          if (!data.accountname) {								
            errors.accountname = '必填';
          }

          // if (!data.bankbranch) {								
          //   errors.bankbranch = '必填';
          // }

          // if (!data.bankcity) {								
          //   errors.bankcity = '必填';
          // }

          if (!data.bankcardno) {								
            errors.bankcardno = '必填';
          } else if (!numberRegExp.test(data.bankcardno)) {
            errors.bankcardno = '必须是0-9'
          }           
         
          return errors;
      },     
      
      onSubmit: (data) => {
        
          const filterData = {    
            cookie_uuid: Cookies.get("cookie_uuid"),
            cookie_hash: Cookies.get("cookie_hash"), 
          	customerno: data.customerno,
            amount: data.amount,
            merchantno: data.merchantno,
            bankname: data.bankname,
            accountname: data.accountname,
            bankbranch: data.bankbranch || "",
            bankcity: data.bankcity || "",
            bankcardno: data.bankcardno.toString(),
            confirmpeople: Cookies.get("cookie_uuid"),
            source: Cookies.get("cookie_uuid")
          };    
      
          const addPurOrder = async () => { 
            setIsLoading(true)       
          	try {
          	
          		const { listResponse, status, statusText } = await web_33_add_pur_order(filterData);       
              
          		console.log("listResponse: ", listResponse)
      
          		if (status === 200) {                  
                setIsRefresh(prev => !prev)
          			showToast("success", "" + status, `成功新增一笔采购单 编号为:${data.customerno}` )
                formik.resetForm();
          		} else {
          			showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          		}
          	} catch (error) {        
          		console.error(location.pathname, error);            
          	} finally {
          		setIsLoading(false)
          	}
          }
          addPurOrder()            
      }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };  
    
    useEffect(() => {
      const web84_fetchData = async() => {
        setIsLoading(true)
        const filterData = {      
          cookie_uuid: Cookies.get('cookie_uuid'),
          cookie_hash: Cookies.get('cookie_hash')                       
        };
           
        try {
          const { listResponse, status, statusText} = await web_84_add_flowrecord_get_merchantno(filterData);          
          console.log("listResponse: ", listResponse)          
  
          if (status === 200) {                     
            formik.setFieldValue("merchantno", listResponse?.merchantno)
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      } 
      web84_fetchData()

      formik.setFieldValue("customerno", generateCode())
    }, [location, isRefresh])

    return (
        <div className="systeminformationWrap card flex justify-content-center">
          <div className="my-12 mx-10 p-8 border border-gray-700 w-full overflow-y-scroll max-h-[80vh] max-md:mx-auto">
            <div className="absolute my-[-3rem] text-xl text-gray-400 bg-[#18191a]">新增采购单 </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row flex-wrap justify-between">
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                  <div>
                    <label htmlFor="customerno" className="text-blue-300">商户订单号</label>
                  </div>
                               
                  <input
                      id="customerno"
                      name="customerno"                  
                      className="p-2 w-64 cursor-not-allowed opacity-50"
                      value={formik.values.customerno}                      
                      placeholder="请输入商户订单号"
                      autoComplete="off"   
                      disabled={true}                   
                  />
                  {getFormErrorMessage('customerno')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="amount" className="text-blue-300">订单金额</label>
                  </div>             
                  <input
                      id="amount"
                      name="amount"                  
                      className="p-2 w-64"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      autoComplete="off" 
                  />
                  {getFormErrorMessage('amount')}
                </div>
                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="merchantno" className="text-blue-300">商户编号</label>
                  </div>             
                  <input
                      id="merchantno"
                      name="merchantno"                  
                      className="p-2 w-64 opacity-50 cursor-not-allowed"
                      value={formik.values.merchantno}
                      onChange={formik.handleChange}
                      placeholder="请输入商户编号"
                      autoComplete="off"
                      disabled={true}
                  />
                  {getFormErrorMessage('merchantno')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="bankname" className="text-blue-300">银行名称</label>
                  </div>             
                  <input
                      id="bankname"
                      name="bankname"                  
                      className="p-2 w-64"
                      value={formik.values.bankname}
                      onChange={formik.handleChange}
                      placeholder="请输入银行名称"
                      autoComplete="off"                     
                      
                  />
                  {getFormErrorMessage('bankname')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="accountname" className="text-blue-300">银行户名</label>
                  </div>             
                  <input
                      id="accountname"
                      name="accountname"                  
                      className="p-2 w-64"
                      value={formik.values.accountname}
                      onChange={formik.handleChange}
                      placeholder="请输入银行户名"
                      autoComplete="off"                     
                  />
                  {getFormErrorMessage('accountname')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="bankbranch" className="text-blue-300">开户支行</label>
                  </div>             
                  <input
                      id="bankbranch"
                      name="bankbranch"                  
                      className="p-2 w-64"
                      value={formik.values.bankbranch}
                      onChange={formik.handleChange}  
                      placeholder="请输入开户支行"
                      autoComplete="off"                    
                  />
                  {getFormErrorMessage('bankbranch')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="bankcity" className="text-blue-300">开户城市</label>
                  </div>             
                  <input
                      id="bankcity"
                      name="bankcity"                  
                      className="p-2 w-64"
                      value={formik.values.bankcity}
                      onChange={formik.handleChange}
                      placeholder="请输入开户城市"
                      autoComplete="off"                  
                  />
                  {getFormErrorMessage('bankcity')}
                </div>

                <div className="flex flex-col w-[25%] max-2xl:w-[50%] max-md:w-[100%]">
                   <div>
                    <label htmlFor="bankcardno" className="text-blue-300">银行卡号</label>
                  </div>             
                  <input
                      id="bankcardno"
                      name="bankcardno"                  
                      className="p-2 w-64"
                      value={formik.values.bankcardno}
                      onChange={formik.handleChange}                     
                      autoComplete="off"
                      placeholder="请输入银行卡号"
                      maxLength="30"                     

                  />
                  {getFormErrorMessage('bankcardno')}
                </div>
              
              </div>
              
              <Button label="新增" type="submit" disabled={isLoading} severity="info" raised className="w-36" />
              
            </form>
          </div>
          
        </div>
    )
}

export default PurchaseAdd;