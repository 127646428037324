import React, { useEffect, useContext, useState } from 'react';
import Dialog from '../../dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import Cookies from 'js-cookie';
import { web_29_Order_Detail } from '../../../api/api';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { imgOnError } from '../../../helper/imgOnError';
import useCopy from '../../../helper/useCopy';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';

const RecordDetail = (props) => {

  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {setShowDetail, detailData} = props
  const location = useLocation()
  const {showToast} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [otherDetailData, setOtherDetailData] = useState({})
  const {copyHandler} = useCopy()
  
  const [payerName, setPayerName] = useState("");
  const [displayPayerName, setDisplayPayerName] = useState("");
  const helpTextPicture = useHelpText(0)

  const imgDetail = (item) => {
    return (
    <>
      <HelpIcon tooltipText={helpTextPicture}></HelpIcon>
      <a href={`https://water168.shop/pic/${item.img}`} target='_blank' rel='noopener noreferrer'>     
        <img src={`https://water168.shop/pic/${item.img}`} className='max-w-[100px] max-h-[100px]' onError={({currentTarget}) => imgOnError(currentTarget)}></img>
      </a>
    </>
    )
  }

  useEffect(() => {
    if (otherDetailData) {
      const name = otherDetailData.付款人姓名;
      setPayerName(name);
      setDisplayPayerName(name ? (name.length < 2 ? '*' : name[0] + '*' + name.slice(2)) : '');
    }
  }, [otherDetailData]);

  useEffect(() => {      
    setIsLoading(true)
    const filterData = {       
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      ordernumber: detailData?.訂單編號,     
    };

    const fetchData = async() => {
            
      try {
        const { listResponse, status, statusText } = await web_29_Order_Detail(filterData);          
        console.log("listResponse: ", listResponse)          

        if (status === 200) { 
          setOtherDetailData(listResponse)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)      
  }, [location]);

  return (
    <>      
      <Dialog>
        <div className='mx-auto w-[70vw] bg-customColor-bgGray rounded-xl overflow-y-scroll max-md:w-full max-h-[800px] max-md:max-h-[500px]'>
          <div className='flex w-full h-20'>
            <ul className='flex justify-between items-center w-full border-b px-8 max-md:px-2 max-md:text-xs'>
              <li className='text-lg max-md:text-lg'>订购产品详情</li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/api/kuaidi/yuantong/view/${detailData?.訂單編號}`}>国内运单</a></li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/t/kuaidi/tracker/${detailData?.訂單編號}`}>发货凭证</a></li>
              <li className='text-blue-500'><a href={`http://47.111.232.151:5000/api/kuaidi/yuantong/viewdd/${detailData?.訂單編號}`}>快递追踪</a></li>
              <li className='text-blue-500'><a href={`https://expressdeles.com/#/detail/${detailData?.訂單編號}`}>国际运单</a></li>
            </ul>
          </div>
          <div className='flex flex-col border-b px-8'>                  
            <div className='flex flex-wrap'>
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className=' my-2'>
                    <label>订单编号</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.訂單編號}</p>
                  </li>
                  <li className=' my-2'>
                    <label>付款人姓名</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{displayPayerName}</p>
                  </li>
                  <li className=' my-2'>
                    <label>付款人地址</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{otherDetailData.付款人地址}</p>
                  </li>
                  <li className=' my-2'>
                    <label>付款人卡号</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{otherDetailData.付款人卡號}</p>
                  </li>                
                </ul>              
              
                <ul className='flex flex-col w-[50%] max-2xl:w-[100%]'>
                  <li className='my-2'>
                    <label>订单时间</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.訂單時間}</p>
                  </li>
                  <li className='my-2'>
                    <label>收款人姓名</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.員工姓名}</p>
                  </li>
                  <li className='my-2'>
                    <label>收款人卡号</label>
                    <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{detailData.員工卡號}</p>
                  </li>                 
                </ul>
              
            </div>

            <div className='w-full tableWrap mt-2'>
              <DataTable value={otherDetailData.產品資訊} tableStyle={{ minWidth: '50rem' }}  scrollable scrollHeight="200px">
                <Column field="name" header="产品名称"></Column>
                <Column field="img" header="产品图片" body={imgDetail}></Column>
                <Column field="qty" header="数量"></Column>
                <Column field="price" header="价格"></Column>                      
              </DataTable>      
            </div>

            <div className='ml-auto my-4 w-[25%] max-2xl:w-full'>
              <ul>
                <li className='flex justify-between m-2'><p>商品总金额</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.訂單金額 + otherDetailData.折扣}</p></li>
                <li className='flex justify-between m-2'><p>折扣金额</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{otherDetailData.折扣}</p></li>
                <li className='flex justify-between m-2'><p>订单总金额</p> <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>¥ {detailData.訂單金額}</p></li>
              </ul>
            </div>                                     
            
          </div>
          <div className='flex flex-auto w-full justify-center items-center mt-3 py-2'>
            <Button className='w-[20%] flex justify-center' onClick={() => {setShowDetail(false)}} severity="info" raised>OK</Button>
          </div> 
        </div>
      </Dialog>       
      
    </>
    
  );
};

export default RecordDetail;