import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Table } from 'flowbite-react';
import { ToastContext } from '../../context/ToastContext';
import { formatDate } from '../../helper/formatDate';

import FilterInput from '../../components/UI/filterInput';

// import testRecordData from '../../data/testRecordData'
import { web_12_orderlist } from '../../api/api'
import PaginatorUI from '../../components/UI/paginatorUI';
import { useLocation } from 'react-router-dom';
import RecordComplete from '../../components/orderManage/record/recordComplete';
import LoadingContext from '../../context/loadingContext';
// import RecordMessageU3 from '../../components/orderManage/recordU3/recordMessageU3';
import Cookies from 'js-cookie';
import RecordWechat from '../../components/orderManage/record/recordWechat';
import RecordFreeze from '../../components/orderManage/record/recordFreeze';
import { SplitButton } from 'primereact/splitbutton';
import RecordDetail from '../../components/orderManage/record/recordDetail';
import RecordResend from '../../components/orderManage/record/recordResend';

const Record = () => {       
    const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
    const {isLoading, setIsLoading} = useContext(LoadingContext)
    const [recordData , setRecordData] = useState([]);
    const [totalData, setTotalData] = useState({})
    const [totalCount, setTotalCount] = useState(0)
    const [filterDate, setFilterDate] = useState([])    
    const [filterNumber, setFilterNumber] = useState("")    
    // const [showMessage, setShowMessage] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [showComplete, setShowComplete] = useState(false)    
    const [showWechat, setShowWechat] = useState(false)
    const [detailData, setDetailData] = useState({})
    // const [messageData, setMessageData] = useState({})
    const [wechatData, setWechatData] = useState({})
    const [showFreeze, setShowFreeze] = useState(false)
    const [freezeOrUnfreeze, setFreezeOrUnfreeze] = useState(null)
    const [showResend, setShowResend] = useState(false)
    const [resendData, setResendData] = useState({})        
    const [first, setFirst] = useState(0)   
    const [page, setPage] = useState(0)
    const [rows, setRows] = useState(10);
    const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
    const location = useLocation()     
    console.log(filterDate);

    const ActiveColumn = (item) => {     
            
      const statusBtnCheck = () => {   
        let label = ''
        let severity = ''
        let custom = ''
        switch (item.訂單狀態) {
          case 0:
            label = "未支付"
            severity = "secondary"
            break
          case 1:
            label = "已支付"
            severity = "info"
            break
          case 2:
            label = "已確認"
            severity = "success"
            break
          case 3:
            label = "已逾時"
            severity = "warning"
            break
          case 4:
            label = "操作中"
            severity = "help"
            break
          case 5:
            label = "凍結中"
            severity = "secondary"            
            break
          case 9:
            label = "異常"
            severity = "danger"
            break
          default:
            label = '';
            severity = '';
        }
        return {label, severity}             
      }     

      const {label, severity} = statusBtnCheck()      

      // const showMessageHandler = () => {
      //   setShowMessage(true)
      //   setMessageData(item)        
      // }

      const showDetailHandler = () => {
        setShowDetail(true)
        setDetailData(item)        
      }

      const showCompleteHandler = () => {
        setShowComplete(true)
        setDetailData(item) 
      }

      const showWechatHandler = () => {
        setShowWechat(true)
        setWechatData(item)         
      }

      const showFreezeHandler = () => {
        setFreezeOrUnfreeze("冻结") 
        setShowFreeze(true)
        setDetailData(item)         
      }

      // const showUnFreezeHandler = () => {
      //   setFreezeOrUnfreeze("解冻") 
      //   setShowFreeze(true)
      //   setDetailData(item)  
      // }

      const showResendHandler = () => {
        setShowResend(true)
        setResendData(item)
      }

      let menuItems1_2 = [        
        {
          label: '订单详情',
          command: showDetailHandler          
        },  
        {
          label: '完成订单',
          command: showCompleteHandler,
          disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
        },     
      ];

      let menuItems3 = [
        {
          label: '完成订单',
          command: showCompleteHandler,
          disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
        },
        {
          label: '查看微信',
          command: showWechatHandler          
        },
        {
          label: '冻结',
          command: showFreezeHandler,
          disabled: item.訂單狀態 === 5          
        },       
        {
          label: '重发回调',
          command: showResendHandler,
          disabled: item.訂單狀態 !== 2         
        },         
      ];

      let menuItems8 = [
        {
          label: '重发回调',
          command: showResendHandler,
                    
        }
      ];

      let menuItems9 = [
        {
          label: '完成订单',
          command: showCompleteHandler,
          disabled: item.訂單狀態 === 2 || item.訂單狀態 === 5
        },
        // {
        //   label: '订单详情',
        //   command: showDetailHandler          
        // },
        {
          label: '冻结',
          command: showFreezeHandler,
          disabled: item.訂單狀態 === 5          
        },       
        {
          label: '重发回调',
          command: showResendHandler,
                    
        },           
      ];

      //凍結與解凍功能在同一頁時

      // if(item.訂單狀態 !== 5) {
      //   const freezeButton = {
      //     label: '冻结',
      //     command: showFreezeHandler          
      //   }

      //   menuItems3.push(freezeButton)
      //   menuItems9.push(freezeButton)
         
      // }else if(item.訂單狀態 === 5){
      //   const unFreezeButton = {
      //     label: '解冻',
      //     command: showUnFreezeHandler,
      //     disabled: item.訂單狀態 !== 5
      //   }
      //   menuItems3.push(unFreezeButton)
      //   menuItems9.push(unFreezeButton)
      // }
      
      return (       
        
        <div className='activeBtn flex flex-col items-center justify-center p-0 w-full'> 
          {(uuidFirst === 1 || uuidFirst === 2) &&           
            <SplitButton label={label} model={menuItems1_2} severity={severity} />      
          }         

          {uuidFirst === 3 &&           
            <SplitButton label={label} model={menuItems3} severity={severity} />      
          }       

          {uuidFirst === 8 &&           
            <Button label={label} severity={severity} />      
          }       

          {uuidFirst === 9 &&           
            <SplitButton label={label} model={menuItems9} severity={severity} />      
          }         
        </div>   
        
      )    
    }      

    useEffect(() => {      
      const filterData = {      
        cookie_uuid: Cookies.get('cookie_uuid'),
        cookie_hash: Cookies.get('cookie_hash'),    
        ordernumber: filterNumber,
        start_date: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
        end_date: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime(),
        page: page + 1,
        limit: rows,        
      };

      const fetchData = async() => {
        setIsLoading(true)       
        try {
          const { listResponse, status, statusText} = await web_12_orderlist(filterData);          
          console.log("listResponse: ", listResponse)          

          if (status === 200) {                     
            setRecordData(listResponse.data)            
            setTotalCount(listResponse.count[0])
            setTotalData(listResponse.count_data[0])             
          } else {
            showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
          }
        } catch (error) {          
          console.error(location.pathname, error);            
        }finally {
          setIsLoading(false)
        }
      }
      fetchData(filterData)      
    }, [first, rows, page, filterDate, filterNumber, isRefresh]);      

    return (
        <div className="p-4 recordWrap tableWrap">
          {(uuidFirst === 3 || uuidFirst === 8 || uuidFirst === 9) &&
            <div className='flex flex-row'>
              <div className='px-1 py-1 bg-blue-500 rounded-md mr-2 max-md:text-center'>已完成订单总数：{totalData.完成筆數 ?? 0}</div>
              <div className='px-1 py-1 bg-yellow-400 rounded-md max-md:text-center'>已完成订单总金额 ：{totalData.完成金額加總 ?? 0}</div>            
           </div>
          }
         
          <FilterInput setFilterDate={setFilterDate} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} placeholder="请输入订单编号"/>
          <div className='max-2xl:hidden'>
            <DataTable value={recordData} scrollable scrollHeight="550px">
              <Column field="訂單時間" header="订单时间" body={(date) => formatDate(date.訂單時間)}></Column>
              <Column field="完成時間" header="完成时间" body={(date) => formatDate(date.完成時間)}></Column>
              <Column field="訂單編號" header="订单编号"></Column>
              {(uuidFirst === 3 || uuidFirst === 8 || uuidFirst === 9) &&
                <Column field="付款人姓名" header="付款人姓名"></Column>
              }              
              <Column field="員工姓名" header="收款人姓名"></Column>
              <Column field="員工卡號" header="收款人卡号"></Column>
              <Column field="員工銀行名稱" header="收款人银行"></Column>              
              <Column field="訂單金額" header="订单金额"></Column>                       
              {(uuidFirst === 8 || uuidFirst === 9) && 
                <>
                  <Column field="" header="运单编号"></Column>
                  <Column field="來源" header="来源"></Column>
                </>
              }
              <Column style={{ width: "140px" }} field="active" header="状态/操作" body={ActiveColumn} headerClassName="centered-header"></Column>
            </DataTable>
            <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>
          </div>        

          <div className='min-2xl:hidden'>
            <div className='overflow-y-scroll max-md:max-h-[50vh] max-lg:max-h-[600px] max-2xl:max-h-[700px] max-h-[600px]'>
              <Table className='border-none'>              
                <Table.Body className="text-white">
                  {
                    recordData.map((item) => {
                      return (
                        <Table.Row key={item.訂單編號} className="bg-customColor-bgDark mx-96 leading-6 rounded-md">
                          <Table.Cell className="flex flex-col w-[80%] max-md:w-[60%] mx-4">
                            <p>{formatDate(item.訂單時間)}</p>
                            {/* <p>{formatDate(item.完成時間)}</p> */}
                            <p>{item.訂單編號}</p>                           
                            {(uuidFirst === 3 || uuidFirst === 8 || uuidFirst === 9) &&
                              <p>{item.付款人姓名}</p>
                            }                           
                            <p>{item.員工姓名}</p>
                            <p>{item.員工卡號}</p>
                            <p>{item.員工銀行名稱}</p>                                                                              
                            <p>金額: {item.訂單金額}</p> 
                          </Table.Cell>   
                          <Table.Cell className="w-[20%] max-md:w-[40%] p-0">
                            {ActiveColumn(item)}
                          </Table.Cell>                 
                        </Table.Row>
                      )                       
                    })
                  }
                                                    
                </Table.Body>
              </Table>              
            </div>
            <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> 
          </div>    

          {/* 訂單詳細  */}
          {showDetail && <RecordDetail setShowDetail={setShowDetail} detailData={detailData}/>}      

          {/* 查看簡訊  */}
          {/* {showMessage && <RecordMessageU3 setShowMessage={setShowMessage} messageData={messageData}/>} */}

          {/* 查看微信  */}
          {showWechat && <RecordWechat setShowWechat={setShowWechat} wechatData={wechatData}/>}

          {/* 完成訂單 */}
          {showComplete && <RecordComplete setShowComplete={setShowComplete} detailData={detailData}/>}

           {/* 凍結 */}
           {showFreeze && <RecordFreeze setShowFreeze={setShowFreeze} rowData={detailData} freezeOrUnfreeze={freezeOrUnfreeze}/>}

           {/* 重發回調 */}
           {showResend && <RecordResend setShowResend={setShowResend} resendData={resendData} />}
        </div>
    );
}

export default Record