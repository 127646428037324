import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_63_credit_table_list } from '../../api/api';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import EditCredits from '../../components/other/creditManage/editCredits';
import EditLog from '../../components/other/creditManage/editLog';
import FilterInput from '../../components/UI/filterInput';
import useSystemText from '../../helper/useSystemText';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const CreditManage = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [creditManageData , setCreditManageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)     
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [showEditCredit, setShowEditCredit] = useState(false)
  const [editCreditData, setEditCreditData] = useState({})
  const [showEditLog, setShowEditLog] = useState(false)  
  const [editLogData, setEditLogData] = useState({})
  const [filterNumber, setFilterNumber] = useState("")
  const tableScrollY = useTableSize(65, 65)

  const sysText703001 = useSystemText(703001)
  const sysText703002 = useSystemText(703002)
  const sysText703003 = useSystemText(703003)
  const sysText703004 = useSystemText(703004)
  const sysText703005 = useSystemText(703005)  
  const sysText703006 = useSystemText(703006)
  const sysText703007 = useSystemText(703007)
  const sysText703010 = useSystemText(703010)    

  const ActiveColumn = (item) => {               
       
    const editCreditHandler = () => {
      setEditCreditData(item)
      setShowEditCredit(true)
    }

    const editLogHandler = () => {
      setEditLogData(item)
      setShowEditLog(true)
    }

    return (      
      <div className='activeBtn flex flex-row items-center space-x-2 justify-center'>          
        <Button style={{whiteSpace: "nowrap"}} label={sysText703006} severity="warning" raised onClick={editCreditHandler} />
        <Button style={{whiteSpace: "nowrap"}} label={sysText703007} severity="secondary" raised onClick={editLogHandler} />        
      </div>
    )
  }      

  const tableColumns = [
    {
      header: sysText703001,
      field: "uuid",
      style: {minWidth: "150px"},
    },
    {
      header: sysText703002,
      field: "用戶姓名",
      style: {minWidth: "150px"},
    },
    {
      header: sysText703003,
      field: "信用額度",
      style: {minWidth: "150px"},
    },
    {
      header: sysText703004,
      field: "當前額度",
      style: {minWidth: "150px"},
    },
    {
      header: sysText703005,
      field: "active",
      style: {minWidth: "150px"},
      enable: [3, 9],
      body: ActiveColumn,
      headerClassName: "centered-header"
    },   

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {    
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterNumber,
      page: page + 1,
      limit: rows    
    };

    const fetchData = async() => {    
      setIsLoading(true)   
      try {
        const { listResponse, status, statusText } = await web_63_credit_table_list(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setCreditManageData(listResponse.data)            
          setTotalCount(listResponse.count[0])            
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [first, rows, page, filterNumber, isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showCalendarInput={false} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} placeholder={sysText703010}>
          <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>
        </FilterInput>
        <div className=''>
          <DataTable value={creditManageData} scrollable scrollHeight={tableScrollY}>           
            {visibleColumns.map((row,index) =>                             
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>              
            )}        
          </DataTable>          
        </div>
            
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/>

        {/* 修改信用額度 */}
        {showEditCredit && <EditCredits editCreditData={editCreditData} setShowEditCredit={setShowEditCredit} />}

        {/* 查看修改紀錄 */}
        {showEditLog && <EditLog editLogData={editLogData} setShowEditLog={setShowEditLog} />}

      </div>
  );
}

export default CreditManage

