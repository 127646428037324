import React, { useState, useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import LoadingContext from '../../../context/loadingContext';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import PurchaseRecordTransfer from './purchaseRecordTransfer';
import PurchaseRecordSubRevoke from './purchaseRecordSubRevoke';
import { imgOnError } from '../../../helper/imgOnError';
import useCopy from '../../../helper/useCopy';
import PurchaseRecordConfirm from './purchaseRecordConfirm';
import useJson from '../../../helper/useJson';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';

const PurchaseRecordProcess = (props) => {

  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const { showToast, isRefresh, setIsRefresh } = useContext(ToastContext)
  const location = useLocation()
  const { setShowProcess, processParentData, processSubData } = props

  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const [showSubRevoke, setShowSubRevoke] = useState(false)
  const [subRevokeData, setSubRevokeData] = useState({})
  const [showTransfer, setShowTransfer] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const {copyHandler} = useCopy()
  const helpTextPicture = useHelpText(0)

  console.log("processParentData: ", processParentData);
  console.log("processSubData: ", processSubData);

  const confirmHandler = () => {    
    setShowConfirm(true)
  }

  const transferHandler = () => {    
    setShowTransfer(true)
  }

  const revokeHandler = () => {
    setSubRevokeData(processSubData)
    setShowSubRevoke(true)
  }


  return (
    <>
      <Dialog>
        <div className='mx-auto min-w-[350px] max-h-[800px] max-md:max-h-[500px] bg-customColor-bgGray rounded-xl overflow-y-scroll'>
          <div className='flex flex-col'>
            <div className='flex flex-row items-center p-6 border-b border-gray-700'>
              <h2 className='text-lg'>处理采购</h2>
              <div className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></div>
            </div>
            <div className='px-4 py-7 text-sm'>

              {(processSubData.圖片1 || processSubData.圖片2) &&
                <div className='flex flex-col mb-6 space-y-4'>
                  <div className=''>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>已上傳圖片</span>
                  </div>
                  <div className='flex flex-row space-x-2'>

                    <a href={`${processSubData.圖片1}`} target='_blank' rel='noopener noreferrer'>
                      <img className='max-w-[180px] max-h-[180px]' onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${processSubData.圖片1}`} />
                    </a>

                    <a href={`${processSubData.圖片2}`} target='_blank' rel='noopener noreferrer'>
                      <img className='max-w-[180px] max-h-[180px]' onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${processSubData.圖片2}`} />
                    </a>
                  </div>
                </div>
              }

              {!(processSubData.圖片1 || processSubData.圖片2) &&
                <div className='flex flex-col mb-6 space-y-4'>
                  <div className=''>
                    <span className='border border-gray-700 bg-[#440000] py-2 px-3 rounded-md'>尚未上传图档！</span>
                  </div>
                </div>
              }


              <div className='flex flex-wrap'>
                <div className='flex flex-col w-full my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>子單單號</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processSubData.子單單號}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人姓名</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人姓名}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人卡号</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人卡號}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>订单金额</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processSubData.訂單金額}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人银行名称</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人銀行名稱}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人开户城市</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人開戶城市}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人开户支行</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人開戶支行}</p>
                </div>
              </div>

            </div>

            <div className='flex w-full justify-around items-center border-t border-gray-700 p-4'>
              {uuidFirst === 3 && 
              <>
                <Button className='flex justify-center' label='确认子单' disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 0 || processSubData.狀態 == 2 || processSubData.狀態 == 3 || processSubData.狀態 == 4 || processSubData.狀態 == 9} onClick={confirmHandler} severity="success" raised></Button>
                <Button className='flex justify-center' label='撤回子单' disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 0 || processSubData.狀態 == 2 || processSubData.狀態 == 3 || processSubData.狀態 == 4} onClick={revokeHandler} severity="danger" raised></Button>
                <Button className='flex justify-center' label='转派子单' disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 1 || processSubData.狀態 == 2} onClick={transferHandler} severity="warning" raised></Button>
              </>
              }              
              <Button className='flex justify-center' label='关闭视窗' onClick={() => { setShowProcess(false) }} severity="secondary" raised></Button>
            </div>
          </div>

        </div>
      </Dialog>

      {/* 確認子單 */}
      {showConfirm && <PurchaseRecordConfirm setShowConfirm={setShowConfirm} processSubData={processSubData} processParentData={processParentData} setShowProcess={setShowProcess} />}

      {/* 撤回子單 */}
      {showSubRevoke && <PurchaseRecordSubRevoke setShowSubRevoke={setShowSubRevoke} subRevokeData={subRevokeData} setShowProcess={setShowProcess} />}

      {/* 轉派子單 */}
      {showTransfer && <PurchaseRecordTransfer setShowTransfer={setShowTransfer} processSubData={processSubData} setShowProcess={setShowProcess} />}

    </>
  );
};

export default PurchaseRecordProcess;

//P11080958056494685F0M