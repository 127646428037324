import React, { useState, useContext, useEffect } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../../context/loadingContext';
import { web_60_review_staffcardnumber } from '../../../api/api';
import { imgOnError } from '../../../helper/imgOnError';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';

const UpdateState = (props) => {

  const {setShowUpdateState, updateStateData} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [isImgLoading, setIsImgLoading] = useState(true)
  const [showLog, setShowLog] = useState(false)
  const location = useLocation()   
  const helpTextPicture = useHelpText(0)
  
  const LogDialog = () => {
    if (showLog === false) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setShowLog(false)}
        header="修改记录"
      >
        <div className='mx-auto w-[800px] max-md:w-full max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>修改记录</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
           <pre>
              {updateStateData.log}
           </pre>            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setShowLog(false)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };
  
  const confirmHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      cardnumber: updateStateData?.卡號,
      state: "1"
    }
    
    try {
      
      const {listResponse, status, statusText} = await web_60_review_staffcardnumber(filterData)

      if (status === 200) { 
        setIsRefresh(prev => !prev)        
        showToast("success", "" + status, `成功审核 卡号:${filterData.cardnumber}` )          
        setShowUpdateState(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }

  const exceptionHandler = async () => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      cardnumber: updateStateData?.卡號,
      state: "2"
    }
    
    try {
      
      const {listResponse, status, statusText} = await web_60_review_staffcardnumber(filterData)

      if (status === 200) { 
        setIsRefresh(prev => !prev)        
        showToast("success", "" + status, `成功駁回 卡号:${filterData.cardnumber}` )          
        setShowUpdateState(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }        
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }
  }
 
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[600px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='flex flex-row border-b border-gray-500 text-lg p-6 flex-1'>
              变更审核状态 <span className='ml-auto'><HelpIcon tooltipText={helpTextPicture}/></span>
            </div>
            <div className='p-4'>
              <p>用户姓名: <span className='text-sm'>{updateStateData.姓名}</span></p>
              <p>银行名称: <span className='text-sm'>{updateStateData.銀行名稱}</span></p>
              <p>开户支行: <span className='text-sm'>{updateStateData.開戶支行}</span></p>
              <p>卡号: <span className='text-sm text-yellow-400'>{updateStateData.卡號}</span></p>
              
              {updateStateData.圖片網址 ?
                <div className='flex justify-center items-center p-4'>                 
                  {isImgLoading && <div className="loading-indicator absolute">Loading...</div>}
                  <a href={`${updateStateData.圖片網址}`} target='_blank' rel='noopener noreferrer'>
                    <img className='max-w-[400px] max-h-[400px] border border-red-800 cursor-pointer' onLoad={() => setIsImgLoading(false)} onError={({currentTarget}) => imgOnError(currentTarget)} src={`${updateStateData.圖片網址}`} onClick={(currentTarget) => window.open(currentTarget.src)}></img>
                  </a>
                </div>  
                : <div className='flex justify-center items-center min-w-[400px] min-h-[400px] text-gray-500'>
                    <p className=''>无资料</p>
                  </div>
              }
            </div>
            
            <div className='border-t border-gray-500 flex justify-around items-center h-20'>
              <Button style={{width: '120px'}} label='取消变更' severity='secondary' raised onClick={() => setShowUpdateState(false)}></Button>
              <Button style={{width: '120px'}} label='确认审核' severity='info' onClick={confirmHandler} raised></Button>
              <Button style={{width: '120px'}} label='驳回审核' severity='danger' raised onClick={exceptionHandler}></Button>
            </div>
          </div>
        </div>
      </Dialog>

      <LogDialog/>
    </>
  );
};

export default UpdateState;