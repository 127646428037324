import React, {useContext, useState} from 'react';
import  Dialog from '../../dialog'
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { web_56_edit_percen, web_67_edit_percent_down_line } from '../../../api/api';
import useSystemText from '../../../helper/useSystemText';

const EditPercent = (props) => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()
  const {editPercentData, setShowEditPercent, editPercentUpperData} = props
  const [profit, setProfit] = useState(editPercentData.用戶分潤)
  const [downlineProfit, setDownlineProfit] = useState(editPercentData["下線完成訂單分潤(uid=2專用)"])

  const sysText10002 = useSystemText(10002)
  const sysText10003 = useSystemText(10003)

  const sysText502001 = useSystemText(502001)
  const sysText502013 = useSystemText(502013)
  const sysText502014 = useSystemText(502014)
  const sysText502015 = useSystemText(502015)

  //送出判斷UUID是否為2 只更新下線訂單分潤
  const confirmHandler = async() => {
    setIsLoading(true)
    if(String(editPercentData.uuid)[0] === "2") {
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        user_uuid: editPercentData.uuid,
        percen: profit,
        percent_down_line: parseFloat(downlineProfit)
      }        
             
      try {
        const { listResponse, status, statusText} = await web_67_edit_percent_down_line(filterData);          
        console.log("listResponse: ", listResponse)          
  
        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEditPercent(false)
          showToast("success", "" + status, `成功更新分润: ${editPercentData.uuid}` ) 
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }    

    }else {
      setIsLoading(true)  
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        percen_uuid: editPercentData.uuid,
        percen: profit
      }
  
           
      try {
        const { listResponse, status, statusText} = await web_56_edit_percen(filterData);          
        console.log("listResponse: ", listResponse)          
  
        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEditPercent(false)          
          showToast("success", "" + status, `成功更新分潤: ${editPercentData.uuid}` )
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {          
        console.error(location.pathname, error);            
      }finally {
        setIsLoading(false)
      }
    }
    
  }
  

  return (
    <Dialog>
      <div className='mx-auto w-[35vw] max-md:w-full min-h-[300px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <div className='flex flex-col'>
          <div className='flex items-center p-6 border-b border-gray-700'>
            <h2 className='text-lg'>{sysText502013}</h2>
          </div>         
          
         
            <div className='leading-9 p-7 text-sm'>
              <p>{sysText502001}：{editPercentData.uuid}</p>            
              <p>{sysText502014}：{editPercentUpperData[0].用戶分潤}％</p>
              <p>{sysText502015}：</p>
              <input type="number" value={profit} onChange={(e) => setProfit(e.target.value)} className='w-full'></input>
              {String(editPercentData.uuid)[0] === "2" ? 
              <>
                <p>用户当前下线利润百分比：</p>
                <input type="number" value={downlineProfit} onChange={(e) => setDownlineProfit(e.target.value)} className='w-full'></input>
              </>
              : ""
              }
                
            </div>  
            
            <div className='flex flex-auto w-full justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{minWidth: "120px"}} className='flex justify-center' label={sysText10003} onClick={() => {setShowEditPercent(false)}} size="small" severity="secondary" raised></Button>
              <Button style={{minWidth: "120px"}} className='flex justify-center' label={sysText10002} onClick={confirmHandler} size="small" severity="info" raised></Button>
            </div> 
        </div>
        
      </div>
    </Dialog>
  );
};

export default EditPercent;