import React, { useContext } from 'react';
import Menu from '../layouts/menu';
import MenuContext from '../context/menuContext';
import { Outlet } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb';
import { ToastProvider } from '../context/ToastContext';

const Main = () => {

	const {showMenu} = useContext(MenuContext)  
     
  return (
      
      <div className='flex h-full'>
        <Menu></Menu>
        <div className='mainWrap w-full min-2xl:p-4 max-2xl:px-0 max-2xl:py-2 h-full overflow-auto'>
            <div className='max-2xl:hidden'>
              <Breadcrumb  />
            </div>          
            <div className='h-[calc(100%-48px)]'>
              <Outlet/>
            </div>                           
            
        </div>
      </div>
      
  );
};

export default Main;