import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import FilterInput from '../../components/UI/filterInput';
import Card from '../../components/other/cardSetting/card';
import AddCard from '../../components/other/cardSetting/addCard';
import EditCard from '../../components/other/cardSetting/editCard';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { web_39_find_user_card, web_57_set_card } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import DeleteCard from '../../components/other/cardSetting/deleteCard';
import { SplitButton } from 'primereact/splitbutton';

const CardSetting = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const [showAddCard, setShowAddCard] = useState(false)
  const [showEditCard, setShowEditCard] = useState(false)
  const [editCardData, setEditCardData] = useState({})
  const [showDeleteCard, setShowDeleteCard] = useState(false)
  const [deleteCardData, setDeleteCardData] = useState({})
  const [cardSettingData, setCardSettingData] = useState([])
  const [filterName, setFilterName] = useState("")
  const { showToast, isRefresh, setIsRefresh } = useContext(ToastContext)
  const location = useLocation()
  const [bankInfo, setBankInfo] = useState(null);

  const dailyCashColumn = (item) => {
    return <div>{item.當前收款額 + "/" + item.日收款額上限}</div>
  }

  const dailyCountColumn = (item) => {
    return <div>{item.當前收款次數 + "/" + item.日收款次數上限}</div>
  }

  const ActiveColumn = (item) => {

    const statusBtnCheck = () => {
      let label = ''
      let severity = ''
      switch (item.狀態) {
        case 0:
          label = "停用中"
          severity = "help"
          break
        case 1:
          label = "启用中"
          severity = "success"
          break
        case 2:
          label = "已锁住"
          severity = "warning"
          break
        case 3:
          label = "限额中"
          severity = "danger"
          break
        case 9:
          label = "异常"
          severity = "danger"
          break
        default:
          label = '';
          severity = '';
      }
      return { label, severity }
    }

    const { label, severity } = statusBtnCheck()

    const statusHandler = async() => {     
      setIsLoading(true) 
      const filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        staffcardnumber: item.卡號,
        paymentamountdaylimit_small: item.日小額收款額上限,
        paymentcount: item.當前收款次數,
        balance: item.餘額,
        paymentamountdaylimit: item.日收款額上限,
        paymentcountdaylimit: item.日收款次數上限,
        old_paymentamountdaylimit_small: item.日小額收款額上限,
        old_paymentcount: item.當前收款次數,
        old_balance: item.餘額,
        old_paymentamountdaylimit: item.日收款額上限,
        old_paymentcountdaylimit: item.日收款次數上限,          
        state: item.狀態 == 1 ? "0" : "1"           
      };         
            
      try {
        const { listResponse, status, statusText } = await web_57_set_card(filterData);

        console.log("listResponse: ", listResponse)

        if (status === 200) {
          setIsRefresh(prev => !prev)
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }

    const showEditCardHandler = () => {
      setShowEditCard(true)
      setEditCardData(item)
    }

    const showDeleteCardHandler = () => {
      setDeleteCardData(item)
      setShowDeleteCard(true)
    }   

    const menuItems1_2 = [
      {
        label: item.狀態 === 1 ? "停用" : "启用",
        command: () => {
          if(item.狀態 === 0 || item.狀態 === 1){
            statusHandler()
          }          
        }
      },
      {
        label: '编辑',
        command: showEditCardHandler,
      }      
    ];
    
    const menuItems3_9 = [
      {
        label: item.狀態 === 1 ? "停用" : "启用",
        command: statusHandler
      },
      {
        label: '编辑',
        command: showEditCardHandler,
      },
      {
        label: '删除',
        command: showDeleteCardHandler,
      },
    ];
    
    const menuItems8 = [
      {
        label: item.狀態 === 1 ? "停用" : "启用",
        command: statusHandler
      },    
      {
        label: '删除',
        command: showDeleteCardHandler,
      },
    ]; 
    
    return (
      
      <div className='activeBtn flex flex-col items-center justify-around p-0'>
        {(uuidFirst === 1 || uuidFirst === 2) &&          
          <SplitButton label={label} model={menuItems1_2} severity={severity} />
        }

        {(uuidFirst === 3 || uuidFirst === 9) &&          
          <SplitButton label={label} model={menuItems3_9} severity={severity} />
        }

        {(uuidFirst === 8) &&          
          <SplitButton label={label} model={menuItems8} severity={severity} />
        }        
      </div>      
    )

    // return (

    //   <div className='activeBtn flex flex-col items-center justify-around'>
    //     <Button label={label} severity={severity} raised />
    //     <Button label={item.狀態 === 1 ? "停用" : "启用"} severity={item.狀態 === 1 ? "help" : "success"} disabled={!(item.狀態 === 0 || item.狀態 === 1)} text raised onClick={statusHandler} />
    //     {(uuidFirst !== 8) && <Button label="编辑" severity="info" text raised onClick={showEditCardHandler} />}
    //     {(uuidFirst === 3 || uuidFirst === 9) && <Button label="删除" severity="danger" text raised onClick={showDeleteCardHandler} />}
    //   </div>
    // )
  }
  const bankColumn = (rowData) => {
    return <a href="#" onClick={(e) => { e.preventDefault(); setBankInfo(rowData) }}>{rowData.銀行名稱}</a>
  }
  const BankInfoDialog = ({ bankInfo, setBankInfo }) => {
    if (bankInfo === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setBankInfo(null)}
        header="銀行信息"
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>銀行信息</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            <>
              <p>开户支行: {bankInfo.開戶支行}</p>
              <p>开户城市: {bankInfo.開戶城市}</p>
              <p>开户省分: {bankInfo.開戶省分}</p>
            </>
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setBankInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  useEffect(() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterName,
      page: 1,
      limit: 1000000
    };

    const fetchData = async () => {
      
      try {
        const { listResponse, status, statusText } = await web_39_find_user_card(filterData);
        console.log("listResponse: ", listResponse)
        if (status === 200) {         
          setCardSettingData(listResponse.data)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterName, isRefresh]);

  return (
    <div className="cardSettingWrap flex flex-col">
      <div className='mx-2'>
        <div className='flex flex-row max-md:flex-col min-md:justify-end mb-1 items-center mx-2'>
          <div>
            <FilterInput showCalendarInput={false} setFilterNumber={setFilterName} placeholder="请输入uuid、用户名称、卡号" />
          </div>
        </div>
        <div className='flex items-center text-center max-md:flex-col'>
          <div className='min-md:ml-auto'>
            <Button label='新增卡片' severity='info' raised onClick={() => setShowAddCard(true)}></Button>
          </div>
        </div>
       
      </div>

      <div className="p-4 recordWrap tableWrap">
        <div className='max-lg:hidden'>
          <DataTable value={cardSettingData} scrollable scrollHeight='550px' >
            <Column style={{ minWidth: "80px" }} field="uuid" sortable header="UUID"></Column>
            <Column style={{ minWidth: "120px" }} field="用戶名稱" sortable header="用户姓名"></Column>
            <Column style={{ minWidth: "120px" }} field="銀行名稱" header="银行名称" body={bankColumn}></Column>
            <Column style={{ minWidth: "120px" }} field="卡號" sortable header="卡号"></Column>
            <Column style={{ minWidth: "120px" }} field="" header="日收款额" body={dailyCashColumn}></Column>
            <Column style={{ minWidth: "120px" }} field="" header="日收款次数" body={dailyCountColumn}></Column>
            <Column style={{ minWidth: "100px" }} field="餘額" sortable header="余额"></Column>
            <Column style={{ minWidth: "120px" }} field="當前信用額度" header="信用分数"></Column>           
            <Column style={{ minWidth: "150px" }} field="狀態" sortable header="状态/操作" headerClassName='centered-header' body={ActiveColumn}></Column>
          </DataTable>
        </div>

        <div className='min-lg:hidden'>
          <DataTable value={cardSettingData} scrollable scrollHeight='430px'>
            <Column style={{ minWidth: "80px" }} field="uuid" sortable header="UUID"></Column>
            <Column style={{ minWidth: "120px" }} field="用戶名稱" sortable header="用户姓名"></Column>
            <Column style={{ minWidth: "120px" }} field="銀行名稱" header="银行名称" body={bankColumn}></Column>
            <Column style={{ minWidth: "120px" }} field="卡號" sortable header="卡号"></Column>
            <Column style={{ minWidth: "120px" }} field="" header="日收款额" body={dailyCashColumn}></Column>
            <Column style={{ minWidth: "120px" }} field="" header="日收款次数" body={dailyCountColumn}></Column>
            <Column style={{ minWidth: "80px" }} field="餘額" header="余额"></Column>
            <Column style={{ minWidth: "120px" }} field="當前信用額度" header="信用分数"></Column>
            <Column style={{ minWidth: "150px" }} field="狀態" sortable header="状态/操作" headerClassName='centered-header' body={ActiveColumn}></Column>
          </DataTable>
        </div>
        <BankInfoDialog bankInfo={bankInfo} setBankInfo={setBankInfo} />
        {/* <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> */}


      </div>

      {/* 新增卡片 */}
      {showAddCard && <AddCard setShowAddCard={setShowAddCard} />}

      {/* 編輯卡片 */}
      {showEditCard && <EditCard setShowEditCard={setShowEditCard} editCardData={editCardData} />}

      {/* 刪除卡片 */}
      {showDeleteCard && <DeleteCard setShowDeleteCard={setShowDeleteCard} deleteCardData={deleteCardData} />}

    </div>
  );
}

export default CardSetting