import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import FilterInput from '../../components/UI/filterInput';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import { web_14_ncc_find_pur_order, web_27_ncc_create_pur_sub_order, web_39_ncc_find_user_ncc_set, web_88_pur_order_allotment_or_grab } from '../../api/api';
import { Dropdown } from 'primereact/dropdown';
import useJson from '../../helper/useJson';
import useSystemText from '../../helper/useSystemText';

const PurchaseGrab = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const { showToast, isRefresh, setIsRefresh } = useContext(ToastContext)
  const [grabData, setGrabData] = useState([])
  const [cardData, setCardData] = useState([])
  const [selectCard, setSelectCard] = useState("")
  const [selectCardBalance, setSelectCardBalance] = useState(0)
  const {jsonToObject} = useJson()
  const location = useLocation()
  const [filterNumber, setFilterNumber] = useState("")
  const [verificationCode, setVerificationCode] = useState("")
  const [showGrab, setShowGrab] = useState(false)
  const [selectOrderData, setSelectOrderData] = useState(null)

  const sysText302001 = useSystemText(302001)
  const sysText302002 = useSystemText(302002)
  const sysText302003 = useSystemText(302003)
  const sysText302004 = useSystemText(302004)
  const sysText302005 = useSystemText(302005)
  const sysText302006 = useSystemText(302006)
  const sysText302007 = useSystemText(302007)
  const sysText302008 = useSystemText(302008)

  const selectCardChangeHandler = (e) => {
    setSelectCard(e.value)
    setSelectCardBalance(e.value.餘額)
  }

  const dateBodyTemplate = (rowData) => {
    // 若訂單時間超過10分鐘，則將字改成黃色
    const rowDate = new Date(rowData.時間);
    const tenMinutesLater = new Date(rowDate.getTime() + 10 * 60 * 1000);

    const now = new Date();    
    const style = now > tenMinutesLater ? { color: '#fb9d37' } : {};
 
    return (
      <span style={style}>{rowData.時間}</span>
    );
  };

  const grabOrderHandler = async() => {
    setIsLoading(true)    
 
    const cardInfo = jsonToObject(selectCard.資訊)      
    console.log("cardInfo: ", cardInfo);  

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      crepso_pur_confirmpeople: Cookies.get("cookie_uuid"),
      crepso_pur_rawordernumber: selectOrderData.單號,        
      crepso_pur_productamount: selectOrderData.金額,
      crepso_pur_payee_info: selectOrderData.收款人姓名,
      crepso_carddata: {[selectCard.id]: selectOrderData.金額},
      verification_code: verificationCode
    };
    
    try {
      const { listResponse, status, statusText } = await web_27_ncc_create_pur_sub_order(filterData);
      console.log("listResponse: ", listResponse)
      if (status === 200) {      
        setIsRefresh(prev => !prev)  
        showToast("success", `成功搶單 訂單號: ${filterData.crepso_pur_rawordernumber}`)
        setShowGrab(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
      }
    } catch (error) {
      console.error(location.pathname, error);
    } finally {
      setIsLoading(false)
    }
  }

  const grabToMateHandler = async() => {
    setIsLoading(true)         

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      id: selectOrderData.id,
      grab: "0",
      verification_code: verificationCode
    };
    
    try {
      const { listResponse, status, statusText } = await web_88_pur_order_allotment_or_grab(filterData);
      console.log("listResponse: ", listResponse)
      if (status === 200) {      
        setIsRefresh(prev => !prev)  
        showToast("success", `成功轉配單 ${selectOrderData.單號}`)
        setShowGrab(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
      }
    } catch (error) {
      console.error(location.pathname, error);
    } finally {
      setIsLoading(false)
    }
  } 
  
  const ActiveColumn = (rowData) => {

    const showGrabHandler = () => {
      setSelectOrderData(rowData)
      setShowGrab(true)
    }

    return (
      <>       
        {(uuidFirst === 1 || uuidFirst === 2 || uuidFirst === 8) && 
          <div className='flex justify-center'>
            <Button label={sysText302005} severity='danger' disabled={rowData.金額 > selectCardBalance || !selectCard} onClick={showGrabHandler}></Button>
          </div>
        }

        {(uuidFirst === 3 || uuidFirst === 9) && 
          <div className='flex justify-center'>
            <Button label={sysText302006} severity='info' onClick={showGrabHandler}></Button>
          </div>
        }
      </>
      
    )
  }

  useEffect(() => {        

    const fetchData_pur = async () => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        select: filterNumber,
        grab: "1"
      };
      
      try {
        const { listResponse, status, statusText } = await web_14_ncc_find_pur_order(filterData);
        console.log("listResponse: ", listResponse)
        if (status === 200) {         
          setGrabData(listResponse.data)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }

    const fetchData_card = async () => {
      setIsLoading(true)
      const filterData = {
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        select: "",
        page: 1,
        limit: 1000000,
        card: "1"
      };
      
      try {
        const { listResponse, status, statusText } = await web_39_ncc_find_user_ncc_set(filterData);
        console.log("listResponse: ", listResponse)
        if (status === 200) { 
          const newData = []
          listResponse.data.map((row) => {                      
            const infoObject = jsonToObject(row.資訊); // 直接调用同步函数
            if (infoObject["卡号"]) {
              newData.push({ name: `卡号: ${infoObject["卡号"]} 、 餘額: ${row.餘額}`, value: row });
            }           
          })
          
          setCardData(newData)          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }

    fetchData_pur()
    fetchData_card()
  }, [filterNumber, isRefresh]);

  console.log("cardData: ", cardData);
  console.log("selectCard: ", selectCard)

  return (
    <div className="cardSettingWrap flex flex-col">
      
      <div className='mx-2'>       
        <div className='flex flex-row max-md:flex-col mb-1 items-center mx-2'>          
        {(uuidFirst === 1 || uuidFirst === 2 || uuidFirst === 8) && <Dropdown className='custom-dropdown w-64 max-md:w-80' value={selectCard} onChange={(e) => selectCardChangeHandler(e)} options={cardData} optionLabel='name' optionValue='value' placeholder='请选择卡' />}
          <FilterInput setFilterNumber={setFilterNumber} showCalendarInput={false} placeholder={sysText302007}/>
          
        </div>
      </div>
            

      <div className="p-4 recordWrap tableWrap">
        <div className='max-lg:hidden'>
          <DataTable value={grabData} scrollHeight='550px' >
            <Column style={{ minWidth: "80px" }} field="單號" header={sysText302001}></Column>                      
            <Column style={{ minWidth: "120px" }} field="金額" header={sysText302002}></Column>            
            <Column style={{ minWidth: "100px" }} field="時間" header={sysText302003} body={dateBodyTemplate}></Column>                   
            <Column style={{ minWidth: "150px" }} field="狀態" header={sysText302004} headerClassName='centered-header' body={ActiveColumn}></Column>
          </DataTable>
        </div>

        <div className='min-lg:hidden'>
          <DataTable value={grabData} scrollHeight='430px'>
            <Column style={{ minWidth: "80px" }} field="單號" header={sysText302001}></Column>                       
            <Column style={{ minWidth: "120px" }} field="金額" header={sysText302002}></Column>           
            <Column style={{ minWidth: "80px" }} field="時間" header={sysText302003} body={dateBodyTemplate}></Column>            
            <Column style={{ minWidth: "150px" }} field="狀態" header={sysText302004} headerClassName='centered-header' body={ActiveColumn}></Column>
          </DataTable>
        </div>
        
        {/* <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> */}

      </div>

      {/* 搶單、轉單 */}
      { showGrab && 
        <Dialog>
          <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>    
            <div className='border-b border-gray-700 p-4 text-lg'>
            {(uuidFirst === 1 || uuidFirst === 2 || uuidFirst === 8) ?
              "抢单确认"
              : (uuidFirst === 3 || uuidFirst === 9) ?
              "转成配单"
              : null
            }
            </div>      
            <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
              <p>{sysText302001}: {selectOrderData.單號}</p>
              <p>{sysText302003}: {selectOrderData.時間}</p>  
              <p>{sysText302002}: {selectOrderData.金額}</p>           

              <div className='space-x-2 py-2'>
                <label>{sysText302008}</label>
                <input className='px-2 w-24' maxLength="8" onChange={(e) => setVerificationCode(e.target.value)}></input>
              </div>                         
            </div>
            <div className='flex justify-around items-center border-t border-gray-700 py-2'>
              <Button style={{ width: "120px" }} label='取消' severity='secondary' raised onClick={() => setShowGrab(false)}></Button>
              {(uuidFirst === 1 || uuidFirst === 2 || uuidFirst === 8) ?
                <Button style={{ width: "120px" }} label='确定' severity='info' raised onClick={grabOrderHandler}></Button>
                : (uuidFirst === 3 || uuidFirst === 9) ?
                <Button style={{ width: "120px" }} label='确定' severity='info' raised onClick={grabToMateHandler}></Button>
                : null
              }
            </div>
          </div>
        </Dialog>
      }
     

    </div>
  );
}

export default PurchaseGrab