import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { web_92_edit_3pset, web_93_get_3pset } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import ThirdPartyEdit from '../../components/other/thirdParty/thirdPartyEdit';
import useSystemText from '../../helper/useSystemText';
import { Button } from 'primereact/button';
import Dialog from '../../components/dialog';
import { Tooltip } from 'primereact/tooltip';
import useTableSize from '../../helper/useTableSize';
import { MultiSelect } from 'primereact/multiselect';

const ThirdParty = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const { isLoading, setIsLoading } = useContext(LoadingContext)  
  const [showEdit, setShowEdit] = useState(false)
  const [editData, setEditData] = useState({})
  const [thirdPartyData, setThirdPartyData] = useState([])
  const [filterName, setFilterName] = useState("")
  const { showToast, isRefresh, setIsRefresh } = useContext(ToastContext)
  const [colOrPayInfo, setColOrPayInfo] = useState(null)
  const [gatewayInfo, setGatewayInfo] = useState(null)
  const location = useLocation()
  const tableScrollY = useTableSize(70, 80)

  const sysText701015 = useSystemText(701015)
  const sysText701016 = useSystemText(701016)
  const sysText705001 = useSystemText(705001)
  const sysText705002 = useSystemText(705002)
  const sysText705003 = useSystemText(705003)
  const sysText705004 = useSystemText(705004)
  const sysText705005 = useSystemText(705005)
  // const sysText705006 = useSystemText(705006)
  // const sysText705007 = useSystemText(705007)
  const sysText705008 = useSystemText(705008)
  const sysText705009 = useSystemText(705009)
  const sysText705010 = useSystemText(705010)

  const userColumn = (item) => {
    return <div>
      <p>{item.uuid}</p>
      <p>{item.用戶姓名}</p>
    </div>
  }

  const colOrPayColumn = (rowData) => {
    return <Button label={sysText701016} severity='info' text raised onClick={(e) => { 
      try{
        const data = JSON.parse(rowData.卡號)
        setColOrPayInfo(data)
      }catch (e) {
        setColOrPayInfo(null)
        showToast("error", "资料格式错误无法显示")
      }
    }}></Button>
  }
  const ColOrPayDialog = ({ colOrPayInfo, setColOrPayInfo }) => {
    if (colOrPayInfo === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setColOrPayInfo(null)}
        header={sysText701016}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText701016}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            {Object.keys(colOrPayInfo).map((key) => 
              <p>{`${key}: ${colOrPayInfo[key]}`}</p>
            )}             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setColOrPayInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const dailyCashColumn = (item) => {
    return ( 
      <>      
        <Tooltip target=".dailyCash"/>
        <div className='dailyCash' data-pr-tooltip={`${item.當前收款額} / ${item.日收款額上限}`}  data-pr-position="top">
          {item.當前收款額 > 0 ? Math.round((parseFloat(item.當前收款額) / parseFloat(item.日收款額上限)) * 10000) / 100 + "%" : "0%"}
        </div>
      </>
    )
  }

  const dailyCountColumn = (item) => {    
    return ( 
      <>      
        <Tooltip target=".dailyCount"/>
        <div className='dailyCount' data-pr-tooltip={`${item.當前收款次數} / ${item.日收款次數上限}`}  data-pr-position="top">
          {item.當前收款次數 > 0 ? Math.round((parseFloat(item.當前收款次數) / parseFloat(item.日收款次數上限)) * 10000) / 100 + "%" : "0%"}
        </div>
      </>
    )
  }

  // const merchantnoColColumn = (item) => {
  //   return <div>
  //     {item?.專收.map((it, index) => 
  //       <p key={index}>{it}</p>
  //     )}
  //   </div>
  // }

  // const merchantnoPurColumn = (item) => {
  //   return <div>
  //     {item?.專付.map((it, index) => 
  //       <p key={index}>{it}</p>
  //     )}
  //   </div>
  // }

  const defColumn = (item) => {
    console.log(Object.entries(item.def))
    return Object.entries(item.def).map(([key, value]) => <div key={key} className='whitespace-nowrap'>{`${key}: ${value}`}</div>)
  }

  const gatewayColumn = (rowData) => {
    return <Button label={sysText701015} severity='help' text raised onClick={(e) => { 
      try{       
        setGatewayInfo(rowData?.能使用的通道id)
      }catch (e) {
        setGatewayInfo(null)        
      }
    }}></Button>
  }
  const GatewayDialog = ({ gatewayInfo, setGatewayInfo }) => {
    if (gatewayInfo === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setGatewayInfo(null)}
        header={sysText701015}
      >
        <div className='mx-auto w-[50vw] max-md:w-full min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText701015}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
            
            <p>{gatewayInfo && gatewayInfo.join(",")}</p>             
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setGatewayInfo(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };  
  
  const ActiveColumn = (item) => {

    const statusBtnCheck = () => {
      let label = ''
      let severity = ''
      switch (item.state) {
        case 0:
          label = "停用中"
          severity = "help"
          break
        case 1:
          label = "启用中"
          severity = "success"
          break        
        default:
          label = '';
          severity = '';
      }
      return { label, severity }
    }

    const { label, severity } = statusBtnCheck()

    const statusHandler = async() => {     
      setIsLoading(true) 
      let filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        id: item.id,
        new_3_pset_uuid: item.uuid,
        new_3_pset_username: item.用戶姓名,
        new_3_pset_secondname: item.次要姓名,
        representscardnumber: item.卡號,
        paymentamountdaylimit: item.日收款額上限,
        paymentcountdaylimit: item.日收款次數上限,        
        def_list: JSON.stringify(item.def),
        state: item.state == "1" ? "0" : "1",
        gateway_id_list: item.能使用的通道id
      };    
      
      // if(item.專付.length > 0) {
      //   filterData.pur_merchantno = item.專付
      // }

      // if(item.專收.length > 0) {
      //   filterData.col_merchantno = item.專收
      // }     
            
      try {
        const { listResponse, status, statusText } = await web_92_edit_3pset(filterData);

        console.log("listResponse: ", listResponse)

        if (status === 200) {
          setIsRefresh(prev => !prev)
          showToast("success", "成功編輯狀態")
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }

    const showEditHandler = () => {
      setShowEdit(true)
      setEditData(item)
    }     
    
    const menuItems = [
      {
        label: item.state == 1 ? "停用" : "启用",
        command: statusHandler
      },
      {
        label: '编辑',
        command: showEditHandler,
      }      
    ];        
    
    return (
      
      <div className='activeBtn flex flex-col items-center justify-around p-0'>              
        <SplitButton label={label} model={menuItems} severity={severity} />                
      </div>      
    )

    // return (

    //   <div className='activeBtn flex flex-col items-center justify-around'>
    //     <Button label={label} severity={severity} raised />
    //     <Button label={item.狀態 === 1 ? "停用" : "启用"} severity={item.狀態 === 1 ? "help" : "success"} disabled={!(item.狀態 === 0 || item.狀態 === 1)} text raised onClick={statusHandler} />
    //     {(uuidFirst !== 8) && <Button label="编辑" severity="info" text raised onClick={showEditHandler} />}
    //     {(uuidFirst === 3 || uuidFirst === 9) && <Button label="删除" severity="danger" text raised onClick={showHandler} />}
    //   </div>
    // )
  }

  const tableColumns = [
    {
      header: sysText705001,
      field: "用户资讯",
      style: {minWidth: "100px"},
      body: userColumn
    },
    {
      header: sysText705002,
      field: "卡號",
      style: {minWidth: "120px"},
      body: userColumn
    },
    // {
    //   header: sysText705003,
    //   field: "日收款額",
    //   style: {minWidth: "120px"},
    //   body: dailyCashColumn
    // },
    // {
    //   header: sysText705004,
    //   field: "日收款次數",
    //   style: {minWidth: "120px"},
    //   body: dailyCountColumn
    // },
    // {
    //   header: sysText705005,
    //   field: "餘額",
    //   style: {minWidth: "100px"},
    // },
    {
      header: sysText705008,
      field: "def",
      style: {minWidth: "120px"},
      body: defColumn
    },
    {
      header: sysText705010,
      field: "能使用的通道id",
      style: {minWidth: "120px"},
      body: gatewayColumn
    },
    
    {
      header: sysText705009,
      field: "active",
      style: {minWidth: "150px"},
      body: ActiveColumn,
      headerClassName: "centered-header"
    },

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)
 

  useEffect(() => {
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterName,
      page: 1,
      limit: 1000000
    };

    const fetchData = async () => {
      
      try {
        const { listResponse, status, statusText } = await web_93_get_3pset(filterData);
        console.log("listResponse: ", listResponse)
        if (status === 200) {         
          setThirdPartyData(listResponse.filter(item => item.id <= 10))          
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}`)
        }
      } catch (error) {
        console.error(location.pathname, error);
      } finally {
        setIsLoading(false)
      }
    }
    fetchData(filterData)
  }, [filterName, isRefresh]);

  return (
    <div className="cardSettingWrap flex flex-col">    

      <div className="p-4 recordWrap tableWrap">
        <div className='flex flex-col'>
          <div className='min-2xl:ml-auto flex max-2xl:justify-center items-center space-x-4'>                  
            <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 custom-multiselect" display='chip'/>                
          </div>
          <DataTable value={thirdPartyData} scrollable scrollHeight={tableScrollY} >
            {visibleColumns.map((row,index) =>             
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName} alignFrozen={row.alignFrozen} frozen={row.frozen}></Column>
            )}
          </DataTable>
        </div>      
        
        {/* 資訊詳情 */}
        <ColOrPayDialog colOrPayInfo={colOrPayInfo} setColOrPayInfo={setColOrPayInfo} />

        {/* 通道詳情 */}
        <GatewayDialog gatewayInfo={gatewayInfo} setGatewayInfo={setGatewayInfo} />
      </div>     

      {/* 編輯卡片 */}
      {showEdit && <ThirdPartyEdit showEdit={showEdit} setShowEdit={setShowEdit} editData={editData}/>}
    </div>
  );
}

export default ThirdParty