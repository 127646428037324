import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';
import { web_101_get_frozen_amount } from '../../api/api';
import { SplitButton } from 'primereact/splitbutton';
import RecordFreeze from '../../components/orderManage/record/recordFreeze';
import { formatDate } from '../../helper/formatDate';

const FreezeOrder = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [freezeOrderData , setFreezeOrderData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)     
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterNumber, setFilterNumber] = useState("")
  const [showFreeze, setShowFreeze] = useState(false)
  const [rowData, setRowData] = useState({})
  const [freezeOrUnfreeze, setFreezeOrUnfreeze] = useState(null)
  const [filterDate, setFilterDate] = useState([])


  const ActiveColumn = (item) => {     
            
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''   
       
      switch (item.訂單狀態) {
        case 5:
          label = "冻结"
          severity = "secondary"
          break        
        default:
          label = "解冻"
          severity = "info"
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()

    const showUnFreezeHandler = () => {    
      setFreezeOrUnfreeze("解冻")
      setShowFreeze(true)
      setRowData(item)  
    }

    let menuItems8 = []

    let menuItems9 = [        
      {
        label: '解冻',
        command: showUnFreezeHandler,
        disabled: item.訂單狀態 !== 5          
      } 
           
    ];
    
    return (  
      <>
        {uuidFirst === 8 &&
          <div className='activeBtn flex flex-col items-center justify-center p-0 w-full'>                    
            <Button label={label} model={menuItems8} severity={severity} />       
          </div>   
        }

        {(uuidFirst === 3 || uuidFirst === 9) &&
          <div className='activeBtn flex flex-col items-center justify-center p-0 w-full'>                    
            <SplitButton label={label} model={menuItems9} severity={severity} />       
          </div>   
        }
      </>  
    )    
  }

  useEffect(() => {    
    setIsLoading(true)
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      ordernumber: filterNumber,
      page: page + 1,
      limit: rows,
      ncc_or_cc: "2",
      s_time: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
      e_time: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime()    
    };

    const fetchData = async() => {    
         
      try {
        const { listResponse, status, statusText } = await web_101_get_frozen_amount(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setFreezeOrderData(listResponse.data)            
          setTotalCount(listResponse.count)            
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchData(filterData)
  }, [first, rows, page, filterNumber, isRefresh, filterDate]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showCalendarInput={true} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} setFilterDate={setFilterDate} placeholder="请输入编号"/>
        <div className='max-lg:hidden'>
          <DataTable value={freezeOrderData} scrollable scrollHeight='550px'>
            <Column style={{ minWidth: "150px" }} field="訂單時間" header="订单时间"></Column>
            <Column style={{ minWidth: "150px" }} field="訂單編號" header="订单号"></Column>
            <Column style={{ minWidth: "120px" }} field="付款人姓名" header="付款人姓名"></Column>
            <Column style={{ minWidth: "120px" }} field="員工姓名" header="收款人姓名"></Column>
            <Column style={{ minWidth: "120px" }} field="員工卡號" header="卡号"></Column>
            <Column style={{ minWidth: "120px" }} field="員工銀行名稱" header="銀行名稱"></Column>
            {uuidFirst === 9 &&
              <Column style={{ minWidth: "120px" }} field="商戶號" header="商户来源"></Column>
            }
            <Column style={{ minWidth: "150px" }} field="訂單金額" header="订单金额"></Column>
            <Column style={{ minWidth: "150px" }} field="凍結金額" header="冻结金额"></Column>
            <Column style={{ minWidth: "150px" }} field="備註" header="备注"></Column>
            <Column style={{ minWidth: "150px" }} field="凍結時間" header="冻结时间"></Column>
            <Column style={{ minWidth: "150px" }} field="解凍時間" header="解冻时间"></Column>            
            <Column style={{ minWidth: "150px" }} field="active" header="状态/操作" body={ActiveColumn} headerClassName="centered-header" alignFrozen="right" frozen={true}></Column>            
          </DataTable>          
        </div>

        <div className='min-lg:hidden px-2 flex flex-col bg-customColor-bgGray space-y-2'>
          {freezeOrderData.map(row => {
            return (
              <div key={row.訂單編號} className='flex flex-row justify-between items-center bg-customColor-bgDark p-4'>
                <div className='flex flex-col'>
                  <p className='break-words'>{row.訂單時間}</p>
                  <p className='break-words'>{row.訂單編號}</p>
                  <p>{row.付款人姓名}</p>
                  <p>{row.員工姓名}</p>
                  <p>{row.員工卡號}</p>
                  <p>{row.員工銀行名稱}</p>
                  {uuidFirst === 9 &&
                   <p>{row.商戶號}</p>
                  }
                  <p>{row.訂單金額}</p>
                  <p>{row.凍結金額}</p>
                  <p>{row.備註}</p>
                  <p>{row.凍結時間}</p>
                  <p>{row.解凍時間}</p>
                </div>
                <div>
                  {ActiveColumn(row)}
                </div>
              </div>                        
            )
          })}        
          
        </div> 
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> 

        {showFreeze && <RecordFreeze setShowFreeze={setShowFreeze} rowData={rowData} freezeOrUnfreeze={freezeOrUnfreeze} />}

      </div>
  );
}

export default FreezeOrder

