import React, { useContext, useEffect, useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { web_57_set_card } from '../../../api/api';
import { useFormik } from 'formik';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

const EditCard = (props) => {

  const {editCardData, setShowEditCard} = props
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [selectState, setSelectState] = useState(String(editCardData.狀態))

  const stateOption = [
    {
      name: "停用",
      value: "0"
    },
    {
      name: "启用",
      value: "1"
    },
    {
      name: "上锁",
      value: "2"
    },
    {
      name: "限制",
      value: "3"
    },
  ]

  // const confirmHandler = async() => {
  //   const filterData = {     
  //     cookie_uuid: Cookies.get("cookie_uuid"),
  //     cookie_hash: Cookies.get("cookie_hash"),
  //     dailyCashInput: dailyCashInput + "",
  //     dailyCountInput: dailyCountInput + "",
  //     cardnumber: editCardData.員工卡號,        
  //     state: editCardData.狀態
  //   };  
   
  //   setIsLoading(true)       
  //   try {
  //     const { listResponse, status, statusText } = await web_57_set_card(filterData);        
      
  //     console.log("listResponse: ", listResponse)

  //     if (status === 200) {                     
  //       setIsRefresh(prev => !prev)
  //       setShowEditCard(false)
  //       showToast("success", "" + status, `成功编辑卡号: ${editCardData.員工卡號}` )
  //     } else {
  //       showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
  //     }
  //   } catch (error) {        
  //     console.error(location.pathname, error);            
  //   } finally {
  //     setIsLoading(false)
  //   }      
  // }

  const formik = useFormik({
    initialValues: {
        balance: editCardData.餘額,
        dailyCash: editCardData.日收款額上限,
        dailyCashSmall: editCardData.日小額收款額上限,
        currentCount: editCardData.當前收款次數,
        dailyCount: editCardData.日收款次數上限,
        priority: false

    },
    validate: (data) => {
      let errors = {};      

      if (data.balance === "") {								
        errors.balance = '必填';
      }

      if (data.dailyCash === "") {								
        errors.dailyCash = '必填';
      }
      
      if (data.dailyCashSmall === "") {								
        errors.dailyCashSmall = '必填';
      }
      
      if (data.currentCount === "") {								
        errors.currentCount = '必填';
      }

      if (data.dailyCount === "") {								
        errors.dailyCount = '必填';
      }

      if (data.state === "") {								
        errors.state = '必填';
      }

      return errors;
    },
    onSubmit: async(data) => {  
      setIsLoading(true)
      let filterData = {     
        cookie_uuid: Cookies.get("cookie_uuid"),
        cookie_hash: Cookies.get("cookie_hash"),
        staffcardnumber: editCardData.卡號,
        paymentamountdaylimit_small: data.dailyCashSmall,
        paymentcount: data.currentCount,
        balance: data.balance,
        paymentamountdaylimit: data.dailyCash,
        paymentcountdaylimit: data.dailyCount,
        old_paymentamountdaylimit_small: editCardData.日小額收款額上限,
        old_paymentcount: editCardData.當前收款次數,
        old_balance: editCardData.餘額,
        old_paymentamountdaylimit: editCardData.日收款額上限,
        old_paymentcountdaylimit: editCardData.日收款次數上限,          
        state: selectState          
      };    

      if(data.priority) {
        filterData.priority = "1"
      } 
      
      try {
        
        const { listResponse, status, statusText } = await web_57_set_card(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowEditCard(false)              
          showToast("success", "" + status, `成功编辑卡片 卡号: ${filterData.staffcardnumber}` )           
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };	

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }  
      
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[35vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
        <form onSubmit={formik.handleSubmit} className='flex flex-col' onKeyDown={handleKeyDown}>
          
          <div className='border-b border-gray-700 p-4 text-lg'>编辑卡片</div>          
          
          {(uuidFirst === 1 || uuidFirst === 2) &&
             <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>         
              
              <div>
                <label htmlFor='dailyCash'>日收款额上限</label>
                <input name="dailyCash" value={formik.values.dailyCash} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCash')}
              </div>  
  
              <div>
                <label htmlFor='dailyCount'>日收款数上限</label>
                <input name="dailyCount" value={formik.values.dailyCount} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCount')} 
              </div>         
                                      
            </div>
          }        
         
         {(uuidFirst === 3 || uuidFirst === 9) &&
            <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-y-scroll'>
              <div>
                <label htmlFor='balance'>余额</label>
                <input name="balance" value={formik.values.balance} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('balance')}
              </div>  
  
              <div>
                <label htmlFor='currentCash'>当前收款额</label>
                <input name="currentCash" value={editCardData.當前收款額} disabled={true} autoComplete='off' onChange={formik.handleChange} className='w-full opacity-30 cursor-not-allowed' type="number" />
                {getFormErrorMessage('currentCash')} 
              </div>                
              
              <div>
                <label htmlFor='dailyCash'>日收款额上限</label>
                <input name="dailyCash" value={formik.values.dailyCash} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCash')}
              </div>
  
              <div>
                <label htmlFor='currentCashSmall'>当前收款额-小</label>
                <input name="currentCashSmall" value={editCardData.當前小額收款額} disabled={true} autoComplete='off' onChange={formik.handleChange} className='w-full opacity-30 cursor-not-allowed' type="number" />
                {getFormErrorMessage('currentCashSmall')} 
              </div>
  
              <div>
                <label htmlFor='dailyCashSmall'>日收款额上限-小</label>
                <input name="dailyCashSmall" value={formik.values.dailyCashSmall} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCashSmall')}
              </div>
  
              <div>
                <label htmlFor='currentCount'>当前收款数</label>
                <input name="currentCount" value={formik.values.currentCount} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('currentCount')} 
              </div>
  
              <div>
                <label htmlFor='dailyCount'>日收款数上限</label>
                <input name="dailyCount" value={formik.values.dailyCount} autoComplete='off' onChange={formik.handleChange} className='w-full' type="number" />
                {getFormErrorMessage('dailyCount')} 
              </div>            
  
              <div className='flex flex-row space-x-4'>
                <div className='flex items-center space-x-4'>
                  <label htmlFor='state'>启用状态</label>                 
                  <Dropdown className='custom-dropdown' value={selectState} onChange={(e) => setSelectState(e.value)} options={stateOption} optionLabel="name"/>
                </div>
                <div className='flex items-center space-x-4'>
                  <label htmlFor='priority'>优先</label>              
                  <InputSwitch name="priority" checked={formik.values.priority} value={formik.values.priority} onChange={formik.handleChange} className="p-inputswitch" />
                </div>              
              </div>                                       
            </div>
          }       

          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{width: "120px"}} label='取消编辑' severity='secondary' raised onClick={() => setShowEditCard(false)}></Button>
            <Button type="submit" style={{width: "120px"}} label='确认编辑' disabled={isLoading} severity='info' raised></Button>
          </div>
        
        </form>
          
        </div>
      </Dialog>
    </>
  );
};

export default EditCard;