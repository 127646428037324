import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import Cookies from 'js-cookie';
import FilterInput from '../../components/UI/filterInput';
import { web_101_get_frozen_amount } from '../../api/api';
import { SplitButton } from 'primereact/splitbutton';
import useJson from '../../helper/useJson';
import Dialog from '../../components/dialog';
import RecordFreezeNcc from '../../components/orderManage/recordNcc/recordFreezeNcc';
import useSystemText from '../../helper/useSystemText';
import { MultiSelect } from 'primereact/multiselect';

const FreezeOrderNcc = () => {
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [freezeOrderData , setFreezeOrderData] = useState([]);
  const [totalCount, setTotalCount] = useState(0)     
  const [first, setFirst] = useState(0)   
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(10)
  const {showToast, isRefresh, setIsRfresh} = useContext(ToastContext)  
  const location = useLocation()
  const [filterNumber, setFilterNumber] = useState("")
  const [showFreeze, setShowFreeze] = useState(false)
  const [rowData, setRowData] = useState({})
  const [freezeOrUnfreeze, setFreezeOrUnfreeze] = useState(null)
  const {jsonToObject} = useJson()
  const [infoData, setInfoData] = useState(null)
  const [remarkData, setRemarkData] = useState(null)
  const [filterDate, setFilterDate] = useState([])

  const sysText10032 = useSystemText(10032)
  const sysText10033 = useSystemText(10033)
  const sysText10041 = useSystemText(10041)
  const sysText10043 = useSystemText(10043)

  const sysText202001 = useSystemText(202001)
  const sysText202002 = useSystemText(202002)
  const sysText202003 = useSystemText(202003)
  const sysText202004 = useSystemText(202004)
  const sysText202005 = useSystemText(202005)
  const sysText202006 = useSystemText(202006)
  const sysText202007 = useSystemText(202007)
  const sysText202008 = useSystemText(202008)
  const sysText202009 = useSystemText(202009)
  const sysText202010 = useSystemText(202010)
  const sysText202011 = useSystemText(202011)
  const sysText202012 = useSystemText(202012)

  const sysText201031 = useSystemText(201031)

  const InfoColumn = (data) => {
    const infoData =  jsonToObject(data)    

    const showInfoHandler = () => {
      setInfoData(infoData)
    }

    return (       
      
      <div className='activeBtn flex flex-col items-center p-0 w-24'>                    
        <Button label={sysText10041} severity="help" text raised onClick={showInfoHandler} />       
      </div>   
      
    )    
  }

  const InfoDialog = () => {
    if (infoData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setInfoData(null)}
        header={sysText202004}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText202004}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-auto'>
            <div className='p-2 flex flex-col'>           
              {Object.entries(infoData).map(([key, value]) => 
                <p key={key + value}>
                  {`${key}: ${value}`}
                </p>
                )                                  
              }
            </div>  
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setInfoData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const RemarkColumn = (data) => {    
    return (      
      
        <div className='activeBtn flex flex-col items-center p-0 w-24'>                    
          <Button label={sysText10043} severity="info" text raised onClick={() => setRemarkData(data)} />       
        </div>   
              
    )         
  }

  const RemarkDialog = () => {
    if (remarkData === null) {
      return null;
    }
    return (
      <Dialog
        onHide={() => setRemarkData(null)}
        header={sysText202008}
      >
        <div className='mx-auto w-[30vw] min-w-[350px] max-h-[800px] bg-customColor-bgGray rounded-xl'>
          <div className='border-b border-gray-700 p-4 text-lg'>{sysText202008}</div>
          <div className='space-y-2 text-sm my-2 px-8 py-4 max-h-[55vh] overflow-auto'>
            <div className='p-2 flex flex-col'>           
              <p>{remarkData}</p>
            </div>  
            
          </div>
          <div className='flex justify-around items-center border-t border-gray-700 py-2'>
            <Button style={{ width: "120px" }} label='OK' severity='info' raised onClick={() => setRemarkData(null)}></Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const ActiveColumn = (item) => {     
            
    const statusBtnCheck = () => {   
      let label = ''
      let severity = ''      
      switch (item.訂單狀態) {
        case 5:
          label = sysText10032
          severity = "secondary"
          break        
        default:
          label = sysText10033
          severity = "info"
      }
      return {label, severity}             
    }     

    const {label, severity} = statusBtnCheck()

    const showUnFreezeHandler = () => {    
      setFreezeOrUnfreeze("解冻")
      setShowFreeze(true)
      setRowData(item)  
    }

    let menuItems8 = []

    let menuItems9 = [        
      {
        label: sysText201031,
        command: showUnFreezeHandler,
        disabled: item.訂單狀態 !== 5          
      } 
           
    ];
    
    return (  
      <>
        {uuidFirst === 8 &&
          <div className='activeBtn flex flex-col items-center justify-center p-0 w-full whitespace-nowrap'>                    
            <Button label={label} severity={severity} />       
          </div>   
        }

        {(uuidFirst === 3 || uuidFirst === 9) &&
          <div className='activeBtn flex flex-col items-center justify-center p-0 w-full whitespace-nowrap'>                    
            <SplitButton label={label} model={menuItems9} severity={severity} />       
          </div>   
        }
      </>  
    )    
  }

  const tableColumns = [
    {
      header: sysText202001,
      field: "訂單時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText202002,
      field: "訂單編號",
      style: {minWidth: "150px"},
    },
    {
      header: sysText202003,
      field: "付款人資訊",
      style: {minWidth: "120px"},
    },
    {
      header: sysText202004,
      field: "收款人資訊",
      style: {minWidth: "120px"},
      body: (row) => InfoColumn(row?.員工資訊),
      headerClassName: "centered-header"
    },
    {
      header: sysText202005,
      field: "商戶號",
      style: {minWidth: "120px"},
      enable: [9]
    },
    {
      header: sysText202006,
      field: "訂單金額",
      style: {minWidth: "150px"},
    },
    {
      header: sysText202007,
      field: "凍結金額",
      style: {minWidth: "150px"},
    },
    {
      header: sysText202008,
      field: "備註",
      style: {minWidth: "120px"},
      body: (row) => RemarkColumn(row?.備註),
      headerClassName: "centered-header"
    },
    {
      header: sysText202009,
      field: "凍結時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText202010,
      field: "解凍時間",
      style: {minWidth: "120px"},
    },
    {
      header: sysText202011,
      field: "",
      style: {minWidth: "120px"},
      headerClassName: "centered-header",
      alignFrozen: "right",
      frozen: true
    },        

  ]

  //透過欄位enable判斷是否顯示
  const filterColumnForUuid = tableColumns.filter(item => {
    return item.enable ?
      item.enable.includes(uuidFirst) ? item : null
    : 
      item
  })

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

    setVisibleColumns(orderedSelectedColumns);
  };  
  
  const [visibleColumns, setVisibleColumns] = useState(filterColumnForUuid)

  useEffect(() => {    
    setIsLoading(true)
    const filterData = {  
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      ordernumber: filterNumber,
      page: page + 1,
      limit: rows,
      ncc_or_cc: "1",
      s_time: filterDate?.[0] !== undefined ? filterDate?.[0] : -2209017600000,
      e_time: filterDate?.[1] !== undefined ? filterDate?.[1] : new Date().getTime()  
    };

    const fetchData = async() => {    
         
      try {
        const { listResponse, status, statusText } = await web_101_get_frozen_amount(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setFreezeOrderData(listResponse.data)            
          setTotalCount(listResponse.count)            
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchData(filterData)
  }, [first, rows, page, filterNumber, isRefresh, filterDate]);      

  return (
      <div className="p-4 recordWrap tableWrap">
        <FilterInput showCalendarInput={true} setFilterNumber={setFilterNumber} setFirst={setFirst} setPage={setPage} setFilterDate={setFilterDate} placeholder="请输入订单编号">
          <MultiSelect value={visibleColumns.map(item => ({...item, body: ""}))} options={filterColumnForUuid.map(item => ({...item, body: ""}))} optionLabel="header" onChange={onColumnToggle} className="w-80 max-2xl:hidden custom-multiselect" display='chip'/>  
        </FilterInput>
        <div className='max-lg:hidden'>        

           <DataTable value={freezeOrderData} scrollable scrollHeight="550px">
            {visibleColumns.map((row,index) =>                                
              <Column key={row.header + index} style={row.style} field={row.field} header={row.header} body={row.body} headerClassName={row.headerClassName}></Column>           
            )}              
          </DataTable>      
        </div>

        <div className='min-lg:hidden px-2 flex flex-col bg-customColor-bgGray space-y-2'>
          {freezeOrderData.map(row => {

            return (
              <div key={row.訂單編號} className='flex flex-row justify-between items-center bg-customColor-bgDark p-4'>
                <div className='flex flex-col basis-4/5'>
                  <p className='break-words'>{row.訂單時間}</p>
                  <p className='break-words'>{row.訂單編號}</p>
                  <p>{row.付款人姓名}</p>
                  <p>{row.員工資訊}</p>
                  {uuidFirst === 9 &&
                   <p>{row.商戶號}</p>
                  }
                  <p>{row.訂單金額}</p>
                  <p>{row.凍結金額}</p>
                  <p>{row.備註}</p>
                  <p>{row.凍結時間}</p>
                  <p>{row.解凍時間}</p>
                </div>
                <div className='basis-1/5'>
                  {ActiveColumn(row)}
                </div>
              </div>                        
            )
          })}        
          
        </div> 
        <PaginatorUI value={{first, setFirst, rows, setRows, page, setPage, totalCount}}/> 
        {infoData && <InfoDialog/>}
        {remarkData && <RemarkDialog/>}
        {showFreeze && <RecordFreezeNcc setShowFreeze={setShowFreeze} rowData={rowData} freezeOrUnfreeze={freezeOrUnfreeze} />}

      </div>
  );
}

export default FreezeOrderNcc

