import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContext } from '../../context/ToastContext';
import { web_19_review_staffcardnumber } from '../../api/api';
import { useLocation } from 'react-router-dom';
import PaginatorUI from '../../components/UI/paginatorUI'
import LoadingContext from '../../context/loadingContext';
import FilterInput from '../../components/UI/filterInput';
import Cookies from 'js-cookie';
import UpdateState from '../../components/other/cardCheck/updateState';

const CardCheck = () => {
  const {isLoading, setIsLoading} = useContext(LoadingContext)    
  const [cardCheckData , setCardCheckData] = useState([]);
  const [updateStateData, setUpdateStateData] = useState({})  
  const [showUpdateState, setShowUpdateState] = useState(false)
  const [filterUUID, setFilterUUID] = useState("")
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()   

  const ActiveColumn = (item) => {   
    const updateStateHandler = () => {
      setUpdateStateData(item)
      setShowUpdateState(true)
    }            
       
    return (      
      <div className='activeBtn flex justify-center items-center'>          
        <Button label="变更审核状态" severity="warning" raised onClick={updateStateHandler} />        
      </div>
    )
  }      

  useEffect(() => {    

    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),
      select: filterUUID
    }
    
    const fetchData = async() => {    
      setIsLoading(true)
      try {
          
        const { listResponse, status, statusText } = await web_19_review_staffcardnumber(filterData);        
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setCardCheckData(listResponse.data)            
                   
        } else {
          showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [isRefresh]);      

  return (
      <div className="p-4 recordWrap tableWrap"> 
        <FilterInput setFilterNumber={setFilterUUID} showCalendarInput={false} placeholder="请输入UUID或用户姓名"/>       
        <div className=''>
          <DataTable value={cardCheckData}  scrollable scrollHeight="530px">
            <Column style={{ minWidth: '150px' }} field="用戶編號" sortable header="UUID"></Column>
            <Column style={{ minWidth: '100px' }} field="姓名" header="用户姓名"></Column>
            <Column style={{ minWidth: '150px' }} field="銀行名稱" header="银行名称"></Column>
            <Column style={{ minWidth: '150px' }} field="開戶支行" sortable header="开户支行"></Column>                          
            <Column style={{ minWidth: "100px" }} field="active" header="状态/操作" body={ActiveColumn} headerClassName='centered-header'></Column>
          </DataTable>         
        </div>             
        
        {showUpdateState && <UpdateState updateStateData={updateStateData} setShowUpdateState={setShowUpdateState}/>}

      </div>
  );
}

export default CardCheck