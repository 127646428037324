import React from 'react';
import ReactDOM from 'react-dom';

const dialogRoot = document.getElementById("dialog-root")

const Dialog = (props) => {  

  return ReactDOM.createPortal(
  <div className={`${props?.className} dialogWrap fixed top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,0.5)] z-[9]`}>
    <div className='flex items-center h-full dialogAnime'>
      {props.children}
    </div>
    
  </div>, dialogRoot)
};

export default Dialog;