import React, { useState } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import { imgOnError } from '../../../helper/imgOnError';
import useCopy from '../../../helper/useCopy';
import PurchaseRecordNccConfirm from './purchaseRecordNccConfirm';
import PurchaseRecordNccSubRevoke from './purchaseRecordSubRevoke';
import PurchaseRecordNccTransfer from './purchaseRecordNccTransfer';
import useJson from '../../../helper/useJson';
import Cookies from 'js-cookie';
import PurchaseRecordNccRePay from './purchaseRecordNccRePay';
import HelpIcon from '../../UI/helpIcon';
import useHelpText from '../../../helper/useHelpText';
import useSystemText from '../../../helper/useSystemText';

const PurchaseRecordNccProcess = (props) => {
  
  const { setShowProcess, processParentData, processSubData } = props
  const [uuidFirst] = useState(parseInt(Cookies.get("cookie_uuid")?.[0]));
  const [showSubRevoke, setShowSubRevoke] = useState(false)
  const [subRevokeData, setSubRevokeData] = useState({})
  const [showTransfer, setShowTransfer] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showRePay, setShowRePay] = useState(false)
  const [rePayData, setRePayData] = useState({})
  const {copyHandler} = useCopy()
  const {jsonToObject} = useJson()
  const helpTextPicture = useHelpText(0)

  const sysText10009 = useSystemText(10009)
  const sysText306004 = useSystemText(306004)
  const sysText306006 = useSystemText(306006)
  const sysText306011 = useSystemText(306011)
  const sysText306031 = useSystemText(306031)
  const sysText306032 = useSystemText(306032)
  const sysText306033 = useSystemText(306033)
  const sysText306034 = useSystemText(306034)
  const sysText306035 = useSystemText(306035)
  const sysText306036 = useSystemText(306036)
  const sysText306037 = useSystemText(306037)

  console.log("processParentData: ", processParentData);
  console.log("processSubData: ", processSubData);

  const confirmHandler = () => {    
    setShowConfirm(true)
  }

  const transferHandler = () => {    
    setShowTransfer(true)
  }

  const revokeHandler = () => {
    setSubRevokeData(processSubData)
    setShowSubRevoke(true)
  }

  const rePayHandler = () => {
    setRePayData(processSubData)
    setShowRePay(true)
  }

  let payeeName, payeeBank, payeeCardnumber = ""

  const payeeData = jsonToObject(processParentData.收款人資訊)
  payeeName = payeeData?.姓名
  payeeBank = payeeData?.银行名称
  payeeCardnumber = payeeData?.卡号
  
  return (
    <>
      <Dialog>
        <div className='mx-auto w-[60vw] min-w-[350px] max-h-[800px] max-md:w-full max-md:max-h-[500px] bg-customColor-bgGray rounded-xl overflow-y-scroll'>
          <div className='flex flex-col'>
            <div className='flex flex-row items-center p-6 border-b border-gray-700'>
              <h2 className='text-lg'>{sysText306031}</h2>
              <span className='ml-auto mr-2'><HelpIcon tooltipText={helpTextPicture}/></span>
            </div>
            <div className='px-4 py-7 text-sm'>

              {(processSubData.圖片1 || processSubData.圖片2) &&
                <div className='flex flex-col mb-6 space-y-4'>
                  <div className=''>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306032}</span>
                  </div>
                  <div className='flex flex-row space-x-2'>

                    <a href={`${processSubData.圖片1}`} target='_blank' rel='noopener noreferrer'>
                      <img className='max-w-[180px] max-h-[180px]' alt="" onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${processSubData.圖片1}`} />
                    </a>

                    <a href={`${processSubData.圖片2}`} target='_blank' rel='noopener noreferrer'>
                      <img className='max-w-[180px] max-h-[180px]' alt="" onError={({ currentTarget }) => imgOnError(currentTarget)} src={`${processSubData.圖片2}`} />
                    </a>
                  </div>
                </div>
              }

              {!(processSubData.圖片1 || processSubData.圖片2) &&
                <div className='flex flex-col mb-6 space-y-4'>
                  <div className=''>
                    <span className='border border-gray-700 bg-[#440000] py-2 px-3 rounded-md'>{sysText306033}</span>
                  </div>
                </div>
              }


              <div className='flex flex-col'>
                <div className='flex flex-col my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306004}</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processSubData.子單單號}</p>
                </div>  

                <div className='flex flex-col my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306006}</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人資訊}</p>
                </div>               

                <div className='flex flex-col my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>{sysText306011}</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processSubData.訂單金額}</p>
                </div>               

                {/* <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人开户城市</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人開戶城市}</p>
                </div>

                <div className='flex flex-col w-[33%] max-lg:w-[50%] my-1'>
                  <div className='my-4'>
                    <span className='border border-gray-700 bg-[#111111] py-2 px-3 rounded-md'>收款人开户支行</span>
                  </div>
                  <p className="hover:text-yellow-500 duration-300 cursor-pointer" onClick={(e) => copyHandler(e.target.innerText)}>{processParentData.收款人開戶支行}</p>
                </div> */}
              </div>

            </div>

            <div className='flex w-full justify-around items-center border-t border-gray-700 p-2'>
              {uuidFirst === 3 && 
              <>
                <Button className='flex justify-center' label={sysText306034} disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 0 || processSubData.狀態 == 2 || processSubData.狀態 == 3 || processSubData.狀態 == 4 || processSubData.狀態 == 9} onClick={confirmHandler} severity="success" raised></Button>
                <Button className='flex justify-center' label={sysText306035} disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 0 || processSubData.狀態 == 2 || processSubData.狀態 == 3 || processSubData.狀態 == 4} onClick={revokeHandler} severity="danger" raised></Button>
                <Button className='flex justify-center' label={sysText306036} disabled={processParentData.採購單狀態 == 3 || processParentData.採購單狀態 == 4 || processSubData.狀態 == 1 || processSubData.狀態 == 2} onClick={transferHandler} severity="warning" raised></Button>
                {/* 狀態異常 + 三方狀態2 才會執行 */}
                <Button className='flex justify-center' label={sysText306037} disabled={processSubData.狀態 != 9} onClick={rePayHandler} severity="help" raised></Button>
              </>              
              }              
              <Button className='flex justify-center' label={sysText10009} onClick={() => { setShowProcess(false) }} severity="secondary" raised></Button>
            </div>
          </div>

        </div>
      </Dialog>

      {/* 確認子單 */}
      {showConfirm && <PurchaseRecordNccConfirm setShowConfirm={setShowConfirm} processSubData={processSubData} processParentData={processParentData} setShowProcess={setShowProcess} />}

      {/* 撤回子單 */}
      {showSubRevoke && <PurchaseRecordNccSubRevoke setShowSubRevoke={setShowSubRevoke} subRevokeData={subRevokeData} setShowProcess={setShowProcess} />}

      {/* 轉派子單 */}
      {showTransfer && <PurchaseRecordNccTransfer setShowTransfer={setShowTransfer} processSubData={processSubData} setShowProcess={setShowProcess} />}

      {/* 發起付款 */}
      {showRePay && <PurchaseRecordNccRePay setShowRePay={setShowRePay} rePayData={rePayData} setShowProcess={setShowProcess} />}

    </>
  );
};

export default PurchaseRecordNccProcess;