import { TOTP } from "otpauth";
import { WEB_01_SECRET } from "../api/api";

export function getTOTP() {
  const totp = new TOTP({    
    period: 60,
    secret: WEB_01_SECRET
  });

  return totp.generate()
}
