export function formatDate(dateString, formatOptions = {}) {

  if(!dateString) {
    return null
  }

  const date = new Date(dateString);

  // 預設的格式選項
  const defaultOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  // 合併預設選項和傳入的選項
  const options = { ...defaultOptions, ...formatOptions };

  const formattedDate = new Intl.DateTimeFormat('zh-CN', options).format(date).replace(/\//g, "-");

  return formattedDate;
}
