import React, { useEffect, useContext, useState } from 'react';
import Dialog from '../../dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'; 
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { web_27_create_pur_sub_order } from '../../../api/api';

const PurchaseMateForce = (props) => {

  const {setShowForce, selectOrder, selectCard} = props
  const location = useLocation()
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const [mateData, setMateData] = useState({}) //物件 key 為卡號 value 為input金額
  const [orderAmount, setOrderAmount] = useState(selectOrder.金額)
  const [totalAmount, setTotalAmount] = useState(0)
  const [balance, setBalance] = useState({}) //物件 key 為卡號 value 為金額

  console.log("balance: ", balance);
  console.log("mateData: ", mateData);

  const handleInputAmountChange = (item, amount) => {
    // 複製現有的付款金額物件，然後更新特定卡號的付款金額
    setMateData((prev) => ({
      ...prev,
      [item.卡號]: parseInt(amount),
    }));    
   
  };

  const InputColumn = (item) => {
    return (
    <>      
       <input type="number" value={mateData[item.卡號] ?? 0} onChange={(e) => handleInputAmountChange(item, e.target.value)} min="0" className='w-[90%]'/>
    </>
    )
  }

  const BalanceColumn = (item) => {
    if(mateData[item.卡號]) {
      return balance[item.卡號] - mateData[item.卡號] >= 0 ? <div>{balance[item.卡號] - mateData[item.卡號]}</div> : <div className='text-red-500'>{balance[item.卡號] - mateData[item.卡號]}</div>
    }else {
      return balance[item.卡號]
    }    
  }

  const creatSubOrderDisable = () => {
    const findBalance = Object.values(balance).filter(value => value < 0)
    console.log("findBalance: ",findBalance);

    const balanceLowZeroList = selectCard.filter(row => (balance[row.卡號] - mateData[row.卡號]) < 0)
    console.log("balanceLowZeroList: ", balanceLowZeroList);
    if (balanceLowZeroList.length > 0 || totalAmount !== selectOrder.金額) {
      return true
    }else {
      return false
    }
  }

  const creatSubOrderHandler = async() => {    
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid") || "",
      cookie_hash: Cookies.get("cookie_hash") || "",
      crepso_pur_confirmpeople: Cookies.get("cookie_uuid") || "",
      crepso_pur_merchantno: selectOrder.商戶號,
      crepso_pur_rawordernumber: selectOrder.單號,
      crepso_pur_productamount: selectOrder.金額,
      crepso_pur_payeebankname: selectOrder.收款銀行名稱,
      crepso_pur_payeebankcity: selectOrder.收款開戶城市,
      crepso_pur_payeebankbranch: selectOrder.收款開戶支行,
      crepso_pur_payeename: selectOrder.收款人姓名,
      crepso_pur_payeecardnumber: selectOrder.收款人卡號,
      crepso_carddata: mateData      
    }
    
    try {
      const {listResponse, status, statusText} = await web_27_create_pur_sub_order(filterData)
      console.log("listResponse: ", listResponse);

      if (status === 200) {          
        setIsRefresh(prev => !prev)
        showToast("success", "" + status, `成功建立子单` )
        setShowForce(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }       
    } catch (error) {          
      console.error(location.pathname, error);            
    }finally {
      setIsLoading(false)
    }    
  }

  //加總所有input金額
  useEffect(() => {      
    let amount = 0
    Object.values(mateData).map((item => {
      amount += parseInt(item)
    }))    
    
    setTotalAmount(amount)   
  }, [mateData]);

  useEffect(() => {
    if(totalAmount) {
      setOrderAmount(selectOrder.金額 - parseInt(totalAmount))
    }else {
      setOrderAmount(selectOrder.金額)
    }
  }, [totalAmount])

  //將所有卡的餘額將存到balance{卡號:值}
  useEffect(() => {     
    let newBalanceData = {} 
    selectCard.map(row => {
      newBalanceData = {...newBalanceData, [row.卡號]: parseInt(row.當前餘額)}
    })
    setBalance(newBalanceData)
  }, [selectCard]);


  return (
    <>      
      <Dialog>
        <div className='mx-auto w-[700px] bg-customColor-bgGray rounded-xl max-md:w-full '>
          
          <div className='flex flex-col'>   
            <div className='border-b border-gray-500 text-lg p-6 flex-1'> 强制送出订单</div>            
            <div className='p-6'>
              <p>订单编号: {selectOrder.單號}</p>
              <p>金額: {orderAmount >= 0 ? <span>{orderAmount}</span> : <span className='text-red-500'>{orderAmount}</span>} </p>
            </div>           

            <div className='w-full tableWrap px-6'>
              <DataTable value={selectCard} scrollable scrollHeight="300px">
                <Column style={{minWidth: "80px"}} field="姓名" header="姓名"></Column>
                <Column style={{minWidth: "80px"}} field="卡號" header="卡号"></Column>
                <Column style={{minWidth: "120px"}} field="付款金额" header="付款金额" body={InputColumn}></Column>
                <Column style={{minWidth: "80px"}} field="當前餘額" header="余额" body={BalanceColumn}></Column>                                     
              </DataTable>      
            </div>                                
            
          </div>
          <div className='flex flex-auto w-full justify-around items-center mt-3 py-2'>
            <Button className='flex justify-center' style={{width: "120px"}} onClick={() => {setShowForce(false)}} severity="secondary" raised>取消配对</Button>
            <Button className='flex justify-center' style={{width: "120px"}} onClick={creatSubOrderHandler} disabled={creatSubOrderDisable()} severity="info" raised>取得配对</Button>
          </div> 
        </div>
      </Dialog>       
      
    </>
    
  );
};

export default PurchaseMateForce;