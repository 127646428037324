import React, { useContext } from 'react';
import Dialog from '../../dialog';
import { Button } from 'primereact/button';
import Cookies from 'js-cookie';
import { ToastContext } from '../../../context/ToastContext';
import LoadingContext from '../../../context/loadingContext';
import { web_110_pur_order_ncc_re_back } from '../../../api/api';
import { useLocation } from 'react-router-dom';

const PurchaseRecordNccRePay = (props) => {
  const {setShowRePay, rePayData, setShowProcess} = props
  
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  console.log(rePayData);

  const confirmHandler = async() => {
    
    setIsLoading(true)
    const filterData = {
      cookie_uuid: Cookies.get("cookie_uuid"),
      cookie_hash: Cookies.get("cookie_hash"),     
      ordernumber: rePayData.子單單號               
    };
    
    try {
      const { listResponse, status, statusText } = await web_110_pur_order_ncc_re_back(filterData);        
      
      if (status === 200) {  
        setIsRefresh(prev => !prev)                 
        showToast("success", "" + status, `成功发起付款: ${rePayData.子單單號}` )
        setShowRePay(false)
        setShowProcess(false)
      } else {
        showToast("error", "" + status, `${statusText} URL:${location.pathname}` )
      }
    } catch (error) {        
      console.error(location.pathname, error);            
    } finally {
      setIsLoading(false)
    }
  }    
     

  return (
    <>
      <Dialog>
        <div className='mx-auto w-[500px] max-md:w-full max-h-[700px] bg-customColor-bgGray rounded-lg'>
          <div className='flex flex-col'>
            <div className='border-b border-gray-700 p-4 text-lg'>发起付款</div>
            <div className='p-4 text-sm space-y-4'>
              <p>采购单编号:</p>
              <p className='text-yellow-400'>{rePayData.子單單號}</p>
              <p className='text-gray-500'>确认是否发起付款？</p>
            </div>            
            
            <div className='border-t border-gray-500 flex justify-around items-center py-2'>
              <Button style={{width: '120px'}} label='取消' severity='secondary' raised onClick={() => setShowRePay(false)}></Button>
              <Button style={{width: '120px'}} label='确认' severity='info' raised onClick={confirmHandler}></Button>              
            </div>
          </div>
         
        </div>
      </Dialog>
    </>
  );
};

export default PurchaseRecordNccRePay;